import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@core/models/api-response.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OneSignalHttpService {

  constructor(private http: HttpClient) { }

  async onLoad(): Promise<any> {
    window.OneSignal = window.OneSignal || [];
    return new Promise((resolve) => {
      window.OneSignal.push(function () {
        resolve(window.OneSignal);
      });
    });
  }

  onInit() {
    return this.http.get<ApiResponse>(`/onesignal/app`).pipe(
      map(res => {
        this.onLoad().then((OneSignal) => {
          OneSignal.init({
            appId: res.data.app_id
          });
        });
      })
    );
  }
}

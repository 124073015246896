// Angular
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
// Model
import { RegistrationPageContainer } from '@core/models/registration-page.model';
// Service
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-section-container1',
  templateUrl: './section-container1.component.html',
  styleUrls: ['./section-container1.component.scss'],
})
export class SectionContainer1Component implements OnInit {
  @Input()
  container: RegistrationPageContainer;

  config: SwiperOptions = {
    pagination: { el: '.swiper-pagination', clickable: true },
    allowTouchMove: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    loop: true
  };

  constructor(private router: Router) {}

  ngOnInit() {}

  onNavigate(link: string) {
    if (link !== "") {
      window.location.href = link;
    } else {
      this.router.navigateByUrl("/");
    }
  }
}
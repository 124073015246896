import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-bank-wrapper-modal',
  templateUrl: './bank-wrapper-modal.component.html',
  styleUrls: ['./bank-wrapper-modal.component.scss']
})
export class BankWrapperModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<BankWrapperModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      existing_bank_name: any,
      is_deposit: boolean,
      is_transfer: boolean,
      is_withdraw: boolean,
      bank_type: any
    },
  ) { }

  ngOnInit(): void {
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

}

<div *ngIf="downloadAppBanner == 'show'" class="download-app-banner">
    <div class="row m-0 h-100">
        <div class="col-2 p-0"><img src="/assets/images/download-app/bp-icon.png"></div>
        <div class="col-5 download-app-text">
            <p class="m-0">{{ '[sitename] App Download' | translate:{'sitename': siteName} }}</p>
            <p class="m-0">{{ 'Download App version Now' | translate }}</p>
        </div>
        <div class="col-4 p-0 m-auto">
            <div class="download-app-banner-button" (click)="onClickBannerBtn('download')">{{ 'Download' | translate }}</div>
        </div>
        <div class="col-1 p-0 download-app-banner-close" (click)="onClickBannerBtn('close')">×</div>
    </div>
</div>
<header id="header" class="full-header" data-sticky-class="not-dark">
    <div id="header-wrap" [ngClass]="downloadAppBanner">
        <div class="container">
            <div class="header-row">

                <div class="body-overlay" (click)="toggleSideBar(false)"></div>
                <div *ngIf="!isLanding && !showBackButton && !onMaintenancePage" id="primary-menu-trigger" class="side-panel-trigger side-bar-icon">
                    <app-sidepanels id="side-panel" class="dark"></app-sidepanels>
                    <svg class="svg-trigger" viewBox="0 0 100 100" style="z-index: 298;" (click)="toggleSideBar(false)">
                        <path d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"></path>
                        <path d="m 30,50 h 40"></path>
                        <path d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"></path>
                    </svg>
                    <div *ngIf="newMenu" class="prediction-notification"></div>
                </div>

                <div *ngIf="showBackButton" id="primary-back-trigger" class="side-bar-icon" (click)="onBackButtonClick()">
                    <i class="fas fa-lg fa-chevron-left text-white"></i>
                </div>

                <div *ngIf="isLanding && !showBackButton" id="primary-menu-trigger" class="side-panel-trigger side-bar-icon">
                    <button type="button" class="close" (click)="onClose()">&times;</button>
                </div>

                <!----LOGO---->
                <div *ngIf="showTitle === false && !hideTopIcon" id="logo" [ngClass]="{'logo-after-login': !(isLoggedIn$ | async)}">
                    <ng-container *ngIf="isLaunchGamePage == false">
                        <div class="d-flex align-items-center">
                            <!----SEASONAL THEME LOGO---->
                            <ng-container *ngIf="showTheme; else noTheme">
                                <ng-container  *ngFor="let theme of themes | keyvalue: returnZero">
                                    <a *ngIf="theme.value.started && !theme.value.end" routerLink="/" class="retina-logo logo-img"><img [src]="theme.value.logo" [ngClass]="{'theme-logo': theme.value.started}" [alt]="altName" id="main-logo"></a>
                                </ng-container>
                            </ng-container>
                            <!----NORMAL LOGO---->
                            <ng-template #noTheme>
                                <a routerLink="/" class="standard-logo logo-img" data-dark-logo="/assets/images/logo.png" [ngClass]="{'logo-large': sitePrefix.toLowerCase() != 'bp77' && sitePrefix.toLowerCase() != 'bp9'}"><img src="/assets/images/logo.png" [alt]="altName" id="main-logo"></a>
                                <a routerLink="/" class="retina-logo logo-img" data-dark-logo="/assets/images/logo.png" [ngClass]="{'logo-large': sitePrefix.toLowerCase() != 'bp77' && sitePrefix.toLowerCase() != 'bp9'}"><img src="/assets/images/logo.png" [alt]="altName" id="main-logo"></a>
                            </ng-template>
                            <p class="text-center text-white font-weight-normal m-0" style="font-size: 0.6rem;" *ngIf="sitePrefix.toLowerCase() == 'bp77' || sitePrefix.toLowerCase() == 'bp9'">
                                <span class="vertical-bar"> | </span>
                                <i *ngIf="sitePrefix.toLowerCase() != 'bp9'" class="vertical-super">{{ 'Now known as BP9' | translate }}</i>
                                <i *ngIf="sitePrefix.toLowerCase() == 'bp9'" class="vertical-super">{{ 'Formerly known as BP77' | translate }}</i>
                            </p>
                        </div>
                    </ng-container>
                </div>

                <div *ngIf="showTitle && !hideTopIcon" id="logo" class="pt-0 top-bar-title" [ngClass]="{'logo-after-login': !(isLoggedIn$ | async)}">
                    {{ ''+pageTitle | translate }}
                </div>

                <div class="header-misc mssg-icon" *ngIf="((isLoggedIn$ | async) !== false || null || undefined) && !hideTopIcon && !onMaintenancePage">
                    <div class="header-misc-icon">
                        <a routerLink="/member/message">
                            <span class="notification-counter-wrapper message-svg">
                                <span [innerHTML]="svg.messageIcon | safeHtml"></span>
                                <ng-container *ngIf="(isLoggedIn$ | async)">
                                    <ng-container *ngIf="(unreadMessages$ | async) as unreadMessages">
                                        <span class="badge badge-danger" *ngIf="unreadMessages >= 1" [ngClass]="{'bounce': newMessage }">{{ unreadMessages }}</span>
                                    </ng-container>
                                </ng-container>
                            </span>

                        </a>
                    </div>
                </div>

            </div>
        </div>
        <div>
            <ng-container *ngIf="showKYCGateKeeper && memberKYC">
                <div *ngIf="memberKYC !== 'pro'" class="demomode-note">
                    <a routerLink="/member/profile" class="standard-logo logo-img" *ngIf="memberGroup !== 'PRO-VIP'">
                        {{ ('This feature is only available on Pro account.' | translate ) + ' ' +  ('Verify now to unlock!' | translate) }}
                    </a>
                    <a *ngIf="memberGroup == 'PRO-VIP'">
                        {{ 'This feature is not available for your member group.' | translate }}
                    </a>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="header-wrap-clone"></div>
</header>
<app-swal-alert [message]="messages$ | async" [success]="true" [tick]="false" color="#0067c6"></app-swal-alert>
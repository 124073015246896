// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';
// Model
import { ApiResponse } from '../models/api-response.model';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})
export class LiveChatHttpService {

    getInfoOngoing: boolean = false;
    constructor(
        private http: HttpClient,
        private cookieService: CookieService
    ) { }

    getLiveChatInfoCookies() {
        const data = {};
        const cookie_username = this.cookieService.get('LC_username');
        const cookie_membership = this.cookieService.get('LC_membership');
        const cookie_balance = this.cookieService.get('LC_balance');
        const cookie_kyc_status = this.cookieService.get('LC_kyc_status');
        const cookie_last_played_game = this.cookieService.get('LC_last_played_game');
        const cookie_next_required_deposit = this.cookieService.get('LC_next_required_deposit');

        if( cookie_username ) {
            data['username'] = cookie_username;
        }
        if( cookie_membership ) {
            data['membership'] = cookie_membership;
        }
        if( cookie_balance ) {
            data['balance'] = cookie_balance;
        }
        if( cookie_kyc_status ) {
            data['kyc_status'] = cookie_kyc_status;
        }
        if( cookie_last_played_game ) {
            data['last_played_game'] = cookie_last_played_game;
        }
        if( cookie_next_required_deposit ) {
            data['next_required_deposit'] = cookie_next_required_deposit;
        }
        
        return data;
    }

    getLiveChatInfo(apiParams: any) {
        this.getInfoOngoing = true;
        const params = {"modules": apiParams};
        return this.http.post<ApiResponse>(`/livechat/info`, params).pipe(
            tap(res => {
                this.setLiveChatInfoCookies(res.data);
                this.getInfoOngoing = false;
            }),
            map(res => res.data)
        );
    }

    setLiveChatInfoCookies(data) {
        const expirationDate = new Date();

        expirationDate.setTime(expirationDate.getTime() + 30 * 60000); 
        if( data.membership != undefined ) {
            this.cookieService.set('LC_membership', data.membership, expirationDate);
        }
        if( data.balance != undefined ) {
            this.cookieService.set('LC_balance', data.balance, expirationDate);
        }
        if( data.kyc_status != undefined ) {
            this.cookieService.set('LC_kyc_status', data.kyc_status, expirationDate);
        }
        if( data.last_played_game != undefined ) {
            this.cookieService.set('LC_last_played_game', data.last_played_game, expirationDate);
        }
        if( data.next_required_deposit != undefined ) {
            this.cookieService.set('LC_next_required_deposit', data.next_required_deposit, expirationDate);
        }

        expirationDate.setTime(expirationDate.getTime() + 30 * 60000);
        if( data.username != undefined ) {
            this.cookieService.set('LC_username', data.username, expirationDate);
        }
    }

    getTranslation() {
        const data = {
            username: 'Username',
            membership: 'Membership Level',
            balance: 'Wallet Balance',
            last_played_game: 'Last Played Game',
            kyc_status: 'KYC Status',
            next_required_deposit: 'Required Deposit Amount for Next Membership Level',
        };
        return data;
    }

    clearCookies() {
        this.cookieService.delete('LC_username');
        this.cookieService.delete('LC_membership');
        this.cookieService.delete('LC_balance');
        this.cookieService.delete('LC_kyc_status');
        this.cookieService.delete('LC_last_played_game');
        this.cookieService.delete('LC_next_required_deposit');
    }
}
import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Currency } from '@core/models/currency.model';
import { DashboardService } from '@core/services/dashboard.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { GameCategoryHttpService } from '@core/services/game-category-http.service';
import { PortalTransferHttpService } from '@core/services/portal-transfer-http.service';
import { WalletHttpService } from '@core/services/wallet-http.service';
import { loggedUser } from '@core/store/auth/auth.selectors';
import { select, Store } from '@ngrx/store';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { TransferWrapperModalComponent } from '@shared/transfer-wrapper-modal/transfer-wrapper-modal.component';
import { AppState } from '@store/reducers';
import { of, Subscription, zip, forkJoin } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-transfer-edit-modal',
  templateUrl: './transfer-edit-modal.component.html',
  styleUrls: ['./transfer-edit-modal.component.scss']
})
export class TransferEditModalComponent implements OnInit, OnDestroy {
  @ViewChildren('focusInput') focusInput: QueryList<ElementRef>;
  form: FormGroup;
  currency: Currency;
  providersDropdown = [];
  providersList = []; // with Balance
  promotions$ = [];
  announcements$ = [];
  announcementIds = [];

  messages$ = this.transferService.messages$;
  isSuccess = false;
  isAllin = false;

  contentHash = '';
  disableButton = false;

  @ViewChild('promotionRef')
  promotionRef: ElementRef;

  @Input()
  isPerProvider = false;

  noPromotion = true;
  submitted = false;
  transferToFromValidate = false;

  walletBalance = this.dashboardService.memberBalance$;
  balance: any;
  amountButton = [50, 100, 300, 500];
  gameCategoryList = JSON.parse(sessionStorage.getItem('game_providers_balance'));
  selectedPromotion = null;

  private subscriptions: Subscription[] = [];
  private subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { provider: any },
    public dialogRef: MatDialogRef<TransferWrapperModalComponent>,
    private dashboardService: DashboardService,
    private transferService: PortalTransferHttpService,
    private store: Store<AppState>,
    public loadingBar: LoadingBarService,
    private router: Router,
    private eventEmitterService: EventEmitterService,
    private gameCategoryHttpService: GameCategoryHttpService,
    private walletHttpService: WalletHttpService
  ) { }

  ngOnInit() {
    this.isSuccess = false;
    this.messages$.next(['-']);

    this.store.pipe(select(loggedUser), map(res => res.currency)).subscribe(res => this.currency = res);
    this.formInit();

    this.balance = Number.parseFloat(this.walletHttpService.memberBalance.value.balance + "").toFixed(2);
    this.form.patchValue({
      ...this.form,
      amount: this.balance
    });

    if (this.isPerProvider) {
      this.transferService.getPromotions('?game_provider_code='+this.data.provider.game_provider_code).subscribe(res => {
        this.promotions$ = res;
      });
      of(null).pipe(
        delay(200), tap(() => this.focusInput.first.nativeElement.focus()
        )).subscribe();
    } else {
      this.eventEmitterService.swalAnnouncementVar = this.eventEmitterService.swalAnnouncementEmitter.subscribe(page => {
        if (page === 'transfer') {
          this.onSwalFire();
        }
      });
      this.onSwalFire();
      this.dropdownInit(this.gameCategoryList);
    }

    if (this.dashboardService.subsVar === undefined) {
      this.dashboardService.subsVar = this.dashboardService.reloadProviderEmmiter.subscribe(() => {
        this.dropdownInit(null);
      });
    }

    this.eventEmitterService.buttonEmitter.subscribe(() => {
      this.disableButton = false;
    })
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }

  onChangeAmount(amount: number) {
    this.form.patchValue({ amount });
  }

  onMultiplicand(formAmount: any, selectedPromo: any){
    var finalBonus = this.onBonusAmount(formAmount, selectedPromo);
    var targetTypeValue = selectedPromo['target_type_name'].includes('incl') ? formAmount : 0; // 0 for exclude
    const totalTransferBonusAmount = ((Number(targetTypeValue) + Number(finalBonus) + Number(this.isPerProvider ? this.data.provider.balance : this.gameCategoryList['rows'].find(x => +x.id === +this.data.provider.game_provider_id))).toFixed(3).slice(0,-1));
    var finalTotalTransferBonusAmount = Number(totalTransferBonusAmount);
    return Number(finalTotalTransferBonusAmount);
  }

  onTotalTurnOver(transferAmount: number, selectedPromo: any){
    const finalTotalTransferBonusAmount = this.onMultiplicand(transferAmount, selectedPromo);
    return Number(finalTotalTransferBonusAmount) * Number(selectedPromo.target_multiplier);
  }

  onBonusAmount(formAmount: any, selectedPromo: any){
    const totalBonus = (formAmount*selectedPromo.bonus_rate).toFixed(3).slice(0,-1);
    var finalBonus = +totalBonus;
    if (+selectedPromo['max_bonus'] < +totalBonus && +selectedPromo['max_bonus'] > 0){
      finalBonus = +selectedPromo['max_bonus'];
    }
    return finalBonus;
  }

  onSelectPromotion(event: Event) {
    const promoId = +(event.target as HTMLSelectElement).value;
    if (promoId === 0){
      this.selectedPromotion = null;
    }else{
      this.selectedPromotion = this.promotions$.find(x => +x.id === promoId);
    }
  }

  onSwalFire() {
    if (localStorage.getItem('announcements_pop') !== null) {
      localStorage.removeItem('popup_ids');
      JSON.parse(localStorage.getItem('announcements_pop')).map(announcement => {
        if ((announcement.location_name === 'all' || announcement.location_name === 'transfer') && announcement.seen === 0) {
          this.announcements$.push(announcement.content);
          this.announcementIds.push(announcement.id);
        }
      });
      localStorage.setItem('popup_ids', JSON.stringify(this.announcementIds));
    }
  }

  onUpdateAnnouncement() {
    let isSeen = 0;
    const announcementPop = [];
    
    JSON.parse(localStorage.getItem('announcements_pop')).forEach(announcement => {
      this.announcementIds.includes(announcement.id) ? isSeen = 1 : isSeen = 0;

      announcementPop.push({
        ...announcement,
        seen: announcement.seen == 1 ? 1 : isSeen,
      });
    });

    localStorage.setItem('announcements_pop', JSON.stringify(announcementPop));
  }

  submit() {
    this.submitted = true;
    if (this.form.valid && this.transferToFromValidate) {
      this.disableButton = true;
      this.loadingBar.start();
      this.transfer().subscribe((res) => {
        this.selectedPromotion = null;
        this.isSuccess = res.success;
        this.messages$.next(res.message);
        this.loadingBar.complete();
        this.submitted = false;
        this.disableButton = false;

        this.walletHttpService.getMemberBalance().subscribe();
        this.getListPromoAndCategory();

        if (this.isSuccess) {
          this.dialogRef.close();
        }
      });
    }
    // Tell dialog to react on changes
    this.contentHash = Math.random().toString(36).substring(7);
  }


  onAllIn(provider: number) {
    this.loadingBar.start();
    const transfers = [];
    this.providersList.map(row => {
      if (row.balance > 0.00 && row.status === 1) {
        transfers.push(this.transferService.transferBy(row.id, 'all', 2));
      }
    });
    if (transfers.length > 0) {
      zip(...transfers).subscribe(() => {
        this.onTransferType(true, provider);
        this.dropdownInit(null);
        this.walletHttpService.getMemberBalance().subscribe();
        this.getListPromoAndCategory();
      });
    } else {
      this.onTransferType(true, provider);
    }
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onTransferChange(event?: any, type?: string) {
    if (type === 'from') {
      const provider = this.providersDropdown.find(e => e.id === +event.srcElement.value);
      this.form.patchValue({
        amount: provider ? provider.balance : this.balance
      });
    }
    const transferTo = +this.form.value.transfer_to;
    if (transferTo === 0) {
      this.form.patchValue({ promotion_id: 0 });
      this.noPromotion = true;
    } else {
      this.transferService.getPromotions('?game_provider_id='+this.form.value.transfer_to).subscribe(res => {
        this.promotions$ = res;
      });
      this.form.patchValue({ promotion_id: 0 });
      this.noPromotion = false;
    }
  }

  private getListPromoAndCategory(isInit = false){
    this.gameCategoryHttpService.getGameCategoryListWithPromo(true).subscribe(res => {
      this.gameCategoryList = res;
      if (isInit){
        this.setDropdown(res);
      }
    });
  }

  private transfer() {
    this.onTransferType();
    const data = (this.isAllin) ? { ...this.form.value, amount: 'all' } : this.form.value;
    return this.transferService.transfer(data).pipe(
      tap(res => {
        this.isSuccess = res.success;
        this.messages$.next(res.message);
        this.loadingBar.complete();
        if (res.success && !this.isPerProvider) {
          this.dropdownInit(null);
          this.noPromotion = true;
          this.walletHttpService.getMemberBalance().subscribe();
          this.isAllin = false; // reset
        }
      })
    );
  }

  private onTransferType(allIn?: boolean, allInProvider?: number) {
    if (allIn) {
      this.isAllin = true;
      this.form.patchValue({
        promotion_id: 0,
        transfer_from: 0,
        transfer_to: allInProvider
      });
      return this.transfer().subscribe();
    }
  }

  private dropdownInit(gameCategoryList: any) {
    this.providersList = []; // Reset
    if (gameCategoryList === null) {
      this.getListPromoAndCategory(true);
    } else {
      this.setDropdown(gameCategoryList);
    }
  }

  private setDropdown(gameCategoryList: any) {
    this.providersDropdown = gameCategoryList.rows;
    gameCategoryList.rows.map(provider => {
      this.providersList.push({
        name: provider.name,
        id: provider.id,
        code: provider.code,
        image_path: provider.image_path,
        balance: provider.balance,
        status: provider.status
      });
    });
  }

  private formInit() {
    if (this.isPerProvider) {
      this.transferToFromValidate = true;
      this.form = new FormGroup({
        amount: new FormControl(null),
        promotion_id: new FormControl(0),
        transfer_from: new FormControl(0),
        transfer_to: new FormControl(this.data.provider.game_provider_id),
      });
    } else {
      this.form = new FormGroup({
        transfer_from: new FormControl(0, [Validators.required]),
        transfer_to: new FormControl(0, [Validators.required]),
        amount: new FormControl(null, [Validators.required]),
        promotion_id: new FormControl(0)
      });
      this.form.valueChanges.subscribe(res => {
        if (res.transfer_from === res.transfer_to) {
          this.transferToFromValidate = false;
        } else {
          this.transferToFromValidate = true;
        }
      })
    }
  }

  closeDialog(event?: Event) {
    this.formInit();
    this.onCloseDialog();
  }
}

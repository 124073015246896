import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { isLoggedIn } from '@core/store/auth/auth.selectors';
import { select, Store } from '@ngrx/store';
import { AppState } from '@store/reducers';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.scss']
})
export class MemberComponent implements OnInit {

  isLoggedIn$: Observable<boolean>;
  rewardNotice = 0;
  showOnCurrentPage: Boolean;
  excludeWalletBarList = [
    '/member/history',
    '/member/message',
    '/member/change-password',
    '/member/profile',
    '/member/account',
    '/member/bank',
    '/member/bank;tab=bank',
    '/member/bank;tab=crypto',
    '/member/wallet',
    '/member/deposit',
    '/member/withdraw',
    '/member/transfer',
    '/member/referrals-history',
    '/member/game-password'
  ];
  route: any;

  constructor(
    private store: Store<AppState>,
    public router: Router
  ) {
    this.route = router;
  }

  ngOnInit() {
    this.showOnCurrentPage = this.router.url === '/member/wallet' ? false : true;
    this.isLoggedIn$ = this.store.pipe(select(isLoggedIn));
    if (sessionStorage.getItem('initialLogin') === '1'){
      sessionStorage.setItem('initialLogin', '0');
    }
  }

  onHistoryPage(){
    return this.router.url.includes('/member/history');
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import svgIconList from 'assets/icons.json';


@Pipe({
    name: 'fileCompression'
})
export class FileCompressionPipe implements PipeTransform {
    svg: any = svgIconList;

    constructor(
        private translateService: TranslateService,
    ) { }

    async transform(file: File): Promise<File> {
        if (file == null) {
            throw new Error('File is null');
        }

        var mbsize = 1048576;
        var compressQuality = 0;
        if (file.size >= 2 * mbsize && file.size <= 10 * mbsize) {
            compressQuality = 0.8;
        } else if (file.size >= 11 * mbsize && file.size <= 50 * mbsize) {
            compressQuality = 0.6;
        } else if (file.size >= 51 * mbsize && file.size <= 100 * mbsize) {
            //compressQuality = 0.1;
            Swal.fire({
                html: '<div class="msg-icon">' + this.svg.dangerIcon + '</div>' +
                    '<div class="text-center m-t-20">' +
                    '<ul><li class="mt-4">' + this.translateService.instant('Max file size is 10MB') + '</li></ul>' +
                    '</div>',
            });
            return;
        }

        if (file.size >= 2 * mbsize && file.size <= 50 * mbsize) {
            const compressedFile = await this.compressImage(file, {
                quality: compressQuality,
                type: file.type,
            });

            return compressedFile;
        }

    }

    async compressImage(file: File, fileDetails: any): Promise<File> {
        try {
            const imageBitmap = await createImageBitmap(file);
            const canvas = document.createElement('canvas');
            canvas.width = imageBitmap.width;
            canvas.height = imageBitmap.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(imageBitmap, 0, 0);
            return await new Promise((resolve, reject) => {
                canvas.toBlob((blob) => {
                    if (blob) {
                        const compressedFile = new File([blob], file.name, {
                            type: fileDetails.type,
                            lastModified: Date.now()
                        });
                        resolve(compressedFile);
                    } else {
                        reject(new Error('Compression failed'));
                    }
                }, fileDetails.type, fileDetails.quality);
            });
        } catch (error) {
            Swal.fire({
                html: '<div class="msg-icon">' + this.svg.dangerIcon + '</div>' +
                    '<div class="text-center m-t-20">' +
                    '<ul><li class="mt-4">' + this.translateService.instant('Max file size is 10MB') + '</li></ul>' +
                    '</div>',
            });
        }
    }
}
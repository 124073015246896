import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiResponse } from './../models/api-response.model';
import { MemberBalance } from './../models/member-balance.model';
import { LoadingService } from './loading-service.service';

@Injectable({
  providedIn: 'root'
})
export class WalletHttpService {

  memberBalance: BehaviorSubject<MemberBalance> = new BehaviorSubject<MemberBalance>({ balance: 0, code: 'MAIN', currency_code: '-', name: 'Main' });

  constructor(
    private http: HttpClient,
    private loadingService: LoadingService
  ) { }

  getMemberBalance(): Observable<MemberBalance> {
    this.loadingService.updateMemberBalanceLoadingIndicator('increase');
    return this.http.get<ApiResponse>(`/wallet/balance?game_provider_id=0`).pipe(
      map(res => {
        this.pushData(res.data);
        this.loadingService.updateMemberBalanceLoadingIndicator('decrease');
        return res.data
      }),
      catchError(err => {
        this.loadingService.updateMemberBalanceLoadingIndicator('decrease');
        return throwError(err);
      })
    );
  }

  private pushData(balance: MemberBalance) {
    this.memberBalance.next(balance);
  }

}

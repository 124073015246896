import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatName'
})
export class FormatNameInitialsPipe implements PipeTransform {

  transform(fullName: string): string {
    const names = fullName.split(' ');
    const firstName = names[0];
    const remainingName = names.slice(1).join(' ');
    const initials = firstName.charAt(0);
    return initials + '. ' +remainingName;
  }
}
// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Service
import { WebsocketService } from './websocket.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { EwalletVerification } from '@core/models/ewallet-verification.model';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class EwalletVerificationHttpService {

  static verification: EwalletVerification = null;

  private websocketService: WebsocketService | null = null;

  newRequestIncoming = new Subject();
  messages$ = new Subject<any[]>();

  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,) {
    // No need initialize websocket connection when in '/launch' page
    if (window.location.pathname !== '/launch') {
      this.websocketService = new WebsocketService(this.snackBar);
    }
  }

  async init(memberAccountId: number) {
    this.ewalletVerificationtWebsocket(memberAccountId);
  }

  private ewalletVerificationtWebsocket(memberAccountId: number) {
    this.websocketService
      .getChannel('ewallet-verification-channel.' + memberAccountId)
      .listen(`.EwalletVerificationEvent`, (data: EwalletVerification) => {
        if (data.status == 1 || data.status == 2) {
          EwalletVerificationHttpService.verification = data;
        } else {
            EwalletVerificationHttpService.verification = null;
        }
        this.newRequestIncoming.next();
      });
  }

}

<div class="modal show">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-body">
            <div class="modal-content modal-bg demomode-notice">
                <div class="modal-header p-0" style="margin-top: -3%">
                    <button type="button" class="close " data-dismiss="modal" aria-hidden="true"
                        (click)="onCloseDialog(false)">×</button>
                </div>
                <div class="notice-body">
                    <p class="icon-wrapper"><span class="icon">i</span></p>
                    <p>{{ 'Transfer all funds from Game Wallets to Main Wallet?' | translate }}</p>

                    <button class="btn-blue-gra m-b-10" (click)="onCloseDialog(true)">{{ 'Yes' | translate }}</button>
                </div>
                <p class="m-b-0 d-flex justify-content-center">
                    <label>
                        <input type="checkbox" (change)="onDoNotShowAgain($event)" />
                        {{ 'Do not show again' | translate }}
                    </label>
                </p>
            </div>
        </div>
    </div>
</div>
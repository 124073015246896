<ng-container *ngIf="display === 'prediction'; else elseTemp">
    <div class="prediction-timer text-center">
        <ng-container *ngIf="days > 0">
            <div class="bloc-time">
                <span class="d-block label">{{ 'Day' | translate }}</span>
                <div [innerHtml]="onFormatTimer(days)"></div>
            </div>
            <div>
                <span class="d-block" style="height: 10px;"></span>
                <div class="separator">:</div>
            </div>
        </ng-container>
        <div class="bloc-time" >
            <span class="d-block label">{{ 'Hour' | translate }}</span>
            <ng-container *ngIf="hours >= 0">
                <div [innerHtml]="onFormatTimer(hours)"></div>
            </ng-container>
        </div>
        <div>
            <span class="d-block" style="height: 10px;"></span>
            <div class="separator" *ngIf="hours >= 0">:</div>
        </div>
        <div class="bloc-time" >
            <span class="d-block label">{{ 'Minute' | translate }}</span>
            <ng-container *ngIf="minutes >= 0">        
                <div [innerHtml]="onFormatTimer(minutes)"></div>
            </ng-container>
        </div>
        <ng-container *ngIf="days == 0">
            <div>
                <span class="d-block" style="height: 10px;"></span>
                <div class="separator">:</div>
            </div>
            <div class="bloc-time">
                <span class="d-block label">{{ 'Second' | translate }}</span>
                <div [innerHtml]="onFormatTimer(seconds)"></div>
            </div>
        </ng-container>
    </div>
</ng-container>
<ng-template #elseTemp>
    <p class="m-t-0 p-l-0" *ngIf="days > 0">
        {{'will be back in [days] day(s) [hours] hour(s) [minutes] minute(s) [seconds] second(s)' | translate : { 'days' : translateService.instant(''+days), 'hours' :
        translateService.instant(''+hours), 'minutes': translateService.instant(''+minutes), 'seconds':
        translateService.instant(''+seconds) } }}
    </p>
    <p class="m-t-0 p-l-0" *ngIf="days == 0 "> {{'will be back in [hours] hour(s) [minutes] minute(s) [seconds] second(s)' | translate : { 'hours' :
        translateService.instant(''+hours), 'minutes': translateService.instant(''+minutes), 'seconds':
        translateService.instant(''+seconds) } }}
    </p>
</ng-template>
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ordinalDate'
})
export class OrdinalDatePipe implements PipeTransform {
  transform(value: any): string {
    value = new Date(value);
    if (!value) {
      return '';
    }
   let months= ["January","February","March","April","May","June","July",
           "August","September","October","November","December"]
    return `${months[value.getMonth()]} ${value.getDate()}${this.nth(value.getDate())}  ${value.getFullYear()}`;
  }

 nth(d) {
  if (d > 3 && d < 21) return 'th'; 
  switch (d % 10) {
    case 1:  return "st";
    case 2:  return "nd";
    case 3:  return "rd";
    default: return "th";
  }
}
}
<div class="modal show sign-in" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog-centered">
        <div class="login-modal">
            <div class="modal-content modal-bg modal-signin">
                <div class="modal-header pb-0">
                    <h4 class="modal-title" id="myModalLabel"><img src="/assets/images/forgotpassword.png" width="25" class="mr-2"> {{ 'Forgot Username' | translate | uppercase }}</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseDialog()">×</button>
                </div>

                <form [formGroup]="form" class="mb-0" id="forgot-username-modal" name="forgot-username-modal">
                    <div class="modal-body pt-0">
                        <hr>
                        <div class="form-input-label">
                            <p>{{ ('Email' | translate) | titlecase }}</p>
                        </div>
                        <div class="input_container">
                              <input type="text" [ngClass]="{'form-invalid': checkValidity && form.controls.email.errors}" formControlName="email" class="form-control form-control-sm form-input-style" #focusInput>
                        </div>
                        <button class="btn-blue-gra" (click)="onSubmit()">{{ 'Submit' | translate | uppercase }}</button>
                        <p class="m-t-20 text-center">{{'Not registered yet?' | translate}} <a routerLink="/signup" class="btn-signup" (click)="onCloseDialog()"> {{'Register Now' | translate}}</a></p>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="modal1" id="modal-signup">
    <div class="block mx-auto ma-box boxshadow">
      <div class="row">
        <div class="col-12">
          <div class="content bank-modal-content">
            <div class="modal show sign-in" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-body">
                      <div class="modal-content modal-bg modal-blue-bg">
                          <div class="modal-header">
                              <h4 class="modal-title" id="myModalLabel">{{ 'PHONE NUMBER VERIFICATION' | translate }} </h4>
                              <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseDialog()">×</button>
                          </div>
                          <div class="bd-bold"></div>
                            <div class="modal-body">
                              <p>{{ 'You have reach the daily limit of requesting OTP, please contact support for help.' | translate }} <br></p>
                              <button type="button" class="btn-blue-gra pl-3 pr-3" (click)="onCloseDialog()">{{ 'Okay' | translate | uppercase }} </button>
                            </div>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  
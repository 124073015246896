import { Component, ElementRef, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { of } from 'rxjs';
import { catchError, delay, tap } from 'rxjs/operators';
import { PromotionContentHttpService } from '../../services/promotion-content-http.service';

@Component({
  selector: 'app-promotion-application',
  templateUrl: './promotion-application.component.html',
  styleUrls: ['./promotion-application.component.scss']
})
export class PromotionApplicationComponent implements OnInit {

  @ViewChildren('focusInput') focusInput: QueryList<ElementRef>;
  messages$ = this.promotionContentHttpService.messages$;
  isSuccess = this.promotionContentHttpService.isSuccess;
  form: FormGroup;
  checkValidity = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { applyData: any; profile: any },
    private promotionContentHttpService: PromotionContentHttpService,
    public dialogRef: MatDialogRef<PromotionApplicationComponent>,
    public loadingBar: LoadingBarService,
  ) { }

  ngOnInit(): void {
    this.formInit();
  }

  onCloseDialog(event?: Event ) {
    this.dialogRef.close();
  }

  onSave(){
    this.checkValidity = true;
    const data = {
      ...this.form.value
    };
    if (this.form.valid) {
      this.loadingBar.start();
      this.promotionContentHttpService.promoApply(data).pipe(
        catchError(error => {
          this.checkValidity = false;
          throw error;
        })
      )
      .subscribe((res) => {
        this.checkValidity = false;
        this.loadingBar.complete();
        this.isSuccess = this.promotionContentHttpService.isSuccess;
      });
    }
  }

  private formInit() {
    let fields: any;
    this.data.applyData.required_fields.map( res => {
      fields = {
        promotion_content_id: new FormControl(this.data.applyData.id),
        ...fields, [res.toLocaleLowerCase()]: new FormControl(this.data.profile[res.toLocaleLowerCase()], res !== 'Email' ? [Validators.required] : [Validators.pattern('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$'), Validators.required])
      };
    });
    this.form = new FormGroup(fields);
    of(null).pipe(
      delay(200), tap(() => this.focusInput.first.nativeElement.focus()
    )).subscribe();
  }


}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthData } from '@core/models/auth-data';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DashboardService } from '@core/services/dashboard.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { GameCategoryHttpService } from '@core/services/game-category-http.service';
import { GameProviderHttpService } from '@core/services/game-provider-http.service';
import { PortalTransferHttpService } from '@core/services/portal-transfer-http.service';
import { WalletHttpService } from '@core/services/wallet-http.service';
import { logout } from '@core/store/auth/auth.actions';
import { isLoggedIn, loggedUser } from '@core/store/auth/auth.selectors';
import { select, Store } from '@ngrx/store';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from '@store/reducers';
import { forkJoin, Observable, Subject, Subscription, zip } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import Swal, { SweetAlertCustomClass } from 'sweetalert2';
import { RestoreInfoComponent } from './dialogs/restore-info/restore-info.component';
import svgIconList from 'assets/icons.json';

@Component({
  selector: 'app-wallet-bar',
  templateUrl: './wallet-bar.component.html',
  styleUrls: ['./wallet-bar.component.scss']
})
export class WalletBarComponent implements OnInit, OnDestroy {
  svg: any = svgIconList;

  memberBalance: number;
  memberUsername$ = this.dashboardService.memberUsername$;
  showOnCurrentPage: Boolean;

  memberCurrency = '';

  loggedUser$: Observable<AuthData>;
  isLoggedIn$: Observable<boolean>;
  isClicked = false;

  isDemo$ = this.gameProviderHttpService.isDemo$;
  customClass: SweetAlertCustomClass = {
    popup: 'customPopup',
    content: 'customContent'
  };
  doNotShowRestoreInfo = sessionStorage.getItem('doNotShowRestoreInfo') === null ? false : JSON.parse(sessionStorage.getItem('doNotShowRestoreInfo'));

  messages$ = new Subject<any[]>();

  private subscription = new Subscription();
  private subscriptions: Subscription[] = [];

  constructor(
    private dashboardService: DashboardService,
    private walletHttpService: WalletHttpService,
    private store: Store<AppState>,
    public loadingBar: LoadingBarService,
    private authService: AuthHttpService,
    private transferService: PortalTransferHttpService,
    private router: Router,
    private gameProviderHttpService: GameProviderHttpService,
    private gameCategoryHttpService: GameCategoryHttpService,
    public dialog: MatDialog,
    private translateService: TranslateService,
    private eventEmitterService: EventEmitterService,
  ) { }

  ngOnInit() {
    if (this.router.url === '/member/settings' || this.router.url === '/member/wallet' || this.router.url === '/member/reward' || (this.router.url).startsWith('/member/message/') || (this.router.url).startsWith('/member/history/') || this.router.url === '/member/verification-info') {
      this.showOnCurrentPage = false;
    } else {
      this.showOnCurrentPage = true;
      this.loggedUser$ = this.store.pipe(
        select(loggedUser),
        tap((user) => {
          this.dashboardService.refreshMemberName(user.username);
        })
      );
      this.isLoggedIn$ = this.store.pipe(select(isLoggedIn));
      this.loggedUser$.subscribe(res => {
        if (res !== null) {
          this.setWalletDetails();
        }
      });
    }
    this.subscriptions.push(this.eventEmitterService.restoreInfoEmitter.subscribe((checked) => {
      this.doNotShowRestoreInfo = checked;
    }));
    this.subscriptions.push(this.eventEmitterService.restoreEmitter.subscribe(() => {
      this.restore();
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }

  onRestoreInfo() {
    this.dialog.open(RestoreInfoComponent);
  }

  async onRefreshBalance() {
    this.isClicked = true;
    await this.getWalletDetails();
    this.isClicked = false;
  }

  get isTokenExpired() {
    if (!this.authService.isTokenExpired()) {
      return false;
    }
    this.store.dispatch(logout());
    localStorage.setItem('redirectToLogin', '1');
    return true;
  }

  onRestore() {
    if (!this.doNotShowRestoreInfo) {
      this.onRestoreInfo();
    } else {
      this.restore();
    }
  }

  private async setWalletDetails() {
    if (!this.authService.isTokenExpired()) {
      if (this.walletHttpService.memberBalance === undefined) {
        await this.getWalletDetails();
      }
      this.subscriptions.push(
        this.walletHttpService.memberBalance.subscribe((res) => {
          this.memberBalance = res.balance;
          this.memberCurrency = res.currency_code ? res.currency_code : '$';
          this.isClicked = false;
        })
      );
    }
  }

  private getWalletDetails() {
    return new Promise<void>((resolve, reject) => {
      this.walletHttpService.getMemberBalance().subscribe(() => resolve());
    });
  }

  private restore() {
    this.loadingBar.start();
    const transfers = [];
    this.gameCategoryHttpService.getCategoryList()
      .pipe(
        tap((providers: any) => {
          providers.rows.map(provider => {
            if (provider.balance > 0.00 && provider.status === 1) {
              transfers.push(this.transferService.transferBy(provider.id, 'all', 2));
            }
          });
          if (transfers.length === 0) {
            this.loadingBar.complete();
            this.messages$.next([this.translateService.instant('All credits are in Main Wallet')]);
          } else {
            zip(...transfers).subscribe(() => {
              this.onRefreshBalance();
              this.getListPromoAndGameProvider();
              this.loadingBar.complete();
              if (transfers.length !== 0) {
                this.onSwal();
              }
            });
          }
        }),
      ).subscribe();
  }

  private getListPromoAndGameProvider() {
    this.gameCategoryHttpService.getGameCategoryListWithPromo(true).subscribe();
  }

  private onSwal() {
    Swal.fire({
      icon: 'success',
      html: '<div class="text-center">' + this.translateService.instant('Restore Successfully') + ' </div>',
      toast: true,
      showConfirmButton: false,
      customClass: this.customClass,
      position: 'top',
      timer: 3000,
      hideClass: {
        popup: `animate__animated animate__bounceOutUp`
      },
    });
  }
}

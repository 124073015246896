import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { EventEmitterService } from '@core/services/event-emitter.service';
import svgIconList from 'assets/icons.json';

@Component({
  selector: 'app-transfer-wrapper-modal',
  templateUrl: './transfer-wrapper-modal.component.html',
  styleUrls: ['./transfer-wrapper-modal.component.scss']
})
export class TransferWrapperModalComponent implements OnInit {
  svg: any = svgIconList;

  constructor(
    public dialogRef: MatDialogRef<TransferWrapperModalComponent>,
    private eventEmitterService: EventEmitterService,
) { }

  ngOnInit() { 
    this.eventEmitterService.updateProfileErrorCloseAllModalEmitter.subscribe(() => {
      this.onCloseDialog();
    })
  }

  onCloseDialog(event?: Event ) {
    this.dialogRef.close();
  }
}

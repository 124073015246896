import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DesktopGuard } from '@core/guards/desktop.guard';
import { MaintenanceGuard } from '@core/guards/maintenance.guard';
import { ChangePasswordMsgResolver } from '@core/resolvers/change-password-msg.resolver';
import { MaintenanceResolver } from '@core/resolvers/maintenance.resolver';
import { MemberComponent } from '@views/modules/member/member.component';
import { AuthGuard } from './core/guards/auth.guard';
import { CleanModeGuard } from '@core/guards/clean-mode.guard';

const routes: Routes = [
  // Member Module
  {
    path: 'member',
    component: MemberComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./views/modules/member/member.module').then(m => m.MemberModule),
        data: { title: 'Member' },
      }
    ],
    canActivate: [DesktopGuard, MaintenanceGuard],
    canActivateChild: [AuthGuard],
  },
  // Lucky Spin Module
  {
    path: 'luckyspin',
    loadChildren: () => import('./views/modules/lucky-spin/lucky-spin.module').then(m => m.LuckySpinModule),
    data: { title: 'Lucky Spin' },
    resolve: {
      profile: ChangePasswordMsgResolver
    },
    canActivate: [DesktopGuard, MaintenanceGuard, CleanModeGuard]
  },
  // Info Module
  {
    path: 'info-center',
    loadChildren: () => import('./views/modules/info/info.module').then(m => m.InfoModule),
    data: { title: 'Info Center' },
    canActivate: [DesktopGuard, MaintenanceGuard, CleanModeGuard]
  },
  // Referral Module
  {
    path: 'referral',
    loadChildren: () => import('./views/modules/referral/referral.module').then(m => m.ReferralModule),
    data: { title: 'Referral' },
    canActivate: [DesktopGuard, MaintenanceGuard, CleanModeGuard]
  },
  // VIP Module
  {
    path: 'vip',
    loadChildren: () => import('./views/modules/vip/vip.module').then(m => m.VipModule),
    data: { title: 'VIP' },
    resolve: {
      profile: ChangePasswordMsgResolver
    },
    canActivate: [DesktopGuard, MaintenanceGuard, CleanModeGuard]
  },
  // Promotion Module
  {
    path: 'promotion',
    loadChildren: () => import('./views/modules/promotion/promotion.module').then(m => m.PromotionModule),
    data: { title: 'Promotion' },
    resolve: {
      casino: MaintenanceResolver,
      profile: ChangePasswordMsgResolver
    },
    canActivate: [DesktopGuard, MaintenanceGuard, CleanModeGuard]
  },
  // External
  {
    path: 'external',
    loadChildren: () => import('./views/modules/external-link/external-link.module').then(m => m.ExternalLinkModule),
    data: { title: 'External Link' },
    resolve: {
      content: MaintenanceResolver,
      profile: ChangePasswordMsgResolver
    },
    canActivate: [DesktopGuard, MaintenanceGuard, CleanModeGuard]
  },
  // Ambassador Module
  // {
  //   path: 'ambassador',
  //   loadChildren: () => import('./views/modules/ambassador/ambassador.module').then(m => m.AmbassadorModule),
  //   data: { title: 'Ambassador' },
  //   canActivate: [DesktopGuard, MaintenanceGuard]
  // },
  // Main Module ** Game Module moved into this module **
  {
    path: '',
    loadChildren: () => import('./views/modules/main/main.module').then(m => m.MainModule)
  },
  // Default
  {
    path: '**', redirectTo: '/', pathMatch: 'full'
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

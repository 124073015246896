import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@core/models/api-response.model';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { MemberModule } from '../member.module';

@Injectable({
  providedIn: MemberModule
})
export class NumberVerificationHttpService {
  messages$ = new Subject<any[]>();
  isSuccess: boolean;
  constructor(private http: HttpClient) { }

  requestOTP(new_mobile_number: string, request_type: string){
    const parameter = new_mobile_number !== null ? `?mobile=${new_mobile_number}&request_type=${request_type}` : '';
    return this.http.get<ApiResponse>(`/otp/request${parameter}`).pipe(
      map(res => {
        this.messages$.next(res.message);
        this.isSuccess = res.success;
      })
    );
  }

  verifyOTP(data: any){
    return this.http.post<ApiResponse>(`/otp/verify`, data);
  }

}

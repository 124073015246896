import { DatePipe, Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiResponse } from '@core/models/api-response.model';
import { AuthRegister } from '@core/models/auth-register.model';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { logout } from '@core/store/auth/auth.actions';
import { isLoggedIn } from '@core/store/auth/auth.selectors';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { GameProviderHttpService } from './game-provider-http.service';
import { LiveChatHttpService } from '@core/services/livechat-http-service';
import * as CryptoJS from 'crypto-js';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthHttpService {


  messages$ = new Subject<any[]>();
  isSuccess: boolean;
  isUserLoggedIn: boolean;
  todayDate = new Date();

  // Marketing
  marketingStorage = 'marketing_campaign';
  sourceTracker: string;

  constructor(
    private http: HttpClient,
    private store: Store,
    private location: Location,
    private datePipe: DatePipe,
    private eventEmitterService: EventEmitterService,
    private gameproviderHttpService: GameProviderHttpService,
    private router: Router,
    private livechatHttpService: LiveChatHttpService
  ) {
    this.store.pipe(select(isLoggedIn)).subscribe(res => this.isUserLoggedIn = res);
  }

  login(username: string, password: string, operator?: number) {
    return this.http.post<ApiResponse>('/login', { username, password, operator }).pipe(
      tap(res => {
        const { user } = res.data;
        this.setDate();
        this.messages$.next(res.message);
        this.resetDemoMode();

        if (user.change_password) {
          this.router.navigateByUrl('/member/change-password');
        }
      })
    );
  }

  tokenLogin(access_token: string, plaintext_token: string) {
    return this.http.post<ApiResponse>('/login', { access_token, plaintext_token }).pipe(
      tap(res => {
        this.setDate();
        this.isSuccess = res.success;
        this.messages$.next(res.message);
        this.resetDemoMode(); 
      })
    );
  }

  recaptcha(data: any) {
    return this.http.post<ApiResponse>(`/recaptcha`, data);
  }

  validate(data: any) {
    return this.http.post<ApiResponse>('/validate', data);
  }

  register(value: AuthRegister) {
    let addons: any = { url: (localStorage.getItem('signupRoute') ? localStorage.getItem('signupRoute') : window.location.href) };

    let marketingCampaign = JSON.parse(localStorage.getItem(this.marketingStorage));
    if (marketingCampaign !== null && ('sub2' in marketingCampaign) && marketingCampaign['sub2'].length > 0 && !marketingCampaign['sub2'].startsWith('http')) {
      marketingCampaign['sub2'] = 'https://' + marketingCampaign['sub2'];
    }
    const paramsMarketing = marketingCampaign;

    // ONLY get specific query string vars!
    if (paramsMarketing) {
      // Marketing vars
      addons = {
        ...addons,
        campaign: paramsMarketing?.campaign,
        cid: paramsMarketing?.cid,
        clickid: paramsMarketing?.clickid,
        referral_source: paramsMarketing?.referral_source,
        telemarketer_id: paramsMarketing?.telemarketer_id,
      };
    }

    const payload = { ...value, ...addons };
 
    const timestamp = Math.floor(Date.now() / 1000); // in seconds
    const secretKey = environment.reqSignKey; // Make sure this matches backend APP_REQ_SIGN_KEY
    const signature = CryptoJS.HmacSHA256(JSON.stringify(payload) + timestamp, secretKey).toString();

    const headers = {
      'X-Signature': signature,
      'X-Timestamp': timestamp.toString()
    };

    return this.http.post<ApiResponse>('/register', payload, { headers }).pipe(
      tap(res => {
        this.isSuccess = res.success;
        this.messages$.next(res.message);
      })
    );
  }

  logout() {
    this.resetDemoMode('logout');
    localStorage.removeItem(this.marketingStorage);
    localStorage.removeItem('ref');
    localStorage.removeItem('agent');
    localStorage.removeItem('aff');
    this.livechatHttpService.clearCookies();
    return this.http.get('/logout');
  }

  getUrlParams(search: string) {
    const hashes = search.slice(search.indexOf('?') + 1).split('&');
    return hashes.reduce((params, hash) => {
      const [key, val] = hash.split('=');
      return Object.assign(params, { [key]: decodeURIComponent(val) });
    }, {});
  }

  getWhitelist(id: number) {
    return this.http.post<ApiResponse>('/whitelist', {id}).pipe(
        map(res => res.data)
    );
  }

  updateCampaignClickCount(campaign: string) {
    return this.http.post<ApiResponse>('/campaign/updateclickcount', { campaign }).pipe(
      tap(res => {
        this.isSuccess = res.success;
      })
    );
  }

  updateAffiliateClickCount(aff: string) {
    return this.http.post<ApiResponse>('/affiliate/updateclickcount', { aff }).pipe(
      tap(res => {
        this.isSuccess = res.success;
      })
    );
  }

  forceLowerCaseInputControl(formGroup: FormGroup, formControlName: string, event: Event) {
    formGroup.get(formControlName).setValue((event.target as HTMLInputElement).value.toLowerCase());
  }

  checkTokenExpiration(expiryTime: any) {
    const tokenExpiryDateUTC = moment(expiryTime);
    const currentDateUTC = moment.utc();

    // Private page only
    if (
      this.isUserLoggedIn &&
      (this.location.path()).startsWith('/member') &&
      tokenExpiryDateUTC.diff(currentDateUTC, 'minutes') <= 0
    ) {
      this.store.dispatch(logout());
    }

    if (tokenExpiryDateUTC.diff(currentDateUTC, 'minutes') <= 0) {
      localStorage.removeItem('user_token');
    }

    return false; // Not expired yet!

  }

  // Note: This method must be called when there is private API endpoint
  // iniated from any of the public pages.
  isTokenExpired() {
    const userToken = JSON.parse(localStorage.getItem('user_token'));

    if (userToken !== null) {
      const expiryDateTime = userToken ? userToken.expiry_datetime : null;
      if (!this.checkTokenExpiration(expiryDateTime)) {
        return false;
      }

    }
    return true;
  }

  updateLocale() {
    return this.http.post<ApiResponse>('/locale', null);
  }

  getAffiliateAndMarketingParams() {
    const locationSearch = window.location.search;
    if (locationSearch) {
      localStorage.setItem(this.marketingStorage, JSON.stringify(this.getUrlParams(locationSearch) as any));

      var marketingCampaign = JSON.parse(localStorage.getItem(this.marketingStorage));
      if (marketingCampaign['ref'] !== undefined && marketingCampaign['ref'] !== '') {
        localStorage.setItem('ref', JSON.stringify(marketingCampaign['ref']));
      }
      if (marketingCampaign['agent'] !== undefined && marketingCampaign['agent'] !== '') {
        localStorage.setItem('agent', JSON.stringify(marketingCampaign['agent']));
      }
      if (marketingCampaign['aff'] !== undefined && marketingCampaign['aff'] !== '') {
        localStorage.setItem('aff', JSON.stringify(marketingCampaign['aff']));
      }

      // Only in Promotion page
      if (location.pathname === '/promotion') {
        localStorage.setItem('promotion_identifier', (this.getUrlParams(locationSearch) as any).code);
      }
    }
  }

  numberOnly(event: KeyboardEvent): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  private setDate() {
    const dateToday = this.datePipe.transform(this.todayDate, 'yyyy-MM-dd');
    if (localStorage.getItem('date_today') !== dateToday) {
      localStorage.setItem('date_today', dateToday);
      this.eventEmitterService.onPopUpAnnouncementEmitter();
    }
  }

  // Reset back values to default
  private resetDemoMode(action?: string) {
    this.gameproviderHttpService.isDemo$.next((action === 'logout' ? true : false));
    this.gameproviderHttpService.isSlotsPage$.next(false);
    localStorage.setItem('isDemoSelected', '0');
    localStorage.setItem('isShowDemoNotice', '1');
  }

}

// Angular
import { HttpClient } from '@angular/common/http';
// Service
import { TranslateLoader } from '@ngx-translate/core';
// Third Party
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { catchError, map } from 'rxjs/operators';

export class MultiTranslateHttpLoader implements TranslateLoader {

  constructor(private http: HttpClient,
    public resources: { prefix: string, suffix: string, fileName: string }[]) { }

  /**
 * Gets the translations from the server
 * @param lang
 * @returns {any}
 */
  public getTranslation(lang: string): any {
    return forkJoin(this.resources.map(config => {
      return this.http.get(`${config.prefix}${lang.toLowerCase()}/${config.fileName}${config.suffix}`);
    })).pipe(
      map(response => {
        return response.reduce((a, b) => {
          return Object.assign(a, b);
        });
      }),
      catchError(() => {
        return [{}];
      })
    )
  }
}
<div class="modal show">
    <div class="modal-dialog modal-dialog-centered modal1" id="modal-site-route">
        <div class="modal-body">
            <div class="modal-content modal-bg demomode-notice">
                <div class="notice-body">
                    <p class="icon-wrapper"><span class="icon">i</span></p>
                    <h6 class="text-center">{{ 'Announcement' | translate }}</h6>
                    <div *ngIf="sitePrefix != 'BP9'">
                        <p class="text-center" [innerHTML]="'Dear [username], we\'re happy to announce BP77 is going international. You may login from our new link <b>[link]</b> with your existing username and password.' | translate: {username: username, link: link}"></p>
                        <p class="text-center" [innerHTML]="'Press the <b>\'CONTINUE\'</b> button below to proceed to BP9!' | translate"></p>
                        <button type="button" class="btn-blue-gra" (click)="redirectsite();" [disabled]="disableButton">{{ 'Continue' | translate | uppercase }}</button>
                    </div>
                    <div *ngIf="sitePrefix == 'BP9'">
                        <p class="text-center" [innerHTML]="'Welcome to BP9! Save our new link <b>[link]</b> for your ease of access in the future.' | translate: {link: link}"></p>
                        <button type="button" class="btn-blue-gra" (click)="closedialog();">OK</button>
                    </div>
                </div>
                <p class="confirm-text text-center m-t-15 m-b-20" *ngIf="sitePrefix == 'BP9'">
                    <a class="do-not-show-btn" (click)="hidenotice()"><u>{{ 'Do not show again' | translate }}.</u></a>
                </p>
            </div>
        </div>
    </div>
 </div>
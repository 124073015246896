<div class="modal show sign-in" aria-labelledby="mySmallModalLabel">
    <div class="modal-dialog-centered">
        <div class="login-modal">
            <div class="modal-bg modal-signin">
                <div class="modal-header pb-0">
                    <h4 class="modal-title" id="myModalLabel"> {{ 'Cancel Promotion' | translate }}</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseDialog()">×</button>
                </div>
                <div class="modal-body pt-0">
                    <hr>
                    <div class="form-input-label">
                        <p>{{ 'Promotion Name' | translate }}:</p>
                    </div>
                    <div class="input_container">
                        <input type="text" class="form-control form-control-sm form-input-style" [value]="promotionName" readonly>
                    </div>
                    <div class="form-input-label">
                        <p>{{ 'Game Provider' | translate }}:</p>
                    </div>
                    <div class="input_container">
                        <angular2-multiselect
                            [data]="data.activePromotionList"
                            [(ngModel)]="selectedGameProvider"
                            [settings]="gameProviderDropDownSetting"
                            (onSelect)="onSelectGameProvider($event)"
                            [ngModelOptions]="{standalone: true}"
                            (onDeSelect)="OnDeSelectGameProvider($event)"
                            (onDeSelectAll)="OnDeSelectAllGameProvider($event)">
                        </angular2-multiselect>
                    </div>
                    <div #falseClick></div>
                    <div class="form-input-label m-t-20" *ngIf="gameSelected">
                        <p>{{ 'Wallet Balance' | translate }}:</p>
                    </div>
                    <div class="input_container" *ngIf="gameSelected">
                        <input type="text" class="form-control form-control-sm form-input-style" [value]="amountText" readonly>
                    </div>
                    <div class="form-input-label" *ngIf="gameSelected">
                        <p>{{ 'Date' | translate }}:</p>
                    </div>
                    <div class="input_container" *ngIf="gameSelected">
                        <input type="text" class="form-control form-control-sm form-input-style" [value]="data.detail.start_datetime | date: 'yyyy-MM-dd HH:mm'" readonly>
                    </div>
                    <div class="form-input-label" *ngIf="gameSelected">
                        <p>{{ 'Status' | translate }}:</p>
                    </div>
                    <div class="input_container" *ngIf="gameSelected">
                        <input type="text" class="form-control form-control-sm form-input-style" [value]="translateStatus(memberPromotionEnum[data.detail.status])" readonly>
                    </div>
                    <div class="amount-hint-text" *ngIf="gameSelected">
                        <p>
                            *{{ 'The amount of [amount] will be forfeited' | translate: {'amount': bonusAmountText } }}
                        </p>
                        <p>
                            {{ bonusText }}
                        </p>
                        <p>
                            {{ winText }}
                        </p>
                    </div>
                    <span class="confirm-cancel-text" *ngIf="gameSelected">{{ 'Are you sure you want to cancel this promotion?' | translate}}</span>
                    <button class="btn-red-gra m-t-10" (click)="onSave()" [disabled]="buttonDisabled">
                        {{ 'Confirm' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>
  </div>
  
  <app-swal-alert [message]="messages$ | async" [success]="isSuccess" (confirmed)="onCloseDialog(true)" [timer]="5000"></app-swal-alert>
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { DashboardService } from '@core/services/dashboard.service';
import { GameCategoryHttpService } from '@core/services/game-category-http.service';
import { PortalTransferHttpService } from '@core/services/portal-transfer-http.service';
import { WalletHttpService } from '@core/services/wallet-http.service';
import { isLoggedIn } from '@core/store/auth/auth.selectors';
import { select, Store } from '@ngrx/store';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { TranslateService } from '@ngx-translate/core';
import { LoginModalComponent } from '@shared/login-modal/login-modal.component';
import { AppState } from '@store/reducers';
import { Subscription, forkJoin } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import svgIconList from 'assets/icons.json';

@Component({
  selector: 'app-game-sub-sub-component',
  templateUrl: './game-sub-sub-component.component.html',
  styleUrls: ['./game-sub-sub-component.component.scss']
})
export class GameSubSubComponentComponent  implements OnInit, OnDestroy {
  svg: any = svgIconList;

  form: FormGroup;
  routeSub = new Subscription();
  providerCode: string;
  slotCode: string;
  gameType: string;
  isLogged: boolean;

  @Input()
  providerName: string;

  @Input()
  gameCode: string;

  @Input()
  header: string;

  @Input()
  logo: string;

  @Input()
  miniURL: string;

  @Input()
  username: string;

  @Input()
  password: string;

  @Input()
  iosURL: string;

  @Input()
  androidURL: string;

  @Input()
  appId: string;

  @Input()
  appFileName: string;

  @Input()
  appBalance: number;

  @Input()
  appGameProvider: number;

  isSuccess = false;
  messages$ = this.transferService.messages$;
  transferedAmount: number;
  currency = JSON.parse(localStorage.getItem('user_data')).currency;
  walletBalance: number;
  deepLink: any = null; 
  private subscription = new Subscription();
  private subscriptions: Subscription[] = [];

  @ViewChild('tooltipUsername') tooltipUsername: MatTooltip;
  @ViewChild('tooltipPassword') tooltipPassword: MatTooltip;

  constructor(
    private router: Router,
    private store: Store<AppState>,
    public translateService: TranslateService,
    public dialog: MatDialog,
    public loadingBar: LoadingBarService,
    private transferService: PortalTransferHttpService,
    private dashboardService: DashboardService,
    private walletHttpService: WalletHttpService,
    private gameCategoryHttpService: GameCategoryHttpService
  ) { }

  ngOnInit() {
    this.initializeForm();
    this.pageInit();

    if (/Android/i.test(navigator.userAgent) && this.androidURL != "") {
      this.deepLink =  this.androidURL;
    } else if (/iPad|iPhone|iPod/i.test(navigator.userAgent) && this.iosURL != "") {
      this.deepLink = this.iosURL;
    }

    if (this.appGameProvider) {
      this.form.patchValue({
        transfer_from: 0,
        transfer_to: this.appGameProvider['game_provider_id']
      });
    }
    this.subscriptions.push(
      this.walletHttpService.memberBalance.subscribe(wallet => {
        this.walletBalance = wallet.balance;
        this.form.patchValue({
          amount: wallet.balance
        });
      })
    );
    this.loadingBar.complete();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }

  private pageInit() {
    this.store.pipe(select(isLoggedIn))
      .pipe(
        tap(res => {
          if (!res) {
            this.openDialogBy(LoginModalComponent);
          }
        })
      ).subscribe();

    // TODO: Hold for now
    // this.routeSub = this.route.params.subscribe((params) => {
    //   this.providerCode = params.providerCode;
    //   this.slotCode = params.code;
    // });
  }

  onCopyText(type: string) {
    if (type === 'username') {
      this.tooltipUsername.show();
      setTimeout(() => this.tooltipUsername.hide(), 2000);
    } else {
      this.tooltipPassword.show();
      setTimeout(() => this.tooltipPassword.hide(), 2000);
    }
  }

  changeGamePassword() {
    this.router.navigate(['/member/game-password'], { state: { appId: this.appId, appFileName: this.appFileName, appUsername: this.username, gameCode: this.gameCode } });
  }

  onTransfer() {
    this.transferedAmount = +this.form.value.amount;
    if (this.form.valid) {
      this.loadingBar.start();
      const data = {
        amount: this.form.value.amount,
        promotion_id: this.form.value.promotion_id,
        transfer_from: this.form.value.transfer_from,
        transfer_to: this.form.value.transfer_to
      }
      this.submitData(data);
    }
  }

  private submitData(data: any) {
    this.transferService.transfer(data).subscribe(res => {
      this.isSuccess = res.success;
      this.messages$.next(res.message);
      this.loadingBar.complete()
      this.onResetForm();
      this.onRefreshWalletBalance();
      this.gameCategoryHttpService.getGameCategoryListWithPromo(true).subscribe();
    })
  }

  onResetForm() {
    const walletBalance = this.walletBalance - this.transferedAmount;
    this.walletBalance = walletBalance;
    this.appBalance = Number(this.appBalance) + this.transferedAmount;
    this.form.patchValue({
      amount: walletBalance
    });
  }

  onOpenDeepLink(): void {
		const deepLink = this.deepLink;

		window.open(deepLink, '_self');

		let appOpened = false;

		const onBlur = () => {
			appOpened = true;
		};

		const onFocus = () => {
			if (!appOpened) {
				Swal.fire({
					customClass: {
						popup: 'customPopup2',
						denyButton: 'deny-button',
						confirmButton: 'confirm-button',
					},
					html: `<img class="msg-icon" src="/assets/images/message-icon/exclamation.png">
							<div class="text-center m-t-20"> 
							${this.translateService.instant("[gameProvider] Not Installed", { gameProvider: this.providerName })} 
							</div><br><span>${this.translateService.instant("Download and install [gameProvider] first in order to launch the app", { gameProvider: this.providerName })}</span>`,
					reverseButtons: true,
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          denyButtonText: this.translateService.instant('BACK'),
          confirmButtonText: this.translateService.instant('DOWNLOAD')
				}).then((result) => {
					if (result.isConfirmed) {
						window.location.href = this.miniURL;
					}
				});
			}

			window.removeEventListener("blur", onBlur);
			window.removeEventListener("focus", onFocus);
		};

		window.addEventListener("blur", onBlur);
		window.addEventListener("focus", onFocus);

		setTimeout(() => {
			onFocus();
		}, 2000);
	}


  private openDialogBy(componentRef: any) {
    this.dialog.open(componentRef);
  }

  private initializeForm() {
    this.form = new FormGroup({
      amount: new FormControl(null),
      promotion_id: new FormControl(0),
      transfer_from: new FormControl(null, [Validators.required]),
      transfer_to: new FormControl(null, [Validators.required])
    });
  }

  private onRefreshWalletBalance() { // to refresh balance on top bar
    this.walletHttpService.getMemberBalance().subscribe();
  }

}
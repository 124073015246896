<hr>
<form [formGroup]="form" (ngSubmit)="submit()" id="transfer-modal-form" name="transfer-modal-form" class="mb-0">
  <div class="user-input">
    <ng-container *ngIf="!isPerProvider">
      <label>{{ 'Transfer From' | translate }} </label>
      <select [class]="(submitted && !transferToFromValidate) ? 'form-control is-invalid' : 'form-control'" formControlName="transfer_from" (change)="onTransferChange($event, 'from')">
        <option [value]="null" disabled>{{ 'Please Select' | translate }}</option>
        <ng-container *ngIf="walletBalance | async as memberWalletBalance">
          <option value="0">{{ 'Main Wallet' | translate }} ({{ memberWalletBalance | number : '1.2-2' }})</option>
        </ng-container>
        <ng-container *ngFor="let item of providersDropdown">
          <option [value]="item.id" [disabled]="item.status === 2">{{ item.name }} ({{ item.balance }})</option>
        </ng-container>
      </select>
      <label>{{ 'Transfer To' | translate }} </label>
      <select [class]="(submitted && !transferToFromValidate) ? 'form-control is-invalid' : 'form-control'" formControlName="transfer_to" (change)="onTransferChange($event)">
        <option [value]="null" disabled>{{ 'Please Select' | translate }}</option>
        <ng-container *ngIf="walletBalance | async as memberWalletBalance">
          <option value="0">{{ 'Main Wallet' | translate }} ({{ memberWalletBalance | number : '1.2-2' }})</option>
        </ng-container>
        <ng-container *ngFor="let item of providersDropdown">
          <option [value]="item.id" [disabled]="item.status === 2">{{ item.name}} ({{ item.balance }})</option>
        </ng-container>
      </select>
      <div *ngIf="submitted && !transferToFromValidate" class="error">Transfer to and transfer from must be different</div>
    </ng-container>

    <label>{{ 'Transfer Amount' | translate | uppercase }} </label>
    <input type="text" [ngClass]="{'is-invalid': (submitted && form.controls.amount.invalid)}" class="form-control form-control-sm form-input-style" formControlName="amount" digitOnly decimal="true" decimalSeparator="." pattern="^\d+(\.\d{1,2})?$" style="margin-bottom: 2px;" #focusInput/>
    <div *ngIf="submitted && form.controls.amount.invalid" class="error m-0">This field is required</div>
    <!-- <p class="user-input-msg">Minimum amount: RM10</p> FOR REFERENCE-->
    <div class="row transfer-amount-row">
        <div class="transfer-amount-box" *ngFor="let amount of amountButton">
            <div class="transfer-amount" (click)="onChangeAmount(amount)">{{ currency.code }} {{ amount }}</div>
        </div>
    </div>

    <div *ngIf="form.value.transfer_to > 0">
      <label> {{ 'Promotion' | translate | uppercase }} </label>
      <select class="form-control" formControlName="promotion_id" (change)="onSelectPromotion($event)">
        <option value="0" selected>{{ 'None' | translate }}</option>
        <option [value]="item.id" *ngFor="let item of promotions$; let i = index">{{ item.name === null ? (('Promotion' | translate) + ' ' + i) : item.name }}</option>
      </select>
      <p class="turn-over exchange-font" *ngIf="selectedPromotion !== null">
          {{ 'Turnover Requirement' | translate }}: <br>
          {{ currency.code }} {{
            onMultiplicand(+form.value.amount, selectedPromotion) }}
          x {{ selectedPromotion.target_multiplier }} = {{ currency.code + ' ' + (onTotalTurnOver(form.value.amount, selectedPromotion) | number: '1.2-2') }}
          <!-- {{ '(' + currency.code + ' ' + form.value.amount + ' + (' + currency.code + ' ' + form.value.amount + ' x ' + selectedPromotion.bonus_rate + ')) * ' + selectedPromotion.target_multiplier }} = {{ currency.code + ' ' + (onTotalTurnOver(form.value.amount, selectedPromotion) | number: '1.2-2') }} -->
      </p>
      <p class="text-blue exchange-font" *ngIf="selectedPromotion !== null">
          {{ 'Bonus' | translate }}: {{ currency?.code + ' ' + onBonusAmount(+form.value.amount, selectedPromotion) }}
      </p>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12">
      <button type="submit" class="btn-style1" [disabled]="disableButton">{{ 'Submit' | translate | uppercase }}</button>
    </div>
    <div class="col-12 skip-text">
      <p *ngIf="isPerProvider" (click)="onCloseDialog()"> {{ 'Skip' | translate | uppercase }} &gt;</p>
    </div>
  </div>
</form>

<div class="row m-l-0 m-r-0 m-t-30 all-in-list" *ngIf="!isPerProvider">
  <ng-container *ngFor="let item of providersList">
      <div class="col-all-in all-in-vendor p-l-0 p-r-0" (click)="item.status !== 2 ? onAllIn(item.id) : ''" [ngClass]="{'maintenance' : item.status ===2}">
      <!--<div *ngIf="item.status === 2" class="img-overlay"></div>-->
      <img [src]="'assets/images/vendor/' + item.image_path" [alt]="item.name" onerror="this.src='/assets/integrations/default2.jpg'" />
      <p>{{ ( item.balance ? currency?.code + ' ' + (item.balance | number: '1.2-2') : 'N/A')  }} </p>
      <div class="btn-allin" [ngClass]="item.status === 2 ? 'disabled' : ''">
        <a>{{ (item.status !== 2 ? 'All In' : 'Maintenance').toString() | translate  }}</a>
      </div>
    </div>
  </ng-container>
</div>
<app-popup-announcement *ngIf="announcements$.length > 0" [message]="announcements$" (confirmed)="onUpdateAnnouncement()"></app-popup-announcement>
  <app-swal-alert [success]="isSuccess" [message]="messages$ | async" (confirmed)="closeDialog($event)" [timer]="5000"></app-swal-alert>


<div class="modal show sign-in" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog-centered">
        <div class="signup-modal">
            <div class="signup-container modal-content">
                <div class="modal-header pb-0">
                    <h4 class="modal-title" id="myModalLabel">
                        <span [innerHTML]="svg.languageIcon | safeHtml" class="m-2"></span>
                        {{ 'Region & Language' | translate }}
                    </h4>
                    <button type="button" class="close " data-dismiss="modal" aria-hidden="true" (click)="onCloseDialog()">×</button>
                </div>
                <div class="modal-body p-t-0">
                    <hr>
                    <div class="landing-wrapper">
                            <!-- CONTENT -->
                            <div class="landing-body">
                                <!-- BEFORE LOGIN -->
                                <ng-container *ngIf="isLoggedIn === false; else elseAfterLogin">
                                    <div class="row languages-country"
                                        *ngFor="let item of availableCountryAndLanguages[currentDomain] | keyvalue: returnZero">
                                        <div class="col-2 center">
                                            <img src="/assets/images/language-icon/{{ item.key }}.png"
                                                alt="{{ item.value.name }} Flag Icon" width="40px" />
                                        </div>
                                        <div class="col-10">
                                            <div class="row">
                                                <h6>{{ item.value.name | translate }}</h6>
                                            </div>
                                            <div class="row">
                                                <ng-container *ngFor="let code of item.value.languages; let i = index">
                                                    <div class="col-4" [ngClass]="i > 0 ? 'text-center' : ''"><a
                                                            (click)="setLanguage(code, item.key)"
                                                            [ngClass]="(currentLanguageCode == code && currentCountryCode == item.key) ? 'selected' : ''">{{ languages[code] }}
                                                        </a>
                                                    </div>
                                                </ng-container>
                                        <ng-container *ngIf="item.value.languages.length == 0">
                                            <div class="col-12">
                                                <span>{{ 'Coming soon...' | translate }}</span>
                                            </div>
                                        </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <!-- AFTER LOGIN -->
                                <ng-template #elseAfterLogin>
                                    <div class="row languages-country">
                                        <div class="col-2 center">
                                            <img src="/assets/images/language-icon/{{ currentCountryCode }}.png"
                                                alt="{{ availableCountryAndLanguages[currentDomain][currentCountryCode].name }} Flag Icon"
                                                width="40px" />
                                        </div>
                                        <div class="col-10">
                                            <div class="row">
                                                <h6>{{ availableCountryAndLanguages[currentDomain][currentCountryCode].name | translate }}</h6>
                                            </div>
                                            <div class="row">
                                                <ng-container
                                                    *ngFor="let code of availableCountryAndLanguages[currentDomain][currentCountryCode].languages; let i = index">
                                                    <div class="col-4" [ngClass]="i > 0 ? 'text-center' : ''"><a
                                                            (click)="setLanguage(code, currentCountryCode)"
                                                            [ngClass]="(currentLanguageCode == code) ? 'selected' : ''">{{ languages[code] }}
                                                        </a>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="availableCountryAndLanguages[currentDomain][currentCountryCode].languages.length == 0">
                                            <div class="col-12">
                                                <span>{{ 'Coming soon...' | translate }}</span>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
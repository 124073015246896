import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatTooltip } from "@angular/material/tooltip";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";

@Component({
	selector: "app-game-modal",
	templateUrl: "./app-game-modal.component.html",
	styleUrls: ["./app-game-modal.component.scss"],
})
export class AppGameModalComponent implements OnInit {
	@ViewChild("tooltipUsername") tooltipUsername: MatTooltip;
	@ViewChild("tooltipPassword") tooltipPassword: MatTooltip;
	appDetails: any;
	gameProvider: any;
	deepLink: any = null;
    
	constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, public dialogRef: MatDialogRef<AppGameModalComponent>, private translateService: TranslateService) {
		this.appDetails = data.appDetails;
		this.gameProvider = data.gameProvider;
	}

	ngOnInit() {
		if (/Android/i.test(navigator.userAgent) && this.appDetails.android_url != "") {
			this.deepLink = this.appDetails.android_url;
		} else if (/iPad|iPhone|iPod/i.test(navigator.userAgent) && this.appDetails.ios_url != "") {
			this.deepLink = this.appDetails.ios_url;
		}
	}

	onCloseDialog(event?: Event) {
		this.dialogRef.close();
	}

	onCopyText(type: string) {
		if (type === "username") {
			this.tooltipUsername.show();
			setTimeout(() => this.tooltipUsername.hide(), 2000);
		} else {
			this.tooltipPassword.show();
			setTimeout(() => this.tooltipPassword.hide(), 2000);
		}
	}

	onOpenDeepLink(): void {
		const deepLink = this.deepLink;

		window.open(deepLink, '_self');

		let appOpened = false;

		const onBlur = () => {
			appOpened = true;
		};

		const onFocus = () => {
			if (!appOpened) {
				Swal.fire({
					customClass: {
						popup: 'customPopup2',
						denyButton: 'deny-button',
						confirmButton: 'confirm-button',
					},
					html: `<img class="msg-icon" src="/assets/images/message-icon/exclamation.png">
							<div class="text-center m-t-20"> 
							${this.translateService.instant("[gameProvider] Not Installed", { gameProvider: this.gameProvider.game_provider_name })} 
							</div><br><span>${this.translateService.instant("Download and install [gameProvider] first in order to launch the app", { gameProvider: this.gameProvider.game_provider_name })}</span>`,
					reverseButtons: true,
					showDenyButton: true,
					showCloseButton: true,
					showCancelButton: false,
					showConfirmButton: true,
					denyButtonText: this.translateService.instant('BACK'),
					confirmButtonText: this.translateService.instant('DOWNLOAD')
				}).then((result) => {
					if (result.isConfirmed) {
						window.location.href = this.appDetails.url;
					}
				});
			}

			// document.body.removeChild(iframe);
			window.removeEventListener("blur", onBlur);
			window.removeEventListener("focus", onFocus);
		};

		window.addEventListener("blur", onBlur);
		window.addEventListener("focus", onFocus);

		setTimeout(() => {
			onFocus();
		}, 2000);
	}
}

import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DashboardService } from '@core/services/dashboard.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { GameCategoryHttpService } from '@core/services/game-category-http.service';
import { LiveChatIdService } from '@core/services/live-chat-id.service';
import { WalletHttpService } from '@core/services/wallet-http.service';
import { isLoggedIn } from '@core/store/auth/auth.selectors';
import { select, Store } from '@ngrx/store';
import { LanguageModalComponent } from '@shared/language-modal/language-modal.component';
import { LoginModalComponent } from '@shared/login-modal/login-modal.component';
import { SignupModalComponent } from '@shared/signup-modal/signup-modal.component';
import { WalletModalComponent } from '@shared/wallet-modal/wallet-modal.component';
import { AppState } from '@store/reducers';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { LiveChatHttpService } from '@core/services/livechat-http-service';
import { LivechatHttpService } from '@core/services/livechat-http.service';
import { MemberBankHttpService } from '@core/services/member-bank-http.service';
import { Crisp } from "crisp-sdk-web";import { RequestReceiptHttpService } from '@core/services/request-receipt-http.service';
import { RequestReceipt } from '@core/models/request-receipt.model';
import svgIconList from 'assets/icons.json';

declare var $: any;
declare const window: any;
@Component({
  selector: 'app-footer-wrapper',
  templateUrl: './footer-wrapper.component.html',
  styleUrls: ['./footer-wrapper.component.scss']
})
export class FooterWrapperComponent implements OnInit, OnDestroy {
  svg: any = svgIconList;

  mode: string = window.mode;
  isLoggedIn$: Observable<boolean>;
  isAuthStandAlone = false;
  counter: number;
  timer: any;
  showSeconds = false;
  pathname: any;

  windowInnerHeight: number;
  languageCode = localStorage.getItem('language_code');
  countrycode = localStorage.getItem('country_code');
  siteName = environment.sitePrefix.toUpperCase();

  mainWalletBalance: number;
  totalGameBalance: number;
  gameCategoryList: any;

  onMaintenancePage = false;
  lcCustomVariables: any;
  verificationRejected: boolean;
  
  newRequestIncoming = this.requestReceiptHttpService.newRequestIncoming;
  depositData: RequestReceipt = null;

  private subscription = new Subscription();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public dialog: MatDialog,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private eventEmitterService: EventEmitterService,
    private liveChatIdService: LiveChatIdService,
    private dashboardService: DashboardService,
    private gameCategoryHttpService: GameCategoryHttpService,
    private walletHttpService: WalletHttpService,
    private livechatHttpService: LiveChatHttpService,
    private LivechatHttpService: LivechatHttpService,
    private requestReceiptHttpService: RequestReceiptHttpService,
    private memberBankHttpService: MemberBankHttpService,
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.onMaintenancePage = (window.location.pathname) == '/maintenance' ? true : false;
    }, 500)
    this.windowInnerHeight = window.innerHeight;
    this.pathname = window.location.pathname;
    if (this.route.routeConfig.component.name === 'AuthComponent') {
      this.isAuthStandAlone = true;
    }
    this.isLoggedIn$ = this.store.pipe(select(isLoggedIn));
    this.subscription = this.isLoggedIn$.subscribe(res => {
      if (res) {
        setTimeout(() => {
          var userdata = JSON.parse(localStorage.getItem('user_data'));
          if (userdata.country_code == 'ID') {
            this.getRejectedVerification(false);
          }
        }, 500);
        this.eventEmitterService.updateverificationEmitter.subscribe(() => {
          this.getRejectedVerification(true);
        });
        this.depositData = RequestReceiptHttpService.requestReceipt;
        this.newRequestIncoming.subscribe(() => {
          this.depositData = RequestReceiptHttpService.requestReceipt;
        });
        const apiParams = [];
        var interval = setInterval(() => {
          if (!this.livechatHttpService.getInfoOngoing) {
            const cookieData = this.livechatHttpService.getLiveChatInfoCookies();
            if (!cookieData['username']) { apiParams.push('username'); }
            if (!cookieData['membership']) { apiParams.push('membership'); }
            if (!cookieData['balance']) { apiParams.push('balance'); }
            if (!cookieData['last_played_game']) { apiParams.push('last_played_game'); }
            if (!cookieData['kyc_status']) { apiParams.push('kyc_status'); }
            if (!cookieData['next_required_deposit']) { apiParams.push('next_required_deposit'); }
            if (apiParams.length > 0) {
              clearInterval(interval);
              this.livechatHttpService.getLiveChatInfo(apiParams).subscribe(apiData => {
                this.lcCustomVariables = { ...cookieData, ...apiData };
              });
            } else {
              clearInterval(interval);
              this.lcCustomVariables = cookieData;
            }
          }
        }, 500);
      }
    });
    this.eventEmitterService.verificationTimerEmitter.subscribe((name: string) => {
      if (sessionStorage.getItem('OTP_timer') !== null) {
        this.onTimer(Number(sessionStorage.getItem('OTP_timer')) - 1);
      } else {
        this.onTimer(60 - 1);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onTimer(startTime: number) {
    this.counter = startTime;
    window.clearInterval(this.timer);
    this.timer = setInterval(() => {
      sessionStorage.setItem('OTP_timer', (this.counter--).toString());
      if (this.counter === 0) {
        window.clearInterval(this.timer);
        this.showSeconds = false;
        sessionStorage.removeItem('OTP_timer');
      }
    }, 1000);
  }

  onOpenDialog(type: string) {

    switch (type) {
      case 'login':
        this.openDialogBy(LoginModalComponent, 'login');
        break;

      case 'signup':
        this.openDialogBy(SignupModalComponent, 'signup');
        break;

      case 'wallet':
        this.openDialogBy(WalletModalComponent, 'wallet');
        break;

      case 'language':
        this.openDialogBy(LanguageModalComponent, 'language');
        break;
    }

  }

  onClickFooterMenu() {
    this.document.body.classList.remove('side-panel-open');
    this.document.body.classList.remove('no-scroll');
  }

  private openDialogBy(componentRef: any, type: string) {
    if (type !== 'language') {
      this.dialog.open(componentRef, {
        width: '800px',
      });
    } else {
      this.dialog.open(componentRef);
    }
  }

  openLiveChat() {
    this.gameCategoryHttpService.getGameCategoryListWithPromo().subscribe(res => {
      this.gameCategoryList = res;
      this.setParams(res, this.walletHttpService.memberBalance.value.balance);
    });
  }

  private setParams(gameCategory: any, res: any) {
    this.dashboardService.memberBalance$.next(res[0]);
    this.dashboardService.totalGameBalance$.next(gameCategory.total_game_balance);

    const chatParams = {
      depositor: JSON.parse(localStorage.getItem('user_data')).depositor,
      main_wallet_balance: res[0],
      game_wallet_balance: gameCategory.total_game_balance
    };
    if (this.lcCustomVariables) {
      const liveChatTranslate = this.livechatHttpService.getTranslation();
      const lcQueryStr = Object.keys(this.lcCustomVariables).map(key => encodeURIComponent(liveChatTranslate[key] + '=' + this.lcCustomVariables[key])).join('%26');
      chatParams['params'] = lcQueryStr;
    }

    const additionalQuery = Object.keys(chatParams).map(key => key + '=' + chatParams[key]).join('&');

    let AI_GroupId = 1;
    AI_GroupId = this.liveChatIdService.idStack[this.countrycode][this.languageCode];
    // window.open(`https://secure.livechatinc.com/licence/10515072/v2/open_chat.cgi?group=${AI_GroupId}&name=${JSON.parse(localStorage.getItem('user_data')).username}&${additionalQuery}`, '_blank');
    if (this.LivechatHttpService.website !== null && this.LivechatHttpService.website !== '') {
      if (this.LivechatHttpService.live_chat_platform_name == 'Live Chat') {
        window.open(this.LivechatHttpService.chat_page + `?group=${AI_GroupId}&name=${JSON.parse(localStorage.getItem('user_data')).username}&${additionalQuery}`, '_blank');
      } else if (this.LivechatHttpService.live_chat_platform_name == 'Crisp') {
        Crisp.chat.open();
      }
    } else if (this.LivechatHttpService.chat_page !== null && this.LivechatHttpService.chat_page !== '') {
      window.open(this.LivechatHttpService.chat_page + `?group=${AI_GroupId}&name=${JSON.parse(localStorage.getItem('user_data')).username}&${additionalQuery}`, '_blank');
    }

  }

  getRejectedVerification(emitter: boolean) {
    this.memberBankHttpService.getverificationDetails().subscribe(res => {
      if (res.length > 0) {
        localStorage.setItem('rejected_verification', 'true');
        this.verificationRejected = true;
      } else {
        localStorage.setItem('rejected_verification', 'false');
        this.verificationRejected = false;     
      }
      if (emitter == true) {
        this.eventEmitterService.onVerificationStorageEmitter();
      }
    });
  }

  @HostListener('window:resize', [])
  onResize() {
    if (window.innerHeight < this.windowInnerHeight) {
      $('#footer-menu').css("position", "unset");
    } else {
      $('#footer-menu').css("position", "fixed");
    }
  }
}

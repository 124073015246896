import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-insufficient-funds-modal',
  templateUrl: './insufficient-funds-modal.component.html',
  styleUrls: ['./insufficient-funds-modal.component.scss']
})
export class InsufficientModalModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<InsufficientModalModalComponent>,
    private router: Router,
  ) { }

  ngOnInit() {
    this.dialogRef.updatePosition({ bottom: '0px'});
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onDeposit() {
    this.onCloseDialog();
    this.router.navigate(['/member/']);
  }

}

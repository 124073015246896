import { EventEmitterService } from '@core/services/event-emitter.service';
// Angular
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { LoadingBarService } from '@ngx-loading-bar/core';
// Thirt Party
import { Observable } from 'rxjs';
// Core
import { FavoriteGameHttpService } from '@core/services/favorite-game-http.service';
import { isLoggedIn } from '@core/store/auth/auth.selectors';
import { AppState } from '@store/reducers';


@Component({
  selector: 'app-favorite-game',
  templateUrl: './favorite-game.component.html',
  styleUrls: ['./favorite-game.component.scss']
})
export class FavoriteGameComponent implements OnInit {

  @Input() gameProviderId: number;

  @Input() categoryCode: string;

  @Input() isFavorite: boolean;

  @Input() type: string;

  @Input() miniGameId: number;

  @Input() miniGameName: string;

  @Input() currentPage: string;

  @Input() bigIcon: boolean = false;

  @Output() complete = new EventEmitter<any>();

  isLoggedIn$: Observable<boolean>;

  messages$ = this.favoriteGameHttpService.messages$;

  constructor(
    private store: Store<AppState>,
    public loadingBar: LoadingBarService,
    private favoriteGameHttpService: FavoriteGameHttpService,
    private eventEmitterService: EventEmitterService
  ) { }

  ngOnInit() {
    this.isLoggedIn$ = this.store.pipe(select(isLoggedIn));
  }

  onToggleFavorite(event: Event) {
    this.loadingBar.start();
    if (this.type === 'mini-game') {
      var data = {
        mini_game_id: this.miniGameId,
        mini_game_name: this.miniGameName,
        game_provider_id: this.gameProviderId,
        category_code: this.categoryCode
      }
      this.favoriteGameHttpService.toggleFavoriteMiniGames(data).subscribe(
        res => {
          this.loadingBar.complete();
          this.isFavorite = !this.isFavorite;
          this.complete.emit(true);
        }
      )
    } else {
      var param = {
        game_provider_id: this.gameProviderId,
        category_code: this.categoryCode
      }
      this.favoriteGameHttpService.toggleFavorite(param).subscribe(
        (res) => {
          this.loadingBar.complete();
          this.isFavorite = !this.isFavorite;
          this.eventEmitterService.onUpdateFavorite();
        }
      );
    }
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@core/models/api-response.model';
import { Pagination } from '@core/models/pagination.model';
import { Reward } from '@core/models/reward.model';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { MemberModule } from '../member.module';

@Injectable({
  providedIn: MemberModule
})
export class RewardDataService {

  pagination: Pagination;
  messages$ = new Subject<any[]>();
  isSuccess: boolean;

  constructor(private http: HttpClient) { }

  getAll(): Observable<Reward[]> {
    return this.http.get<ApiResponse>('/reward').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<Reward[]> {
    return this.http.get<ApiResponse>(`/reward${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  claim(reward: { reward_id: number; game_provider_id: number }) {
    return this.http.post<ApiResponse>(`/reward/claim`, reward).pipe(
      tap(res => {
        this.messages$.next(res.message);
        this.isSuccess = res.success;
      }),
      map(res => res)
    );
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CryptoWallet } from '@core/models/crypto-wallet.model';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CryptoWalletHttpService } from '@core/services/crypto-wallet-http.service';

@Component({
  selector: 'app-crypto-wallet-details-modal',
  templateUrl: './crypto-wallet-details-modal.component.html',
  styleUrls: ['./crypto-wallet-details-modal.component.scss']
})
export class CryptoWalletDetailsModalComponent implements OnInit {
  form: FormGroup;
  isSuccess = this.cryptoWalletHttpService.isSuccess;
  messages$ = this.cryptoWalletHttpService.messages$;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { wallet: CryptoWallet },
    public dialogRef: MatDialogRef<CryptoWalletDetailsModalComponent>,
    private loadingBar: LoadingBarService,
    private cryptoWalletHttpService: CryptoWalletHttpService,
  ) { }

  ngOnInit(): void {
    this.formInit(this.data.wallet);
  }

  onCloseDialog(value = false) {
    this.dialogRef.close(value);
  }

  private formInit(cryptoWallet) {
    this.form = new FormGroup({
      token: new FormControl(cryptoWallet.token, [Validators.required]),
      network: new FormControl(cryptoWallet.network, [Validators.required]),
      wallet_address: new FormControl(cryptoWallet.wallet_address, [Validators.required]),
      wallet_nickname: new FormControl(cryptoWallet.wallet_nickname),
    });
  }

  onSave() {
    this.loadingBar.start();
    const data = {
      ...this.form.value
    }
    this.cryptoWalletHttpService.update(this.data.wallet.id ,data).subscribe((res) => {
      this.messages$.next(res.message);
      this.loadingBar.complete();
      this.onCloseDialog(true);
    });
  }
}

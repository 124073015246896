import { DOCUMENT, Location } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivatedRoute, NavigationCancel, NavigationEnd, NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router
} from '@angular/router';
import { PortalMemberProfile } from '@core/models/portal-member-profile.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DashboardService } from '@core/services/dashboard.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { environment } from '@env/environment';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { PortalMemberProfileHttpService } from '@core/services/portal-member-profile-http.service';
import { PortalMessageHttpService } from '@core/services/portal-message-http.service';
import { WebsocketService } from '@core/services/websocket.service';
import { AuthActions } from '@core/store/auth/auth-action.types';
import { isLoggedIn } from '@core/store/auth/auth.selectors';
import { select, Store } from '@ngrx/store';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageModalComponent } from '@shared/language-modal/language-modal.component';
import { LoginModalComponent } from '@shared/login-modal/login-modal.component';
import { SignupModalComponent } from '@shared/signup-modal/signup-modal.component';
import { WalletModalComponent } from '@shared/wallet-modal/wallet-modal.component';
import { AppState } from 'app/store/reducers';
import * as moment from 'moment';
import { Observable, of, Subscription, timer } from 'rxjs';
import { delay, filter, switchMap, tap } from 'rxjs/operators';
import { RewardSwalComponent } from './../../modules/member/dialogs/reward-modal/reward-swal/reward-swal.component';
import { MatSnackBar } from "@angular/material/snack-bar";
import svgIconList from 'assets/icons.json';

declare var $: any;
@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit, AfterViewInit, AfterViewInit, OnDestroy {
  svg: any = svgIconList;

  mode: string = window.mode;
  isLoggedIn$: Observable<boolean>;
  loggedUser$: Observable<PortalMemberProfile>;
  memberUsername$ = this.dashboardService.memberUsername$;
  pageTitle = '';
  topBarTitle = '';
  showTitle = false;
  showTitleList = [
    '/member/profile',
    '/member/change-password',
    '/member/game-password',
    '/member/bank',
    '/member/bank;tab=bank',
    '/member/bank;tab=crypto',
    '/member/message',
    '/member/settings',
    '/member/referrals-history',
    '/member/reward',
    '/member/deposit',
    '/member/transfer',
    '/member/withdraw',
    '/member/history',
    '/member/verification-info',
    '/info-center',
    '/info-center?tab=about_us',
    '/info-center?tab=faq',
    '/info-center?tab=termsconditions',
    '/info-center?tab=privacy_policy',
    '/info-center?tab=responsible_gaming',
  ]

  dateTime: string;
  unreadMessages$ = this.portalMessageHttpService.messageCount$;
  messages$ = this.portalMessageHttpService.messages$;
  hiddenSidePanel = true;
  siteName = environment.sitePrefix.toUpperCase();
  isLanding: boolean;
  isSignup: boolean;
  rewardNotice = Number(localStorage.getItem('reward_notice'));

  showBackButton = false;
  backButtonList = [
    // '/signup',
    '/info-center',
    '/info-center?tab=about_us',
    '/info-center?tab=faq',
    '/info-center?tab=termsconditions',
    '/info-center?tab=privacy_policy',
    '/info-center?tab=responsible_gaming',
  ];

  hideTopIcon = window.location.pathname === '/verify-email' ? true : false;
  showKYCGateKeeper: boolean;
  memberKYC: string;
  memberGroup: string;

  currentDateUTC = moment().utc().format('YYYY-MM-DD HH:mm:ss');
  showTheme = false;

  themes = {
    "Christmas": {
      logo: 'assets/integrations/theme/logo-xmas-2023.gif',
      startDate: '2023-12-20',
      endDate: '2024-01-03',
    },
    "CNY": {
      logo: 'assets/integrations/theme/logo-cny-2024.gif',
      startDate: '2024-02-06',
      endDate: '2024-03-01',
    },
    "Mid Autumn": {
      logo: 'assets/integrations/theme/logo-mid-autumn-2023.gif',
      // model: 'assets/integrations/theme/model-cny.png',
      startDate: '2023-09-18',
      endDate: '2023-10-06',
    },
    "Songkran Festival": {
      logo: 'assets/integrations/theme/logo-songkran-2024.gif',
      startDate: '2024-04-04',
      endDate: '2024-04-18',
    },
    "Dragon Boat Festival": {
      logo: 'assets/integrations/theme/dragonboat-festival-2024.gif',
      startDate: '2024-06-03',
      endDate: '2024-06-17',
    },
    "Mid Autumn 2024": {
      logo: 'assets/integrations/theme/logo-mid-autumn-2024.gif',
      startDate: '2024-09-10',
      endDate: '2024-09-24',
    }
  }
  promotionTopBarText = 'Activated Promotion';
  historySubpages = {
    '/member/history/promotion/': 'Activated Promotion',
    '/member/history/bet/': 'Betting Summary',
    '/member/history/rebate/': 'Rebate & Cashback',
    '/member/history/withdrawal/': 'Withdrawal Details',
  }


  altName = environment.sitePrefix.toUpperCase();
  supportedCountry = this.dropdownService.availableCountryAndLanguages;
  hostname = (window.location.hostname).replace(/^([w]{3}.)(?=[a-z0-9]+)/, '');
  currentDomain = JSON.parse(sessionStorage.getItem('mainDomainBlocked')) ? this.getAltDomain() : environment.domainName;

  newMessage = false;

  onMaintenancePage = false;
  isLaunchGamePage = false;

  downloadAppBanner = ((this.mode != 'Members Only' || this.authService.isUserLoggedIn) && sessionStorage.getItem('download_app_banner')) ? 'show' : null;

  sitePrefix = environment.sitePrefix;
  popup_ids = localStorage.getItem('popup_ids') ?? [];

  private unsubscribe: Subscription[] = [];
  private websocketService: WebsocketService | null = null;

  newMenu = false;

  constructor(
    public dialog: MatDialog,
    private store: Store<AppState>,
    private portalMessageHttpService: PortalMessageHttpService,
    private portalMemberProfile: PortalMemberProfileHttpService,
    private dashboardService: DashboardService,
    public loadingBar: LoadingBarService,
    private eventEmitterService: EventEmitterService,
    private authService: AuthHttpService,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private location: Location,
    public router: Router,
    private translateService: TranslateService,
    private dropdownService: DropdownHttpService,
    private snackBar: MatSnackBar,
    private viewContainerRef: ViewContainerRef,
  ) {
    // No need initialize websocket connection when in '/gamelaunch' page
    if (window.location.pathname !== '/gamelaunch') {
      this.websocketService = new WebsocketService(snackBar);
    }
    else {
      this.isLaunchGamePage = true;
    }

    // Percentage just in case needed!
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.loadingBar.start();
      }
      if (event instanceof RouteConfigLoadStart) {
        this.loadingBar.increment(35);
      }
      if (event instanceof RouteConfigLoadEnd) {
        this.loadingBar.increment(75);
      }
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        this.loadingBar.complete();
      }
    });
  }

  ngOnInit() {
    this.eventEmitterService.memberPromotionDetailTopBarTextEmitter.subscribe(res => {
      this.promotionTopBarText = res;
      this.pageTitle = this.promotionTopBarText;
    });

    this.eventEmitterService.goToDepositStepTwoEmitter.subscribe(({ title }) => {
      this.pageTitle = title;
    });

    setTimeout(() => {
      this.onMaintenancePage = (window.location.pathname) == '/maintenance' ? true : false;
    }, 500)
    this.setAltName();
    this.isLoggedIn$ = this.store.pipe(select(isLoggedIn));

    if (!this.authService.isTokenExpired()) {
      this.loggedUser$ = this.portalMemberProfile.getProfile().pipe(
        tap(user => this.dashboardService.refreshMemberName(user.username))
      );
    }

    setInterval(() => {
      this.dateTime = moment(new Date()).local(true).format('YYYY-MM-DD HH:mm:ss');
    }, 1);


    if (this.eventEmitterService.subsVar === undefined) {
      this.eventEmitterService.subsVar = this.eventEmitterService.reloadMessageEmitter.subscribe((name: any) => {
        this.ngAfterViewInit();
      });
    }

    if (this.eventEmitterService.removeBodyClassVar === undefined) {
      this.eventEmitterService.removeBodyClassVar = this.eventEmitterService.removeBodyClassEmitter.subscribe((isDropDown: any) => {
        this.onHideSideBar(isDropDown);
      });
    }

    this.eventEmitterService.removeBodyClassVar = this.eventEmitterService.checkNewMenuEmitter.subscribe((res: any) => {
      this.newMenu = res;
    });

    this.portalMessageHttpService.messageSubject.subscribe(res => {
      this.newMessage = res.event_type === 'new' ? true : false;
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      this.getUserKYC();
      if (this.router.url === '/member/message') {
        this.newMessage = false;
      }
      this.getRouteTitle(this.activatedRoute).data
        .pipe(tap(data => {
          var url = this.router.url;
          url = url.substr(0, url.lastIndexOf('/') + 1);
          if (url in this.historySubpages) {
            this.pageTitle = this.translateService.instant(this.historySubpages[url]);
          } else {
            this.pageTitle = data.title;
          }
          // if (this.rewardNotice > 0 && data.title !== 'Reward History' && data.title !== 'Live Chat') {
          //   this.onRewardNotice();
          // }
          this.showTitle = this.showTitleList.includes(this.router.url) || (this.router.url).startsWith('/member/history/') ? true : false;
        })).subscribe(() => {
          this.isLanding = this.router.url === '/landing';
          this.isSignup = this.router.url === '/signup';
        });
    });

    Object.keys(this.themes).forEach(key => {
      this.themes[key].started = this.festiveStarted(this.themes[key].startDate);
      this.themes[key].end = this.festiveEnd(this.themes[key].endDate);
      this.themes[key].data = [];
      if (this.themes[key].started && !this.themes[key].end) {
        this.showTheme = true;
      }
    });

    this.isLoggedIn$.subscribe(res => {
      if (res) {
        setTimeout(() => {
          var userdata = JSON.parse(localStorage.getItem('user_data'));
          const memberAccountId = userdata.id;
          // No need to call websocket when in '/gamelaunch' page
          if (window.location.pathname !== '/gamelaunch') {
            this.portalMessageHttpService.getUnread().subscribe(() => {
              this.portalMessageHttpService.messageWebsocket(memberAccountId);
            });

            this.memberWebsocket(memberAccountId);
            this.vipEligibilityWebsocket(memberAccountId);
            this.updateMemberInfoWebsocket(memberAccountId);
          }
        }, 500)
      }
    });
  }

  ngAfterViewInit() {
    this.isLoggedIn$.subscribe(res => {
      if (res) {
        of(null).pipe(
          delay(500), tap(() => this.getUserKYC()
          )).subscribe();
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }

  onBackButtonClick() {
    if (this.router.url.startsWith('/promotion/') && sessionStorage.getItem('fromReferral') == null) {
      this.router.navigateByUrl('/promotion');
    }
    else if (this.router.url.startsWith('/member/profile') && sessionStorage.getItem('fromReferral') == null) {
      this.router.navigateByUrl('/member/account');
    }
    else {
      sessionStorage.removeItem('fromReferral');
      this.location.back();
    }
  }

  onHideSideBar(isDropDown: boolean) {
    this.toggleSideBar(isDropDown);
  }

  onOpenDialog(type: string) {

    switch (type) {
      case 'login':
        this.openDialogBy(LoginModalComponent, 'login');
        break;

      case 'signup':
        this.openDialogBy(SignupModalComponent, 'signup');
        break;

      case 'wallet':
        this.openDialogBy(WalletModalComponent, 'wallet');
        break;

      case 'language':
        this.openDialogBy(LanguageModalComponent, 'language');
        break;
    }

  }

  onLogout() {
    this.store.dispatch(AuthActions.logout());
  }

  toggleSideBar(isDropdown: boolean) {
    if (!isDropdown) {
      $('body').toggleClass("side-panel-open");
      if ($('body').hasClass("side-panel-open")) {
        this.document.body.classList.add('no-scroll');
      } else {
        this.document.body.classList.remove('no-scroll');
      }
    }
  }

  returnZero() {
    return 0;
  }

  private setAltName() {
    let countryCode = null;

    Object.keys(this.supportedCountry[this.currentDomain]).forEach(key => {
      if (this.supportedCountry[this.currentDomain][key][environment.domainEnv].includes(this.hostname)) {
        countryCode = key;
      }
    });

    if (countryCode === 'SG' && (environment.sitePrefix == 'bp9' || environment.sitePrefix == 'bp77')) {
      this.altName = 'Best🕹️📲 Mobile Online Casino🎰 in Singapore | BP77';
    }
  }

  private getUserKYC() {
    const userData = localStorage.getItem('user_data');
    this.showKYCGateKeeper = window.location.pathname === '/vip' || window.location.pathname === '/referral' || window.location.pathname === '/member/referrals-history' ? true : false;
    if (this.showKYCGateKeeper && userData !== null) {
      this.memberKYC = JSON.parse(userData).KYC;
      this.memberGroup = JSON.parse(userData).member_group.name;
    }
  }

  private getRouteTitle(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (((this.backButtonList.includes(this.router.url) || this.router.url.includes('/member')) && this.router.url !== '/member/account' && this.router.url !== '/member/wallet') || this.router.url.includes('/promotion/')) {
      this.showBackButton = true;
    } else {
      this.showBackButton = false;
    }
    this.document.body.classList.remove('side-panel-open');
    if (activatedRoute.firstChild) {
      return this.getRouteTitle(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  private openDialogBy(componentRef: any, type: string) {
    if (type !== 'language') {
      this.dialog.open(componentRef, {
        width: '800px',
      });
    } else {
      this.dialog.open(componentRef);
    }
  }

  private onRewardNotice() {
    this.dialog.open(RewardSwalComponent, {
      width: '800px',
      data: {
        type: 'notice'
      }
    });
  }

  private festiveStarted(startDate: string) {
    return moment(this.currentDateUTC).isSameOrAfter(startDate + ' 00:00:00');
  }

  private festiveEnd(endDate: string) {
    return moment(this.currentDateUTC).isAfter(endDate + ' 23:59:59');
  }

  private memberWebsocket(memberAccountId) {
    this.websocketService
      .getChannel("member-channel." + memberAccountId)
      .listen(`.MemberEvent`, (data) => {
        const cta = data.CTA;

        if (cta) {
          // do not show deposit dialog if pop up dialog available
          if (cta == 'deposit' && this.popup_ids.length > 0) {
            return;
          }

          this.websocketService.ctaNotification({
            ...data,
            buttonConfirm: cta,
            buttonCancel: "No, Thanks",
          });
        } else {
          this.messages$.next([data.title]);
        }
      });
  }

  private vipEligibilityWebsocket(memberAccountId) {
    this.websocketService
      .getChannel("vip-eligibility-channel." + memberAccountId)
      .listen(`.VIPEligibilityEvent`, (data) => {
        const cta = data.CTA;

        if (data.CTA) {
          this.websocketService.ctaNotification({
            ...data,
            buttonConfirm: cta,
            buttonCancel: "No, Thanks",
          });
        } else {
          this.messages$.next([data.title]);
        }
      });
  }

  private updateMemberInfoWebsocket(memberAccountId) {
    this.websocketService
      .getChannel("update-member-info-channel." + memberAccountId)
      .listen(`.UpdateMemberInfoEvent`, (data) => {
        const member = data.member;
        this.portalMemberProfile.updateLocalUserData(member);
        localStorage.setItem('verified_number', member.phone_validated);
      });
  }

  private getAltDomain() {
    // E.g: localhost.com
    // Get localhost.com
    if (this.hostname.split('.').length > 2) {
      this.hostname = this.hostname.substr(this.hostname.indexOf('.') + 1);
    }

    // First Alternative
    if (
      JSON.stringify(this.supportedCountry[environment.altDomainName]).includes('"' + this.hostname + '"') ||
      environment.altDomainName.split('|').includes(this.hostname)
    ) {
      return environment.altDomainName;
    }
    // Second Alternative
    else if (
      JSON.stringify(this.supportedCountry[environment.altDomainNameTwo]).includes('"' + this.hostname + '"') ||
      environment.altDomainNameTwo.split('|').includes(this.hostname)
    ) {
      return environment.altDomainNameTwo;
    }
  }

  onClickBannerBtn(type: string) {
    switch (type) {
      case 'download':
        this.router.navigate(['/home']).then(() => {
          setTimeout(() => {
            document.getElementById('download_app_section').scrollIntoView({ behavior: 'smooth', block: 'start' });
          }, 500);
        });
        break;
      case 'close':
        this.downloadAppBanner = 'hide';
        sessionStorage.setItem('download_app_banner', 'hide');
        break;
    }
  }
}

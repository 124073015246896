<div class="modal show sign-in" aria-labelledby="mySmallModalLabel">
    <div class="modal-dialog-centered">
        <div class="login-modal">
            <div class="modal-bg modal-signin download-app-modal m-auto">
                <div class="modal-header pb-0">
                    <h4 class="modal-title text-center" id="myModalLabel">
                        <ng-container *ngIf="data.platform == 'ios'; else android">
                            {{ '[sitename] App IOS Download and Install Guide' | translate:{sitename: siteName} }}
                        </ng-container>
                        <ng-template #android>
                            {{ '[sitename] App Android Download and Install Guide' | translate:{sitename: siteName} }}
                        </ng-template>
                    </h4>
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseDialog()">×</button>
                </div>
                <div class="modal-body pt-0">
                    <div class="row m-0">
                        <div class="col-1 p-0 d-flex justify-content-center align-items-center">
                            <img src="assets/images/download-app/left-arrow.png" (click)="stepControl(-1)">
                        </div>
                        <div class="col-10 d-flex justify-content-center align-items-center mt-3">
                            <img class="coin-1" src="/assets/images/download-app/coin-1.png" [ngClass]="this.data.platform">
                            <img class="coin-2" src="/assets/images/download-app/coin-2.png" [ngClass]="this.data.platform">
                            <img *ngIf="step == 1" id="white-cursor" src="/assets/images/download-app/white-cursor.png" [ngClass]="this.data.platform" @fadeInOut>
                            <img *ngIf="this.data.platform == 'ios' && step != 1 && step != 5" id="blue-cursor" src="/assets/images/download-app/blue-cursor.png"
                                [@cursorAnimation]="getStep()">
                            <img *ngIf="this.data.platform == 'android' && step == 2" id="blue-cursor" src="/assets/images/download-app/blue-cursor.png" [ngClass]="this.data.platform" @fadeInOut>
    
                            <ng-container *ngFor="let image of loop; let i = index">
                                <img *ngIf="step == i + 1" class="m-auto position-absolute" @fadeInOut
                                    [src]="'/assets/images/download-app/' + this.data.platform + '-flow-' + (i + 1) +'.png'">
                            </ng-container>
                            
                            <!-- For sizing purpose only -->
                            <img class="opacity-0" src="/assets/images/download-app/android-flow-1.png">
                        </div>
                        <div class="col-1 p-0 d-flex justify-content-center align-items-center">
                            <img src="assets/images/download-app/left-arrow.png" (click)="stepControl(1)" class="rotate-180">
                        </div>
                    </div>
                    
                    <div class="row pagination-indicator-container" [ngClass]="this.data.platform">
                        <div class="pagination-indicator" [ngClass]="{'active' : step == 1}"></div>
                        <div class="pagination-indicator" [ngClass]="{'active' : step == 2}"></div>
                        <div class="pagination-indicator" [ngClass]="{'active' : step == 3}"></div>
                        <div *ngIf="data.platform == 'ios'" class="pagination-indicator" [ngClass]="{'active' : step == 4}"></div>
                        <div *ngIf="data.platform == 'ios'" class="pagination-indicator" [ngClass]="{'active' : step == 5}"></div>
                    </div>
    
                    <div class="step-description">
                        <ng-container *ngIf="data.platform == 'ios'">
                            <div *ngIf="step == 1" @fadeInOut>
                                <span>{{ 'Step' | translate }} {{ step }}</span>
                                <p>{{ 'Click \"Apple iOS\" download button from [sitename] site.' | translate:{sitename: siteName} }}</p>
                                <p>{{ 'Click \"DOWNLOAD\" button below to download.' | translate }}</p>
                            </div>
                            <div *ngIf="step == 2" @fadeInOut>
                                <span>{{ 'Step' | translate }} {{ step }}</span>
                                <p>{{ 'A new tab will open up and you will be redirected to an app preview page.' | translate }}</p>
                                <p>{{ 'Click "OPEN" on the app preview page.' | translate }}</p>
                            </div>
                            <div *ngIf="step == 3" @fadeInOut>
                                <span>{{ 'Step' | translate }} {{ step }}</span>
                                <p>{{ 'You will be redirected to a \"Hangout Party\" App on AppStore instead of [sitename] App.' | translate:{sitename: siteName} }}</p>
                                <p>{{ 'Click \"GET\" to install "Hangout Party" App.' | translate }}</p>
                            </div>
                            <div *ngIf="step == 4" @fadeInOut>
                                <span>{{ 'Step' | translate }} {{ step }}</span>
                                <p>{{ 'Open \"Hangout Party\" App then click \"Allow Paste\" to launch the [sitename] App.' | translate:{sitename: siteName} }}</p>
                            </div>
                            <div *ngIf="step == 5" @fadeInOut>
                                <span>{{ 'Step' | translate }} {{ step }}</span>
                                <p>{{ 'You will be redirected to [sitename] App and enjoy your game!' | translate:{sitename: siteName} }}</p>
                                <p>{{ '*Direct search of \"Hangout Party\" App in AppStore will not grant your access to [sitename] App.' | translate:{sitename: siteName} }}</p>
                            </div>
                        </ng-container>
    
                        <ng-container *ngIf="data.platform == 'android'">
                            <div *ngIf="step == 1" @fadeInOut>
                                <span>{{ 'Step' | translate }} {{ step }}</span>
                                <p>{{ 'Click \"Android\" download button from [sitename] site.' | translate:{sitename: siteName} }}</p>
                            </div>
                            <div *ngIf="step == 2" @fadeInOut>
                                <span>{{ 'Step' | translate }} {{ step }}</span>
                                <p>{{ 'Download and install the app.' | translate }}</p>
                            </div>
                            <div *ngIf="step == 3" @fadeInOut>
                                <span>{{ 'Step' | translate }} {{ step }}</span>
                                <p>{{ 'Launch the app and enjoy your game!' | translate }}</p>
                            </div>
                        </ng-container>
                    </div>
    
                    <div class="pt-5">
                        <div class="download-app-modal-btn m-auto" (click)="onDownloadApp()">{{ 'Download' | translate | uppercase }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
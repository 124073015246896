import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse } from '../models/api-response.model';

@Injectable({
  providedIn: 'root'
})
export class GameProviderHttpService {

  isSlotsPage$ = new BehaviorSubject<boolean>(false);
  isDemo$ = new BehaviorSubject<boolean>(true);

  constructor(private http: HttpClient) { }

  launchGame(params: string, isFixedMobile: boolean, categoryCode: string, gameProviderCode: string, lobbyUrl?: string, subCategoryCode?: string): Observable<any[]> {
    const { isDesktop } = JSON.parse((localStorage.getItem('user_browser')));
    let deviceType = isFixedMobile ? 'mobile' : (isDesktop ? 'desktop' : 'mobile');
    if (lobbyUrl) {
      params += `&lobby_url=${lobbyUrl}`
    }
    if (subCategoryCode) {
      params += `&sub_category_code=${subCategoryCode}`;
    }
    return this.http.get<ApiResponse>(`/gameprovider/launch?device_type=${deviceType}&category_code=${categoryCode}&game_provider_code=${gameProviderCode}${params}`).pipe(
      map(res => res.data.rows)
    );
  }

  toggleSlotsDemoStorage() {
    setTimeout(() => {
      if (localStorage.getItem('isDemoSelected') === undefined || localStorage.getItem('isDemoSelected') === null) {
        localStorage.setItem('isDemoSelected', '0');
        this.isDemo$.next(false);
        return;
      }
      this.isDemo$.next(+localStorage.getItem('isDemoSelected') == 1 ? true : false);
    });
  }

  getWeeklyTurnover() {
    return this.http.get<ApiResponse>(`/gameprovider/getweeklyturnover`).pipe(
      map(res => res.data)
    )
  }

}

import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '@env/environment';
import { trigger, state, style, animate, transition, AnimationEvent, keyframes } from '@angular/animations';

@Component({
  selector: 'app-download-app-modal',
  templateUrl: './download-app-modal.component.html',
  styleUrls: ['./download-app-modal.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate('500ms')),
    ]),
    trigger('cursorAnimation', [
      // Cursor's state after animation
      state('step1', style({ opacity: 0, transform: 'translate(calc(50% - 40px), calc(50% + 30px))' })),
      state('step2', style({ opacity: 1, transform: 'translate(calc(50% - 40px), calc(50% + 30px))' })),
      state('step3', style({ opacity: 1, transform: 'translate(calc(50% - 45px), calc(50% - 90px))' })),
      state('step4', style({ opacity: 1, transform: 'translate(calc(50% - 75px), calc(50% + 40px))' })),
      state('step5', style({ opacity: 0, transform: 'translate(calc(50% - 75px), calc(50% + 40px))' })),

      // Animation
      transition('step1 => step2', animate('500ms', keyframes([
        style({ offset: 0, opacity: 0, transform: 'translate(calc(50% - 40px), calc(50% + 30px))' }),
        style({ offset: 1, opacity: 1, transform: 'translate(calc(50% - 40px), calc(50% + 30px))' })
      ]))),
      transition('step2 => step3', animate('500ms', keyframes([
        style({ offset: 0, opacity: 1, transform: 'translate(calc(50% - 40px), calc(50% + 30px))' }),
        style({ offset: 1, opacity: 1, transform: 'translate(calc(50% - 45px), calc(50% - 90px))' })
      ]))),
      transition('step3 => step4', animate('500ms', keyframes([
        style({ offset: 0, opacity: 1, transform: 'translate(calc(50% - 45px), calc(50% - 90px))' }),
        style({ offset: 1, opacity: 1, transform: 'translate(calc(50% - 75px), calc(50% + 40px))' })
      ]))),
      transition('step4 => step5', animate('500ms', keyframes([
        style({ offset: 0, opacity: 1, transform: 'translate(calc(50% - 75px), calc(50% + 40px))' }),
        style({ offset: 0.2, opacity: 0.5, transform: 'translate(calc(50% - 75px), calc(50% + 40px))' }),
        style({ offset: 1, opacity: 0, transform: 'translate(calc(50% - 75px), calc(50% + 40px))' })
      ]))),

      // Reversed Animation
      transition('step5 => step4', animate('500ms', keyframes([
        style({ offset: 0, opacity: 0, transform: 'translate(calc(50% - 75px), calc(50% + 40px))' }),
        style({ offset: 1, opacity: 1, transform: 'translate(calc(50% - 75px), calc(50% + 40px))' })
      ]))),
      transition('step4 => step3', animate('500ms', keyframes([
        style({ offset: 0, opacity: 1, transform: 'translate(calc(50% - 75px), calc(50% + 40px))' }),
        style({ offset: 1, opacity: 1, transform: 'translate(calc(50% - 45px), calc(50% - 90px))' })
      ]))),
      transition('step3 => step2', animate('500ms', keyframes([
        style({ offset: 0, opacity: 1, transform: 'translate(calc(50% - 45px), calc(50% - 90px))' }),
        style({ offset: 1, opacity: 1, transform: 'translate(calc(50% - 40px), calc(50% + 30px))' })
      ]))),
      transition('step2 => step1', animate('500ms', keyframes([
        style({ offset: 0, opacity: 1, transform: 'translate(calc(50% - 40px), calc(50% + 30px))' }),
        style({ offset: 0.2, opacity: 0.5, transform: 'translate(calc(50% - 40px), calc(50% + 30px))' }),
        style({ offset: 1, opacity: 0, transform: 'translate(calc(50% - 40px), calc(50% + 30px))' })
      ])))
    ])
  ]
})
export class DownloadAppModalComponent implements OnInit {
  downloadDomain = JSON.parse(localStorage.getItem('redirect_site_domain')).find(x => x.type == 5);
  downloadLink: any;
  siteName = environment.sitePrefix.toUpperCase();
  region = localStorage.getItem('country_code').toLowerCase();
  step = 1;
  animating = false;

  // iOS = 5 steps; Android = 3 steps
  loop = this.data.platform == 'ios' ? Array(5).fill(0) : Array(3).fill(0);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { platform?: string },
    public dialogRef: MatDialogRef<DownloadAppModalComponent>,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    if (this.data.platform == 'android') {
      this.downloadLink = location.host + '/assets/download/bp9_20240528.apk';
    } else {
      if (this.downloadDomain) {
        const regex = /^(http:\/\/|https:\/\/)/;
        let href = environment.https ? 'https://' : 'http://';
        this.downloadLink = this.downloadDomain['domain'].replace('region=', 'region=' + this.siteName.toLowerCase() + this.region);
  
        if (regex.test(this.downloadLink)) {
          this.downloadLink.replace(regex, href);
        } else {
          this.downloadLink = href + this.downloadLink;
        }
      }
    }

    this.cdr.detectChanges();
  }

  stepControl(step: number) {
    if (this.animating) return;
    this.animating = true;

    this.step += step;
    if (this.step > this.loop.length) {
      this.step = 1;
    } else if (this.step < 1) {
      this.step = this.loop.length;
    }

    this.cdr.detectChanges();

    setTimeout(() => {
      this.animating = false;
    }, 1000);
  }

  getStep() {
    return 'step' + this.step;
  }

  onDownloadApp() {
    if (this.data.platform == 'ios') {
      window.open(this.downloadLink);
    } else {
      let link = document.createElement("a");
      link.download = this.siteName;
      link.href = 'assets/download/bp9_20240528.apk';
      link.click();
    }
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }
}


import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-count-down',
  templateUrl: './count-down.component.html',
  styleUrls: ['./count-down.component.scss']
})
export class CountDownComponent implements OnInit, OnDestroy {

  constructor(
    public translateService: TranslateService
  ) { }

  @Input()
  dateStart: string;

  @Input()
  dateEnd: string;

  @Input()
  contentHash: string;

  @Input()
  display: any;

  @Output()
  complete = new EventEmitter<any>();

  millisecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  secondsInAMinute = 60;

  startDateInSeconds: number;
  endDateInSeconds: number
  currentTimeInSeconds: number;

  public currentTime = new Date();
  public timediff;
  public seconds;
  public minutes;
  public hours;
  public days;
  public timer;

  private subscription: Subscription;


  ngOnInit() {
    this.subscription = interval(1000).subscribe(x => { this.getTimeDifference(); });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private getTimeDifference() {
    this.startDateInSeconds = Date.parse((this.dateStart));
    this.endDateInSeconds = Date.parse((this.dateEnd));
    this.currentTimeInSeconds = new Date().getTime();

    if (this.currentTimeInSeconds < this.startDateInSeconds) {
      this.timediff = this.startDateInSeconds - this.currentTimeInSeconds;
    } else {
      this.timediff = this.endDateInSeconds - this.currentTimeInSeconds;
    }

    this.allocateTimeUnits(this.timediff);
  }

  private allocateTimeUnits(timeDifference) {
    this.seconds = Math.floor((timeDifference) / (this.millisecondsInASecond) % this.secondsInAMinute);
    this.minutes = Math.floor((timeDifference) / (this.millisecondsInASecond * this.minutesInAnHour) % this.secondsInAMinute);
    this.hours = Math.floor((timeDifference) / (this.millisecondsInASecond * this.minutesInAnHour * this.secondsInAMinute) % this.hoursInADay);
    this.days = Math.floor((timeDifference) / (this.millisecondsInASecond * this.minutesInAnHour * this.secondsInAMinute * this.hoursInADay));

    if (this.days <= 0 && this.hours <= 0 && this.minutes <= 0 && this.seconds <= 0) {
      this.subscription.unsubscribe();
      this.complete.emit(true);

    }
  }

  onFormatTimer(num) {
    var digits = num.toString().padStart(2, '0').split('');
    return (digits[0].replace(digits[0], `<span class="digits">${digits[0]}</span>`)) + (digits[1].replace(digits[1], `<span class="digits digits-2">${digits[1]}</span>`));
  }

}

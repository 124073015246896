import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UploadHttpService } from '@core/services/upload-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { catchError, tap } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { BankAccountDataService } from '@views/modules/member/services/bank-account-data.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { FileCompressionPipe } from 'app/pipes/file-compression.pipe';
import svgIconList from 'assets/icons.json';

@Component({
  selector: 'app-ewallet-verification-rejection',
  templateUrl: './ewallet-verification-rejection.component.html',
  styleUrls: ['./ewallet-verification-rejection.component.scss']
})
export class EwalletVerificationRejectionComponent implements OnInit {
  @ViewChild('myFileInput') myFileInput;
  @ViewChild('stepper') stepper;
  svg: any = svgIconList;

  form: FormGroup;

  uploadInProgress: boolean;
  fileName: string;

  uploaded_images = [];
  uploaded_images_form = [];
  max_images = 10;
  disableButton = true;
  messages$ = this.bankAccountDataService.messages$;
  isSuccess = this.bankAccountDataService.isSuccess;
  isCompressed = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      reupload: boolean,
      bankAccountId: number,
      account_name: string,
      account_number: number,
      bank_id: number,
      bank_type: number,
      bank_name: string,
      reason: any
    },
    public dialogRef: MatDialogRef<EwalletVerificationRejectionComponent>,
    private uploadHttpService: UploadHttpService,
    private dropdownHttpService: DropdownHttpService,
    private sanitizer: DomSanitizer,
    private bankAccountDataService: BankAccountDataService,
    public loadingBar: LoadingBarService,
    private fileCompression: FileCompressionPipe,
  ) { }

  ngOnInit() {
    this.formInit();
  }

  onCloseDialog(close?: Boolean) {
    this.dialogRef.close(close);
  }

  async onUploadFileMulti(event: any) {
    this.uploadInProgress = true;
    const file: File = event.target.files[0];

    if (file == null) {
      this.uploadInProgress = false;
      return;
    }
  
     var compressedFile: any;
    //Only image & file size greater than 2MB will need to compress
    if (file.type != "application/pdf" && file.size >= 2 * 1048576) {
      // Compress the file
      this.isCompressed = true;
      compressedFile = await this.fileCompression.transform(file);
    }

    if (compressedFile || this.isCompressed == false) {
      const formData = new FormData();
      this.fileName = compressedFile ? compressedFile.name : file.name;
      formData.append('files', compressedFile ? compressedFile : file);
      formData.append('type', this.dropdownHttpService.uploadTypes[2]);
      
      this.uploadHttpService.upload(formData).pipe(
        tap((res: any) => {
          this.uploadInProgress = false;
          this.disableButton = false;
          this.uploaded_images.push({
            path: res[0].indexOf('.pdf') >= 0 ? this.sanitizer.bypassSecurityTrustResourceUrl(res[0]) : res,
            isPdf: res[0].indexOf('.pdf') >= 0 ? true : false,
          });
          this.uploaded_images_form.push(res[0]);
          this.form.patchValue({
            document_path: this.uploaded_images_form
          });
          this.form.get('document_path').markAsDirty();
          this.form.markAllAsTouched();
        }),
        catchError((error) => {
          this.uploadInProgress = false;
          this.disableButton = true;
          throw error;
        })
      ).subscribe();
    } else {
      this.isCompressed = false;
      this.uploadInProgress = false;
      if (this.uploaded_images_form.length == 0) {
        this.disableButton = true;
      } else {
        this.disableButton = false;
      }
    }
  
    event.target.value = null;
  }

  onRemoveFileMulti(idx) {
    this.uploaded_images.forEach((value,index)=>{
      if(index==idx) this.uploaded_images.splice(index,1);
    });
    this.uploaded_images_form.forEach((value,index)=>{
      if(index==idx) this.uploaded_images_form.splice(index,1);
    });
    this.form.patchValue({
      document_path: this.uploaded_images_form
    });
    if (this.uploaded_images_form.length == 0) {
      this.disableButton = true;
    } else {
      this.disableButton = false;
    }
    this.form.get('document_path').markAsDirty();
    this.form.markAllAsTouched();
  }

  onSave() {
    this.loadingBar.start()
      const data = {
        ...this.form.value,
      };
      this.bankAccountDataService.reupload(this.data.bankAccountId, data).pipe(
        tap(res => {
          this.loadingBar.complete();
          this.onCloseDialog(true);
        }),
        catchError(err => {
          this.loadingBar.complete();
          throw err;
        })
      ).subscribe();
  }

  private formInit() {
    this.form = new FormGroup({
      document_path: new FormControl(null, Validators.required),
    });
  }

}

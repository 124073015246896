// Angular
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthHttpService } from '@core/services/auth-http.service';

@Injectable({
  providedIn: 'root'
})
export class CleanModeGuard implements CanActivate {

  mode: string = window.mode;

  constructor(private router: Router, private authHttpService: AuthHttpService) { }

  canActivate(): boolean {
    if (this.mode == 'Members Only') {
      if (localStorage.getItem('user_token')) {
        return true;
      } else {
        if (window.location.pathname != '/') {
          this.router.navigateByUrl('/login');
        } else {
          this.router.navigateByUrl('/signup');
        }
      }
    } else {
      return true;
    }
  }
}

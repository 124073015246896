import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@core/models/api-response.model';
import { Subject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DepositLimit } from '@core/models/deposit-limit.model';
import { TwoStepDepositDataService } from '@views/modules/member/services/two-step-deposit-data.service';

@Injectable({
  providedIn: 'root'
})
export class PortalDepositHttpService {

  messages$ = new Subject<any[]>();
  isSuccess: boolean;

  constructor(
    private http: HttpClient,
    private twoStepDepositDataService: TwoStepDepositDataService,
  ) {}

  add(memberBankAccount: any) {
    return this.http.post<ApiResponse>(`/deposit`, memberBankAccount).pipe(
      tap(res => {
        this.isSuccess = res.success;

        const twoStepPGs = this.twoStepDepositDataService.getTwoStepDepositPGs();

        // skip showing success message for two step PGs
        if (this.isSuccess && twoStepPGs.includes(memberBankAccount.payment_gateway_code)) {
          return;
        }

        this.messages$.next(res.message);
      }),
      map(res => res)
    );
  }

  getLimit(pageParam: string): Observable<DepositLimit>{
    return this.http.get<ApiResponse>(`/deposit/limit${pageParam}`).pipe(
      map(res => res.data.rows)
    );
  }
  
  getCryptoInfo(pageParam: any) {
    return this.http.get<ApiResponse>(`/deposit/crypto${pageParam}`).pipe(
      map(res => res)
    );
  }

  cancelDeposit(depositId: number) {
    return this.http.put<ApiResponse>(`/deposit/reject/${depositId}`, {}).pipe(
      map(res => res)
    );
  }

  changeDepositToExpired(depositId: number) {
    return this.http.put<ApiResponse>(`/deposit/expired/${depositId}`, {}).pipe(
      map(res => res)
    );
  }
}

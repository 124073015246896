import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { EventEmitterService } from '@core/services/event-emitter.service';

@Component({
  selector: 'app-restore-info',
  templateUrl: './restore-info.component.html',
  styleUrls: ['./restore-info.component.scss']
})
export class RestoreInfoComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<RestoreInfoComponent>,
    private eventEmitterService: EventEmitterService
  ) { }

  ngOnInit(): void {
  }

  onCloseDialog(emit: boolean) {
    emit ? this.eventEmitterService.restoreEmitter.emit() : '';
    this.dialogRef.close();
  }

  onDoNotShowAgain(e: Event) {
    const checked = (e.target as HTMLInputElement).checked;
    sessionStorage.setItem('doNotShowRestoreInfo', JSON.stringify(checked));
    this.eventEmitterService.restoreInfoEmitter.emit(checked);
  }

}

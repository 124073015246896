import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PortalMemberProfileHttpService } from '@core/services/portal-member-profile-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import svgIconList from 'assets/icons.json';

@Component({
  selector: 'app-portal-change-password',
  templateUrl: './portal-change-password.component.html',
  styleUrls: ['./portal-change-password.component.scss']
})

export class PortalChangePasswordComponent implements OnInit {
  svg: any = svgIconList;
  passwordForm: FormGroup;
  messages$ = this.portalMemberProfileHttpService.messages$;
  isSuccess = false;
  passwordInput = {
    type: 'password',
    icon: this.svg.hidePasswordIcon
  };
  checkValidity = false;
  passwordMatched = true;

  confirmPasswordInput = {
    type: 'password',
    icon: this.svg.hidePasswordIcon
  };

  userData = JSON.parse(localStorage.getItem('user_data'));

  constructor(
    public dialog: MatDialog,
    private portalMemberProfileHttpService: PortalMemberProfileHttpService,
    public loadingBar: LoadingBarService,
  ) { }

  ngOnInit() {
    this.passwordFormInit();
  }

  onPasswordMask(type?: string) {
    if (type === 'password') {
      this.passwordInput.type = this.passwordInput.type === 'password' ? 'text' : 'password';
      this.passwordInput.icon = this.passwordInput.icon === this.svg.hidePasswordIcon ?this.svg.viewPasswordIcon : this.svg.hidePasswordIcon;
    } else {
      this.confirmPasswordInput.type = this.confirmPasswordInput.type === 'password' ? 'text' : 'password';
      this.confirmPasswordInput.icon = this.confirmPasswordInput.icon === this.svg.hidePasswordIcon ?this.svg.viewPasswordIcon : this.svg.hidePasswordIcon;
    }
  }

  onCheckPassword() {
    this.passwordMatched = true;
    if (this.passwordForm.value.password_confirmation !== null && this.passwordForm.value.password !== null) {
      if (this.passwordForm.value.password !== this.passwordForm.value.password_confirmation) {
        this.passwordMatched = false;
      }

      if (this.passwordForm.value.password.length === 0 || this.passwordForm.value.password_confirmation.length === 0) {
        this.passwordMatched = true;
      }
    }
  }

  onSave() {
    this.checkValidity = true;
    if (this.passwordForm.valid && this.passwordMatched) {
      const data = {
        ...this.passwordForm.value
      };
      Object.keys(data).forEach((key) => (data[key] == null || data[key] === ''));
      this.portalMemberProfileHttpService.updatePassword(data).subscribe(() => {
        this.isSuccess = this.portalMemberProfileHttpService.isSuccess;
        this.passwordFormInit();
        this.userData.change_password = 0;
        localStorage.setItem('user_data', JSON.stringify(this.userData));
      });
      this.checkValidity = false;
    }
  }

  onConfirmPassword() {
  }

  private passwordFormInit() {
    this.passwordForm = new FormGroup({
      password: new FormControl(null, Validators.compose([Validators.required, Validators.minLength(6)])),
      password_confirmation: new FormControl(null, [Validators.required]),
    });
  }

  onConfirm() {
    this.passwordFormInit();
  }
}

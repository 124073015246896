import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-promotion-tooltip-modal',
  templateUrl: './promotion-tooltip-modal.component.html',
  styleUrls: ['./promotion-tooltip-modal.component.scss']
})
export class PromotionTooltipDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PromotionTooltipDialogComponent>,
    private eventEmitterService: EventEmitterService,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    document.getElementById("note").innerHTML=this.translateService.instant("Promotion(s) available for this game provider, for more info please visit our promotions page <a href='promotion'> here </a>")
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

  onDoNotShowAgain(e: Event){
    const checked = (e.target as HTMLInputElement).checked;
    sessionStorage.setItem('doNotShowPromoToolTip', JSON.stringify(checked));
    this.eventEmitterService.promotionTooltipEmitter.emit(checked);
  }

}

import { Component, OnInit } from '@angular/core';
import { PortalMemberProfileHttpService } from '@core/services/portal-member-profile-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import svgIconList from 'assets/icons.json';
import { EventEmitterService } from '@core/services/event-emitter.service';


@Component({
  selector: 'app-portal-navigation',
  templateUrl: './portal-navigation.component.html',
  styleUrls: ['./portal-navigation.component.scss']
})

export class PortalNavigationComponent implements OnInit {
  svg: any = svgIconList;
  member_KYC = null;
  userData = JSON.parse(localStorage.getItem('user_data'));
  verificationRejected = localStorage.getItem('rejected_verification');

  constructor(
    private portalMemberProfileHttpService: PortalMemberProfileHttpService,
    private laodingBar: LoadingBarService,
    private eventEmitterService: EventEmitterService,
) { }

  ngOnInit() {
    this.eventEmitterService.updateVerificationStorageEmitter.subscribe(() => {
      this.verificationRejected = localStorage.getItem('rejected_verification'); 
    });
    this.laodingBar.start();
    this.portalMemberProfileHttpService.getProfile().subscribe(res => {
      this.userData = { ...this.userData, KYC: res.KYC };
      localStorage.setItem('user_data', JSON.stringify(this.userData));
      this.member_KYC = res.KYC;
      this.laodingBar.complete();
    });
  }
}

import { DropdownHttpService } from './../../services/dropdown-http.service';
import { Injectable } from '@angular/core';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { AuthActions } from './auth-action.types';
import { FingerprintHttpService } from '@core/services/fingerprint-http.service';
import { environment } from '@env/environment';
import { AuthHttpService } from '@core/services/auth-http.service';
import { MatDialog } from '@angular/material/dialog';
import { SiteRouteComponent } from '@shared/site-route/site-route.component';
import { WalletHttpService } from '@core/services/wallet-http.service';
import { AuthData } from '@core/models/auth-data';
import { LivechatHttpService } from '@core/services/livechat-http.service';
import { Crisp } from "crisp-sdk-web";
import { RequestReceiptHttpService } from '@core/services/request-receipt-http.service';
import { AnnouncementHttpService } from '@core/services/announcement-http.service';
import { GameCategoryHttpService } from '@core/services/game-category-http.service';
import { Router } from '@angular/router';
import { EwalletVerificationHttpService } from '@core/services/ewallet-verification-http.service';
@Injectable({
  providedIn: 'root'
})
export class AuthEffects {

  hostname = (window.location.hostname).replace(/^([w]{3}.)(?=[a-z0-9]+)/, '');
  supportedCountry = this.dropdownService.availableCountryAndLanguages;
  currentDomain: string;
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      tap(async action => {
        // Set user information to local storage
        localStorage.setItem('user_data', JSON.stringify(action.user));
        localStorage.setItem('user_token', JSON.stringify(action.token));
        localStorage.removeItem('announcements_pop');

        // Update status
        this.auth.isUserLoggedIn = true;

        // Get current domain
        if (this.hostname.split('.').length > 2) {
          this.hostname = this.hostname.substr(this.hostname.indexOf('.') + 1);
        }
        // First Alternative 
        if (
          JSON.stringify(this.supportedCountry[environment.altDomainName]).includes('"' + this.hostname + '"') ||
          environment.altDomainName.split('|').includes(this.hostname)
        ) {
          this.currentDomain = environment.altDomainName;
        }
        // Second Alternative
        else if (
          JSON.stringify(this.supportedCountry[environment.altDomainNameTwo]).includes('"' + this.hostname + '"') ||
          environment.altDomainNameTwo.split('|').includes(this.hostname)
        ) {
          this.currentDomain = environment.altDomainNameTwo;
        }
        // Default
        else {
          this.currentDomain = environment.domainName;
        }

        // Set country code to local storage
        localStorage.setItem('country_code', action.user.country_code);

        // Set language code to local storage
        const languages = this.supportedCountry[this.currentDomain][localStorage.getItem('country_code')]['languages'];
        if (!languages.includes(action.user.lang_code)) {
          localStorage.setItem('language_code', languages[0])
          this.translateService.use((languages[0]).toLocaleLowerCase());
        } else {
          localStorage.setItem('language_code', action.user.lang_code);
          this.translateService.use((action.user.lang_code).toLocaleLowerCase());
        }

        // Call verify API
        this.fingerprintHttpService.verify(action.user);

        // whitelist data is not needed for gamelaunch redirect flow, add condition to prevent too many request error trigger by this api
        // const landing_page_domain = JSON.parse(localStorage.getItem('redirect_site_domain')).find(x => x.type == 2)['domain'];
        if (!window.location.href.includes('gamelaunch?redirectDomain')) {
          // Get white list id
          let whitelistId = await this.setWhitelist(action.user);
        }

        //trigger update country and language emitter for banner/marquee/popup/contact/livechat
        this.eventEmitterService.onUpdateCountryAndLanguageEmitter();
        this.eventEmitterService.onUpdatePromotionListEmitter();

        // TEMPORARY DISABLE REDIRECT ACTION UNTIL FURTHER NOTICE
        // // whitelist id > 0 will perform redirect action
        // if (action.user.currency.code == 'MYR' && !environment.forceMaintenance && whitelistId > 0 && environment.sitePrefix == 'bp77') {
        //   this.whitelistAnnouncement(whitelistId);
        // }
        // // Else continue to check member reward notice
        // else {
        // Trigger set promotion event emitter
        this.eventEmitterService.onSetPromotion();
        // }

        if (window.location.pathname !== '/gamelaunch') {
          if (window.location.pathname == '/login') {
            this.router.navigate(['/']);
          } else {
            this.walletHttpService.getMemberBalance().subscribe();
            this.gameCategoryHttpService.getGameCategoryListWithPromo().subscribe();
          }
        }

        // Listen to RequestReceiptEvent websocket
        this.requestReceiptHttpService.init(action.user.id);

        // Listen to UpdateRewardPromptEvent websocket
        this.announcementHttpService.init();
        this.ewalletVerificationHttpService.init(action.user.id);
      }
      )),
    { dispatch: false });

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      tap(() => {
        // Note: Don't clear sessionStorage!
        localStorage.removeItem('user_token');
        localStorage.removeItem('user_data');
        localStorage.removeItem('profile');
        localStorage.removeItem('os-player-id');
        localStorage.removeItem('bank_maintenance_hour');
        localStorage.removeItem('language_code');
        localStorage.removeItem('country_code');
        localStorage.removeItem('rejected_verification');
        localStorage.removeItem('dialog');
        localStorage.removeItem('popup_ids');
        localStorage.removeItem('main_domain_url');
        sessionStorage.removeItem('fishingSession');
        sessionStorage.removeItem('slotSession');
        sessionStorage.removeItem('crashGameSession');
        sessionStorage.removeItem('tableSession');
        sessionStorage.removeItem('game_providers_balance');
        sessionStorage.removeItem('all_bank');
        sessionStorage.removeItem('member_bank_accounts');
        sessionStorage.removeItem('member_merchant_bank');
        sessionStorage.removeItem('lotterySession');
        sessionStorage.removeItem('promotionSession');
        sessionStorage.removeItem('casinoSession');

        // Update status
        this.auth.isUserLoggedIn = false;

        // Note: Native redirect to destroy browser!
        // if(+JSON.parse(localStorage.getItem('redirectToLogin')) === 1) {
        //   sessionStorage.setItem('openLogin', this.router.url)
        // }
        // window.location.href = '/';

        // Clear Crisp live chat data
        if (this.LivechathttpService.live_chat_platform_name == 'Crisp') {
          Crisp.session.reset();
        }
      })
    ),
    { dispatch: false });

  constructor(
    private actions$: Actions,
    private eventEmitterService: EventEmitterService,
    private translateService: TranslateService,
    private fingerprintHttpService: FingerprintHttpService,
    private dropdownService: DropdownHttpService,
    private auth: AuthHttpService,
    private walletHttpService: WalletHttpService,
    public dialog: MatDialog,
    private LivechathttpService: LivechatHttpService,
    private requestReceiptHttpService: RequestReceiptHttpService,
    private announcementHttpService: AnnouncementHttpService,
    private gameCategoryHttpService: GameCategoryHttpService,
    private router: Router,
    private ewalletVerificationHttpService: EwalletVerificationHttpService,
  ) { }

  private setWhitelist(memberAccount: AuthData) {
    return new Promise<number>((resolve, reject) => {
      this.auth.getWhitelist(memberAccount.id).subscribe(res => {
        localStorage.setItem('whitelist_id', res.whitelist_id);
        // Whitelist data only for BP77
        if ((environment.sitePrefix as string).toUpperCase() == 'BP77') {
          localStorage.setItem('whitelist_data', JSON.stringify(this.compileWhitelistData(memberAccount, res.whitelist_id)));
        }
        resolve(res.whitelist_id);
      });
    })
  }

  private whitelistAnnouncement(whitelistId: number) {
    if (whitelistId && whitelistId > 0 && environment.domainEnv != 'staging') {
      const dialogExist = this.dialog.getDialogById('modal-site-route');
      const sitePrefix = environment.sitePrefix.toUpperCase();
      const hideDialog = localStorage.getItem('hide_save_link_dialog');
      const opendialog = sitePrefix != 'BP9' || (sitePrefix == 'BP9' && hideDialog != '1');
      if (!dialogExist && opendialog) {
        this.dialog.open(SiteRouteComponent, {
          width: '600px',
          height: '400px',
          disableClose: true,
        });
      }
    }
  }

  private compileWhitelistData(memberAccount: AuthData, whitelistId: Number) {
    let message = {
      playerId: memberAccount.id,
      username: memberAccount.username,
      countryCode: memberAccount.country_code,
      whitelistGroup: whitelistId,
      domain: window.location.hostname
    };
    return message;
  }

}

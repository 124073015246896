import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@core/models/api-response.model';
import { Pagination } from '@core/models/pagination.model';
import { PromotionContent } from '@core/models/promotion-content.model';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PromotionModule } from '../promotion.module';

@Injectable({
  providedIn: 'root'
})
export class PromotionContentHttpService {

  messages$ = new Subject<any[]>();
  isSuccess: boolean;
  pagination: Pagination;
  constructor(private http: HttpClient) { }

  getAll(): Observable<PromotionContent[]> {
    return this.http.get<ApiResponse>('/promotion?locale_id=1').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<PromotionContent[]> {
    return this.http.get<ApiResponse>(`/promotion${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getPromotionCategory() {
    return this.http.get<ApiResponse>(`/promotion/category?status=1`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  promoApply(data: any) {
    return this.http.post<ApiResponse>(`/promotion/apply`, data).pipe(
      tap(res => {
        this.messages$.next(res.message);
        this.isSuccess = res.success;
      }),
      map(res => res)
    );
  }

  getActivePromotionList(): Observable<any> {
    return this.http.get<ApiResponse>(`/promotion/activepromotion`).pipe(
      map(res => res.data.rows)
    );
  }

  getBonusWinAmount(memberPromotionID: number): Observable<any> {
    return this.http.get<ApiResponse>(`/promotion/bonuswinamount?member_promotion_id=${memberPromotionID}`).pipe(
      map(res => res.data)
    );
  }

  selfCancelPromotion(data: any) {
    return this.http.post<ApiResponse>(`/promotion/selfcancelpromotion`, data).pipe(
      tap(res => {
        this.messages$.next(res.message);
        this.isSuccess = res.success;
      }),
      map(res => res)
    );
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }
}

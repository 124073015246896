import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DateFilterService } from '@core/services/date-filter.service';
import { TranslateService } from '@ngx-translate/core';
import { Lightbox } from 'ngx-lightbox';
import { HistoryDataService } from '../../services/history-data.service';
import { PromotionContentHttpService } from '@views/modules/promotion/services/promotion-content-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { GameCategoryHttpService } from '@core/services/game-category-http.service';
import { PromotionCancelModalComponent } from '../../dialogs/promotion-cancel-modal/promotion-cancel-modal.component';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { LoadingBarService } from '@ngx-loading-bar/core';
import svgIconList from 'assets/icons.json';
@Component({
  selector: 'app-history-details',
  templateUrl: './history-details.component.html',
  styleUrls: ['./history-details.component.scss']
})
export class HistoryDetailsComponent implements OnInit, AfterViewInit {
  svg: any = svgIconList;

  historyId: string;
  type: string;
  details: any;

  activePromotionList = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private historyDataService: HistoryDataService,
    private dateFilterService: DateFilterService,
    private lightbox: Lightbox,
    private translateService: TranslateService,
    private promotionContentHttpService: PromotionContentHttpService,
    private eventEmitterService: EventEmitterService,
    private gameCategoryHttpService: GameCategoryHttpService,
    public dialog: MatDialog,
    private loadingBar: LoadingBarService,
  ) { }

  async ngOnInit() {
    this.historyId = this.activatedRoute.snapshot.paramMap.get('id');
    this.type = this.activatedRoute.snapshot.paramMap.get('type');
    if (this.type == 'promotion') {
      this.activePromotionList = await new Promise(resolve => {
        this.promotionContentHttpService.getActivePromotionList().subscribe(res => {
          resolve(res);
        });
      });
    }
  }

  ngAfterViewInit() {
    switch (this.type) {
      case 'promotion':
        this.historyDataService.getPromotionHistory(`?id=${this.historyId}`).subscribe(res => {
          this.details = res;
          this.eventEmitterService.onChangeMemberPromotionTopBarText(this.details[0].promotion_name);
        });
        break;
      case 'bet':
        const start_date = this.dateFilterService.getLastMonthWithTime().from,
          end_date = this.dateFilterService.getLastMonthWithTime().to;
        this.historyDataService.getBetHistory(`?start_date_time=${start_date}&end_date_time=${end_date}&id=${this.historyId}`).subscribe(res => {
          this.details = res;
        });
        break;
      case 'rebate':
        this.historyDataService.getRebateHistory(`?id=${this.historyId}`).subscribe(res => {
          this.details = res;
        });
        break;
      case 'withdrawal':
        this.historyDataService.getHistoryWithQuery(`?transaction_type=withdrawal&id=${this.historyId}`).subscribe(res => {
          this.details = res[0];
        });
        break;
    }
  }

  openReceipt(path: any) {
    if (path) {
      const receipt = [{
        src: path,
        caption: this.translateService.instant('Receipt'),
        thumb: path
      }];
      this.lightbox.open(receipt, 0, { centerVertically: true, disableScrolling: true, fitImageInViewPort: true, fadeDuration: 0.01, resizeDuration: 0.01 });
    }
  }

  checkCancelable() {
    const item = this.details[0];
    if (item.allow_cancel) {
      if (this.activePromotionList.length > 0) {
        const data = this.activePromotionList.find(x => x.promotion_id === item.promotion_id);
        if (data) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  onCancelDialog() {
    const item = this.details[0];
    const memberPromotion = this.activePromotionList.find(x => x.promotion_id === item.promotion_id && x.game_provider_id == item.game_provider_id);
    const total_promo_amount = +memberPromotion.amount + +memberPromotion.bonus_amount;
    if (+memberPromotion.game_wallet_balance > total_promo_amount && +memberPromotion.accumulated_target_amount == 0) {
      Swal.fire({
        html: '<div class="msg-icon">' + this.svg.dangerIcon + '</div>' +
              '<div class="text-center m-t-20">' +
              '<ul><li><b>' + this.translateService.instant('ACTION TEMPORARY UNAVAILABLE') + '</b></li></ul>' +
              '<ul><li class="mt-4">' + this.translateService.instant('The turnover is currently being updated and will be available shortly.') + '</li></ul>' +
              '<ul><li class="mt-4 rejected-text small">' + this.translateService.instant('Please try again later or contact customer service if you have any urgent concerns.') + '</li></ul>' +
              '</div>',
        confirmButtonText: this.translateService.instant('OK'),
      });
    } else {
      if (item.limit_transfer_in == 0) {
        Swal.fire({
          html: '<div class="msg-icon">' + this.svg.dangerIcon + '</div>' +
            '<div class="text-center m-t-20">' +
            '<ul><li>' + this.translateService.instant('This action may result in a forfeiture of your transferred funds.') + '</li></ul>' +
            '<ul><li class="mt-4">' + this.translateService.instant('Are you sure you want to cancel this promotion?') + '</li></ul>' +
            '</div>',
          confirmButtonText: this.translateService.instant('YES'),
          showDenyButton: true,
          showConfirmButton: true,
          denyButtonText: this.translateService.instant('NO'),
          reverseButtons: true,
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {
            this.cancelPromotionDialog(memberPromotion);
          }
        });
      } else {
        this.cancelPromotionDialog(memberPromotion);
      }
    }
  }

  cancelPromotionDialog(memberPromotion: any) {
    this.loadingBar.start();
    this.promotionContentHttpService.getBonusWinAmount(memberPromotion.id).subscribe(res => {
      this.gameCategoryHttpService.getProviderBalanceBy(memberPromotion.game_provider_code).subscribe(gameres => {
        this.loadingBar.complete();
        const dialogRef = this.dialog.open(PromotionCancelModalComponent, {
          width: '800px',
          data: {
            detail: memberPromotion,
            amount: res,
            gameWalletBalance: gameres,
            activePromotionList: this.activePromotionList.filter(x => x.promotion_id == memberPromotion.promotion_id)
          }
        });
  
        dialogRef.afterClosed().subscribe(async (result) => {
          if(result === true) {
            this.activePromotionList = [];
            this.activePromotionList = await new Promise(resolve => {
              this.promotionContentHttpService.getActivePromotionList().subscribe(res => {
                resolve(res);
              });
            });
          }
        });
      });
    });
  }

}

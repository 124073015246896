import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiResponse } from './../models/api-response.model';

@Injectable({
  providedIn: 'root'
})
export class EmailVerificationHttpService {

  messages$ = new Subject<any[]>();

  constructor(private http: HttpClient) { }

  verify(token: any) {
    return this.http.post<ApiResponse>(`/otp/email/verify`, token).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

}

import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthHttpService } from '@core/services/auth-http.service';
import { ForgotPasswordHttpService } from '@core/services/forgot-password-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { LoginModalComponent } from '@shared/login-modal/login-modal.component';
import { of } from 'rxjs';
import { ForgotUsernameModalComponent } from '@shared/forgot-username-modal/forgot-username-modal.component';
import { catchError, delay, tap } from 'rxjs/operators';
import svgIconList from 'assets/icons.json';

//Recaptcha
import { ReCaptchaV3Service } from 'ng-recaptcha';
// Third Party
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forgot-password-modal',
  templateUrl: './forgot-password-modal.component.html',
  styleUrls: ['./forgot-password-modal.component.scss']
})
export class ForgotPasswordModalComponent implements OnInit {
  svg: any = svgIconList;

  @ViewChildren('focusInput') focusInput: QueryList<ElementRef>;
  mode: string = window.mode;
  form: FormGroup;

  messages$ = this.forgotPasswordHttpService.messages$;
  isSuccess = this.forgotPasswordHttpService.isSuccess;
  checkValidity = false;
  recaptchScore = 0;
  selectedIndex = 0;
  options = {
    0: 'email',
    1: 'sms'
  };

  usernameFormatValid = {
    consist: false,
    special: false
  };

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ForgotPasswordModalComponent>,
    public dialog: MatDialog,
    public loadingBar: LoadingBarService,
    private auth: AuthHttpService,
    public forgotPasswordHttpService: ForgotPasswordHttpService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) { }

  ngOnInit(): void {
    this.initializeForm();
    of(null).pipe(
      delay(200), tap(() => this.focusInput.first.nativeElement.focus()
      )).subscribe();
  }

  submit() {
    this.checkValidity = true;
    if (this.form.valid) {
      try {
        this.recaptchaV3Service.execute('ForgotPassword').pipe(
          tap((token: any) => {
            this.forgotPasswordHttpService.recaptcha({ response: token, username: this.form.value.username }).pipe(
              tap(() => {
                this.loadingBar.start();
                const data = {
                  ...this.form.value,
                  option: this.options[this.selectedIndex]
                };
                this.forgotPasswordHttpService.forgotPassword(data).subscribe(() => {
                  this.isSuccess = this.forgotPasswordHttpService.isSuccess;
                  this.loadingBar.complete();
                  this.onCloseDialog();
                  if (this.mode != 'Members Only') {
                    this.openDialogBy(LoginModalComponent);
                  }
                });
              })
            ).subscribe();
          }),
          catchError((error) => {
            var message = "Failed captcha";
            Swal.fire({
              html: '<div class="msg-icon">' + this.svg.dangerIcon + '</div>' +
                '<div class="text-center m-t-20">' +
                message +
                '</div>'
            });
            throw error;
          })
        ).subscribe();
      } catch (e) { }
    }
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.auth.forceLowerCaseInputControl(this.form, controlName, event);
  }

  numberOnly(event: KeyboardEvent) {
    return this.auth.numberOnly(event);
  }

  onOpenDialog() {
    this.openDialogBy(ForgotUsernameModalComponent);
  }

  private openDialogBy(componentRef: any) {
    this.dialog.open(componentRef);
  }

  private initializeForm() {
    this.form = this.fb.group({
      username: [
        '',
        [Validators.required, Validators.minLength(6), Validators.maxLength(16)]
      ]
    });
  }

}

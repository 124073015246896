import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class MaintenanceGuard implements CanActivate {
  constructor(
    private router: Router,) {

  }
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    const maintenance = localStorage.getItem('maintenance');
    if (environment.forceMaintenance || maintenance !== null) {
      return this.router.navigateByUrl('/maintenance');
    }
    return true;
  }
}
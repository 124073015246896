// Model
import { Pagination } from '@core/models/pagination.model';
import { ApiResponse } from '@core/models/api-response.model';
// RxJS
import { map, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SeoSettingsHttpService {

  pagination: Pagination;
  messages$ = new Subject<any[]>();
  isSuccess: boolean;

  constructor(private http: HttpClient) { }

  getSettings(params: string): Observable<any> {
    return this.http.get<ApiResponse>(`/seo/getCMSSettings?${params}`).pipe(
      map(res => res.data.rows)
    );
  }
}

// Angular
import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
// Service
import { ForgotPasswordHttpService } from '@core/services/forgot-password-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { TranslateService } from '@ngx-translate/core';
// Third Party
import { of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import svgIconList from 'assets/icons.json';

@Component({
  selector: 'app-forgot-username-modal',
  templateUrl: './forgot-username-modal.component.html',
  styleUrls: ['./forgot-username-modal.component.scss']
})
export class ForgotUsernameModalComponent implements OnInit {
  svg: any = svgIconList;

  @ViewChildren('focusInput') focusInput: QueryList<ElementRef>;
  form: FormGroup;

  messages$ = this.forgotPasswordHttpService.messages$;
  isSuccess = this.forgotPasswordHttpService.isSuccess;
  checkValidity = false;

  constructor(
    private fb: FormBuilder,
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<ForgotUsernameModalComponent>,
    public forgotPasswordHttpService: ForgotPasswordHttpService,
    public loadingBar: LoadingBarService,
  ) { }

  ngOnInit() {
    this.initializeForm();
    of(null).pipe(
      delay(200), tap(() => this.focusInput.first.nativeElement.focus()
      )).subscribe();
  }

  onSubmit() {
    this.checkValidity = true;
    const data = {
      ...this.form.value
    }
    if (this.form.valid) {
      this.forgotPasswordHttpService.forgotUsername(data).pipe(
        tap(() => {
          this.isSuccess = this.forgotPasswordHttpService.isSuccess;
          this.loadingBar.complete();
          this.checkValidity = false;
          if (this.isSuccess) {
            Swal.fire({
              html: '<div class="msg-icon-email">' + this.svg.emailIcon + '</div>' +
                '<div class="text-center mt-3">' +
                '<ul><li><h3 class="verification-title">'
                + this.translateService.instant('EMAIL SENT') +
                '</h3></li><li>'
                + this.translateService.instant('Your username has been sent to') +
                '</li><li class="email-text">' + this.form.value.email + '</li><br>' +
                '</div>',
              confirmButtonText: 'OK',
            }).then(result => {
              if (result) {
                this.form.reset();
                this.onCloseDialog();
              }
            })
          }
        })
      ).subscribe();
    }
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  private initializeForm() {
    this.form = this.fb.group({
      email: new FormControl(null, [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')])
    })
  }

}

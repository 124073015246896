import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dropdown } from '@core/models/dropdown.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse } from '../models/api-response.model';

@Injectable({
  providedIn: 'root'
})
export class BankHttpService {

  constructor(private http: HttpClient) { }

  getAllBanks(): Observable<Dropdown[]>{
    return this.http.get<ApiResponse>(`/bankaccount/list`).pipe(
      map(res => res.data)
    );
  }

  getPaymenyMethods(): Observable<Dropdown[]>{
    return this.http.get<ApiResponse>(`/paymentmethod?type=1&status=1`).pipe(
      map(res => res.data.rows)
    );
  }

  getPaymentGatewayBank(paymentGatewayCode: string): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>(`/banks?payment_gateway_code=${paymentGatewayCode}`).pipe(
      map(res => res.data)
    );
  }

  getPaymentGatewayList(paymentMethodCode: any): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>(`/paymentgateway?payment_method_code=${paymentMethodCode}&status=1`).pipe(
      map(res => res.data.rows)
    );
  }
}

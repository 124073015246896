import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Banner } from '@core/models/banner.model';
import { BannerHttpService } from '@core/services/banner-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { environment } from '@env/environment';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { GameCategoryHttpService } from '@core/services/game-category-http.service';
import { isLoggedIn } from '@core/store/auth/auth.selectors';
import { select, Store } from '@ngrx/store';
import { CountDownDetailsComponent } from '@shared/count-down/dialog/count-down-details/count-down-details.component';
import { LoginModalComponent } from '@shared/login-modal/login-modal.component';
import { AppState } from '@store/reducers';
import { Observable, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SwiperOptions } from 'swiper';
import svgIconList from 'assets/icons.json';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {
  svg: any = svgIconList;

  config: SwiperOptions = {
    pagination: { el: '.swiper-pagination', clickable: true },
    allowTouchMove: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    loop: true
  };

  banners$: Observable<Banner[]>;
  isLoggedIn$: Observable<boolean> = this.store.pipe(select(isLoggedIn));
  isLoggedIn: boolean;

  visible = false;
  banners = [];
  bannerCount = this.banners.length;
  bannerPosition = 0;

  announcements$ = JSON.parse(localStorage.getItem('marquees'));
  affiliateDomain = this.dropdownHttpService.affiliateDomain;

  constructor(
    private store: Store<AppState>,
    private bannerHttpService: BannerHttpService,
    private eventEmitterService: EventEmitterService,
    private router: Router,
    private dropdownHttpService: DropdownHttpService,
    private gameCategoryHttpService: GameCategoryHttpService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {

    this.getBanner();
    this.eventEmitterService.updateCountryAndLanguageEmitter.subscribe(() => {
      this.getBanner();
    });

    this.isLoggedIn$.subscribe(res => {
      this.isLoggedIn = res
    })

    this.eventEmitterService.marqueesEmitterVar = this.eventEmitterService.marqueesEmitter.subscribe(() => {
      this.announcements$ = JSON.parse(localStorage.getItem('marquees'));
    });
  }

  private getBanner() {
    this.banners$ = this.bannerHttpService.getAllBanner().pipe(
      map(banners => {
        if (this.isLoggedIn) {
          return banners.filter(banner => banner.images[0].image_mobile !== null && (banner.session == 1 || banner.session == 3));
        } else {
          return banners.filter(banner => banner.images[0].image_mobile !== null && (banner.session == 1 || banner.session == 2));
        }
      })
    )

    this.banners$.subscribe(res => {
      this.banners = [];
      this.banners = res
    });
  }

  getOffset(el: any) {
    el = el.getBoundingClientRect();
    return {
      left: el.left + window.scrollX,
      top: el.top + window.scrollY,
      bottom: el.top + window.scrollY
    };
  }

  scroll() {
    const scrollTo = document.getElementById('content');
    const topHight = this.getOffset(scrollTo).top;
    window.scrollTo(0, topHight);
  }

  onNavigate(link: string) {
    if (link !== (null && '')) {
      this.router.navigateByUrl(link);
      if (link.includes('external')) {
        this.router.navigate([]).then(() => { window.open(link, '_blank'); });
      }
      else {
        this.router.navigateByUrl(link);
      }
    }
  }

  navToAffLink(link: string) {
    if (link !== (null && '')) {
      if ((environment.sitePrefix as string).toLowerCase() != 'bp9' && (link.indexOf('http://') !== -1 || link.indexOf('https://') !== -1)) {
        //for http & https link redirect
        window.location.href = link;
      } else {
        //for /aff affiliate link shortcut
        let currentCountryCode = localStorage.getItem('country_code'), affCountryObj = this.affiliateDomain[currentCountryCode];
        window.location.href = (environment.https ? 'https://' : 'http://') + affCountryObj.mobilePreExt[environment.domainEnv] + affCountryObj[environment.domainEnv][0] + `?lang=${currentCountryCode}_${localStorage.getItem('language_code')}`;
      }
    }
  }

  onLaunchGame(data: string) {
    // E.g data : Launch|EZ||481000, Launch|EZ|roulette|481000
    let datas = data.split('|');
    this.gameCategoryHttpService.getWithQuery('?category_code=LC').pipe(
      map(res => res.find(x => x.game_provider_code === datas[1]))
    ).subscribe((gameProvider: any) => {
      let dateEnd = Date.parse(gameProvider.maintain_end), dateNow = new Date().getTime();

      if (localStorage.getItem('user_data') === null) {
        this.dialog.open(LoginModalComponent);
      }
      else if (gameProvider.maintenance === 1 && dateEnd > dateNow) {
        this.dialog.open(CountDownDetailsComponent, {
          width: '400px',
          data: {
            dateEnd: gameProvider.maintain_end,
            name: gameProvider.game_provider_name,
            time: gameProvider.maintain_time_left,
          }
        });
      }
      //maintenance with no end date
      else if (gameProvider.maintenance === 1 && gameProvider.maintain_end === '') {
        this.dialog.open(CountDownDetailsComponent, {
          width: '400px',
          data: {
            name: gameProvider.game_provider_name,
            time: gameProvider.maintain_time_left,
          }
        });
      }
      else {
        localStorage.setItem('gameProviderCode', gameProvider.game_provider_code);
        localStorage.setItem('gameCategory', 'LC');
        localStorage.setItem('isPlayTechClicked', (gameProvider.game_provider_code === "PT").toString());
        localStorage.setItem('gameProviderId', gameProvider.game_provider_id);
        localStorage.setItem('lobbyUrl', location.href);
        datas[2] !== undefined ? localStorage.setItem('subCategoryCode', datas[2]) : localStorage.removeItem('subCategoryCode');
        datas[3] !== undefined ? localStorage.setItem('gameCode', datas[3]) : localStorage.removeItem('gameCode');
        this.router.navigate([]).then(() => { window.open('/gamelaunch', '_blank'); });
      }
    });
  }
}

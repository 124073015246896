import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { TwoStepDepositDataService } from '@views/modules/member/services/two-step-deposit-data.service';
import { TwoStepDepositService } from '@views/modules/member/services/two-step-deposit.service';

@Injectable({
  providedIn: 'root',
})
export class DepositDeactivateGuard implements CanDeactivate<any> {
  constructor(
    private twoStepDepositService: TwoStepDepositService,
    private twoStepDepositDataService: TwoStepDepositDataService
) {}

  async canDeactivate(component: any): Promise<boolean> {
    const lsStepTwoDeposit =
      this.twoStepDepositDataService.getLSStepTwoDeposit();

    if (lsStepTwoDeposit) {
        return this.twoStepDepositService.handleLeavingStepTwoPage(lsStepTwoDeposit);
    }

    // user didn't have any pending callback deposit
    return true;
  }
}

<div class="modal-dialog modal-dialog-centered modal show">
  <div class="modal-body">
    <div class="modal-content modal-bg modal-bg-wallet">
      <div class="modal-header">
        <h4 class="modal-title">{{ 'Claim' | translate}} {{ 'Reward' | translate }} {{ data.reward.name ? ' - ' + data.reward.name : '' }}</h4>
        <button type="button" class="close" (click)="onCloseDialog()">&times;</button>
      </div>
      <hr>
      <div class="claim-modal">
        <form [formGroup]="form" (ngSubmit)="submit()" id="claim-reward-modal-form" name="claim-reward-modal-form">
          <ng-container *ngIf="data.reward.promo_type === 3; else transferTypePromo">
            <div class="claim-amount-field row m-l-0 m-r-0 m-b-10">
              <p>{{ 'Claim Amount' | translate }}</p>
              <input class="form-control form-control-sm form-input-style" type="text" placeholder="{{ 'Enter Amount' | translate }}" [value]="data.reward.amount" digitOnly decimal="true" decimalSeparator="." pattern="^\d+(\.\d{1,2})?$" disabled="">
            </div>
            <div class="claim-amount-field row m-l-0 m-r-0 m-b-10">
              <p>{{ 'Transfer To' | translate }}</p>
              <p class="p-l-10" [ngClass]="{'input-invalid' : checkValidity && form.controls.game_provider_id.errors}">
                <select formControlName="game_provider_id" [ngClass]="{'form-invalid dropdown-invalid' : checkValidity && form.controls.game_provider_id.errors}" (change)="onProviderChange($event)">
                  <option [value]="null" disabled>{{ 'Please Select' | translate }}</option>
                  <ng-container *ngFor="let item of providersDropdown">
                    <option [value]="item.id" [disabled]="item.status === 2">{{ item.name}}</option>
                  </ng-container>
                </select>
              </p>
            </div>
            <div class="m-t-10" *ngIf="showIDRRate | async">
                <p class="text-blue">
                    {{ 'Exchange Rate' | translate }} <br> IDR 1:0.2 {{ providerName | uppercase }} {{ 'Credits' | translate }}
                </p>
            </div>
          </ng-container>

          <ng-template #transferTypePromo>
            <div class="claim-amount-field row m-l-0 m-r-0 m-b-10">
              <p>{{ 'Transfer From' | translate }}</p>
              <p class="p-l-10">
                <select formControlName="transfer_from" [ngClass]="{'form-invalid dropdown-invalid' : checkValidity && form.controls.transfer_from.errors}">
                  <option [value]="null" disabled>{{ 'Please Select' | translate }}</option>
                  <ng-container *ngFor="let item of providerList">
                    <option [value]="item.id" [disabled]="item.status === 2">{{ item.name}}</option>
                  </ng-container>
                </select>
              </p>
            </div>
            <div class="claim-amount-field row m-l-0 m-r-0 m-b-10">
              <p>{{ 'Transfer To' | translate }}</p>
              <p class="p-l-10">
                <select formControlName="transfer_to" [ngClass]="{'form-invalid dropdown-invalid' : checkValidity && form.controls.transfer_to.errors}" (change)="onSelectTransferTo($event)">
                  <option [value]="null" disabled>{{ 'Please Select' | translate }}</option>
                  <ng-container *ngFor="let item of providersDropdown">
                    <option [value]="item.id" [disabled]="item.status === 2">{{ item.name}}</option>
                  </ng-container>
                </select>
              </p>
            </div>
            <div class="claim-amount-field row m-l-0 m-r-0 m-b-10">
              <p>{{ 'Transfer Amount' | translate }}</p>
              <input class="form-control form-control-sm form-input-style" formControlName="amount" type="number" placeholder="{{ 'Enter Amount' | translate }}">
            </div>
            <ng-container *ngIf="form.value.transfer_to !== null">
              <p class="text-blue exchange-font m-b-0 mt-1">
                {{ 'You are entitled to promotion(s) for this game provider' | translate }}
              </p>
              <p class="text-blue exchange-font">
                {{ (data.reward.target_type == 2 || data.reward.target_type == 4 ? 'Winover Requirement' : 'Turnover Requirement') | translate }}: <br>
                {{ data.reward.currency_code }} {{ onMultiplicand(+form.value.amount, data.reward) }} x {{ data.reward.target_type == 2 ? data.reward.target_multiplier+1 : data.reward.target_multiplier }} = {{ data.reward.currency_code + ' ' + (onTotalTurnOver(form.value.amount, data.reward) | number: '1.2-2') }}
              </p>
              <p class="text-blue exchange-font">
                  {{ 'Bonus' | translate }}: {{ data.reward.currency_code + ' ' + onBonusAmount(+form.value.amount, data.reward) }}
              </p>
            </ng-container>
          </ng-template>

          <button type="submit" class="btn-blue-gra m-t-0 m-b-20">{{ 'Submit' | translate | uppercase }}</button>
        </form>
      </div>
    </div>
  </div>
</div>
import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthData } from '@core/models/auth-data';
import { DashboardService } from '@core/services/dashboard.service';
import { PortalMemberProfileHttpService } from '@core/services/portal-member-profile-http.service';
import { changeProfile } from '@core/store/auth/auth.actions';
import { loggedUser } from '@core/store/auth/auth.selectors';
import { select, Store } from '@ngrx/store';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from '@store/reducers';
import * as moment from 'moment';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { forkJoin, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { WithdrawVerificationModalComponent } from '../../dialogs/withdraw-verification-modal/withdraw-verification-modal.component';
import { ContactListHttpService } from '@core/services/contact-list-http.service';
import { environment } from '@env/environment';
import svgIconList from 'assets/icons.json';
declare var $: any;

@Component({
  selector: 'app-portal-profile',
  templateUrl: './portal-profile.component.html',
  styleUrls: ['./portal-profile.component.scss']
})

export class PortalProfileComponent implements OnInit, AfterViewInit {
  svg: any = svgIconList;
  @ViewChild(OwlDateTimeInputDirective) datePicker: OwlDateTimeInputDirective<any>;

  form: FormGroup;
  max = new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate());
  messages$ = this.portalMemberProfileHttpService.messages$;
  isSuccess = false;
  userName: string;
  checkValidity = false;
  userData: any;

  currentUser: AuthData;
  countryCode = localStorage.getItem('country_code');
  redirectLink: string;
  sitePrefix = environment.sitePrefix;

  private subscription = new Subscription();
  private datePickerSubscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<PortalProfileComponent>,
    public dialog: MatDialog,
    private portalMemberProfileHttpService: PortalMemberProfileHttpService,
    private datePipe: DatePipe,
    public loadingBar: LoadingBarService,
    private dashboardService: DashboardService,
    private store: Store<AppState>,
    private router: Router,
    private translateService: TranslateService,
    private contactListHttpService: ContactListHttpService,
  ) { }

  ngOnInit() {
    this.fetchData();
    this.profileFormInit();
    this.store.pipe(select(loggedUser)).subscribe((res: AuthData) => this.currentUser = res);
    this.verificationMessage();

    $(document).on('click', "#resendEmail", function () {
      $(document).find('#verifyEmailButton').click();
    });

    this.contactListHttpService.getContactListByPlatform('2', '1').subscribe(data => {
      if (data[0] !== undefined && data[0]['locales'][0] != undefined) {
        this.redirectLink = data[0]['locales'][0]['link'] ?? null;
      }
    });
  }

  ngAfterViewInit() {
    this.datePickerSubscription = forkJoin([
      this.buildDatePicker(0, 'date_of_birth'),
    ]).subscribe();
  }

  fetchData() {
    this.loadingBar.start();
    this.portalMemberProfileHttpService.getProfile()
      .subscribe(data => {
        this.userData = data;
        this.userName = data.name;
        this.form.patchValue({
          name: data.name, email: data.email, mobile: data.mobile
        });

        if ((data.name).length > 0) {
          this.form.controls[`name`].disable();
        }

        if (+data['email_status'] === 1) {
          this.form.controls[`email`].disable();
        }

        if (+data['mobile_status'] === 1) {
          this.form.controls[`mobile`].disable();
        }

        if (this.isValidDate(data.date_of_birth)) {
          this.form.patchValue({ date_of_birth: data.date_of_birth });
          this.form.controls[`date_of_birth`].disable();
        }

        this.loadingBar.complete();
      });
  }

  onSaveProfile() {
    this.checkValidity = true;
    var data = {
      ...this.form.value,
      name: this.userName ? this.userName : this.form.value.name,
    };
    if (this.form.value.email && this.form.value.email != null) {
      data['email'] = this.form.value.email.toLowerCase();
    }
    this.isSuccess = true;
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '' || data[key] === '0000-00-00') && delete data[key]);
    this.portalMemberProfileHttpService
      .updateProfile(data)
      .pipe(
        tap(res => {
          this.isSuccess = this.portalMemberProfileHttpService.isSuccess;
          this.store.dispatch(changeProfile({ profile: res }));
          this.form.controls[`name`].disable();
        })
      )
      .subscribe((res) => {
        this.dashboardService.refreshMemberName(res.username);
      });
    this.checkValidity = false;
  }

  onVerifyNumber() {
    // if( this.countryCode.toUpperCase() == 'ID' ) {
    //   if( this.redirectLink !== undefined && this.redirectLink !== null ) {
    //     window.open(this.redirectLink, "_blank");
    //   } else {
    //     Swal.fire({
    //       html: '<div class="msg-icon">' + this.svg.dangerIcon + '</div>' +
    //         '<div class="text-center m-t-20">' +
    //         this.translateService.instant('Please contact our Live Chat team for further assistance.') +
    //         '</div>'
    //     });
    //   }
    // } else {
    this.openDialogBy(WithdrawVerificationModalComponent, JSON.parse(localStorage.getItem('user_data')).otp_status, this.form.value.mobile);
    // }
  }

  requestEmail() {
    this.loadingBar.start();
    this.portalMemberProfileHttpService.requestEmail(this.form.value.email.toLowerCase()).subscribe(() => {
      this.loadingBar.complete();
      Swal.fire({
        html: '<div class="msg-icon-email">' + this.svg.emailIcon + '</div>' +
          '<div class="text-center mt-3">' +
          '<ul><li><h3 class="verification-title">'
          + this.translateService.instant('VERIFICATION EMAIL SENT') +
          '</h3></li><li>'
          + this.translateService.instant('Verification email has been sent to') +
          '</li><li class="email-text">' + this.form.value.email.toLowerCase() + '</li><br><li>'
          + this.translateService.instant("Didn't recieve our email?") +
          '</li><a class="resend-text" id="resendEmail">' + this.translateService.instant('Resend Verification Email') + '</a></ul>' +
          '</div>',
        confirmButtonText: 'OK',
      });
    });
  }

  isValidDate(date: Date | string) {
    if (moment(date).isValid()) {
      return true;
    }
    return false;
  }

  private verificationMessage() {
    const result = JSON.parse(localStorage.getItem('verify_email_message'));
    if (result !== null) {
      const svgIcon = result.success ? '<div class="msg-icon-greentick">' + this.svg.greenTickIcon + '</div>' : '<div class="msg-icon">' + this.svg.dangerIcon + '</div>';
      Swal.fire({
        html: svgIcon +
          '<div class="text-center mt-3"><ul><li><h3 class="verification-title">'
          + (result.success ? this.translateService.instant('EMAIL VERIFICATION SUCCESSFUL') : this.translateService.instant('EMAIL VERIFICATION FAILED')) +
          '</h3></li><li>'
          + result.message[0] +
          '</li></ul></div>',
        confirmButtonText: 'OK',
      });
      localStorage.removeItem('verify_email_message');
    }
  }

  private openDialogBy(componentRef: any, can_request_otp?: boolean, new_mobile_number?: string) {
    this.dialog.open(componentRef, {
      width: '800px',
      data: {
        can_request_otp: can_request_otp,
        new_mobile_number: new_mobile_number
      }
    });
  }

  private buildDatePicker(index: number, formKey: string) {
    return this.datePicker.valueChange.pipe(
      map(res => this.datePipe.transform(res, 'yyyy-MM-dd')),
      tap(date => {
        this.form.patchValue({ [formKey]: date });
      }),
    );
  }

  private profileFormInit() {
    this.form = new FormGroup({
      name: new FormControl(null),
      // email: new FormControl(null, [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]),
      email: new FormControl(null, [Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i)]),
      mobile: new FormControl(null, [Validators.minLength(5)]),
      date_of_birth: new FormControl(null, [this.dateValidation()])
    });
  }

  formatDOB() {
    var dob = this.form.get('date_of_birth').value?.replace(/[^0-9]+/g, "");
    var newDOB = '';
    for (var i = 0; i < dob?.length; i++) {
      if (i === 4 || i === 6) {
        newDOB += '-';
      }
      newDOB += dob[i];
    }
    this.form.patchValue({
      date_of_birth: newDOB
    })
  }

  dateValidation(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const validDate = moment(control.value).isValid() && +moment(control.value).year() <= (+moment().year() - 18);
      return validDate ? null : { invalidDate: control.value }
    }
  }
}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isLoggedIn } from '@core/store/auth/auth.selectors';
import { select, Store } from '@ngrx/store';
import { AppState } from '@store/reducers';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-slot-fishing-category-modal',
  templateUrl: './slot-fishing-category-modal.component.html',
  styleUrls: ['./slot-fishing-category-modal.component.scss']
})
export class SlotFishingCategoryModalComponent implements OnInit, OnDestroy {

  providers = JSON.parse(localStorage.getItem('available_providers'));
  isLoggedIn$: Observable<boolean>;
  newCategory: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { activeCategory: any, providerCode: string },
    private store: Store<AppState>,
    public dialogRef: MatDialogRef<SlotFishingCategoryModalComponent>
  ) { }

  ngOnInit() {
    this.isLoggedIn$ = this.store.pipe(select(isLoggedIn));

    // Filter out suspended providers
    Object.keys(this.providers).forEach((key) => {
      this.providers[key] = this.providers[key].filter(provider => provider.suspend != 1)
    });
  }

  ngOnDestroy() {
    this.newCategorySelected();
  }

  newCategorySelected() {
    if (this.newCategory) {
      this.dialogRef.close(this.newCategory);
    }
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  toJSON(content: string) {
    if (content == undefined || content == null) {
      return {}
    } else {
      return JSON.parse(content);
    }
  }

  onSelectCategory(category: any) {
    this.newCategory = category;
    this.onCloseDialog();
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginModalComponent } from '@shared/login-modal/login-modal.component';

@Component({
  selector: 'app-demo-notice',
  templateUrl: './demo-notice.component.html',
  styleUrls: ['./demo-notice.component.scss']
})
export class DemoNoticeComponent implements OnInit {

  isShowAgain = false;

  constructor(
    public dialogRef: MatDialogRef<LoginModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      onSwitch: boolean
    },
  ) { }

  ngOnInit() {
  }

  onConfirm(isShowAgain = false) {
    this.dialogRef.close();
  }

  onNotShowAgain() {
    localStorage.setItem('isShowDemoNotice', '0');
    this.onConfirm();
  }

}

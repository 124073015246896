import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class DynamicFooterService {
  
  totalGameBalance$ = new Subject<number>();
  memberBalance$ = new Subject<number>();
  memberUsername$ = new Subject<string>();

  reloadProviderEmmiter = new EventEmitter();
  subsVar: Subscription;

  constructor(
    private http: HttpClient
  ) { }

  getFooter(): Observable<any[]> {
    return this.http.get<ApiResponse>(`/footer`).pipe(
      map(res => {
        return res.data.rows;
      }),
    );
  }
}

<!-- Change country site notice -->
<div class="bg-secondary top-notice p-b-10" *ngIf="showChangeCountryNotice && !showReminder">
  <div class="row">
      <div class="col-12">
        <!-- Note: No need translation per Request -->
        <p class="text-center">{{ 'Change to [country] site to see content specific to your location' | translate: {country: ISOCountryCode[countryCodeToChange] | translate} }}</p>
      </div>
      <div class="col-3 offset-3 p-r-5">
          <button class="changecountry-btn-white" (click)="onChangeCountryStatus()">{{ 'No, Thanks' | translate }}</button>
      </div>
      <div class="col-3 p-l-5">
        <button class="changecountry-btn-blue" (click)="onChangeCountrySite()">{{ 'Yes' | translate }}</button>
      </div>
  </div>
</div>
<!-- BP9 reminder -->
<div class="bg-secondary top-notice p-b-10" *ngIf="showReminder && !shutdownflag">
  <div class="row">
      <div class="col-12">
        <p class="text-center reminder-text" [innerHTML]="'Welcome to BP9! Save our new link <b>[link]</b> for your ease of access in the future.' | translate: {link: newLink}"></p>
      </div>
      <div class="row col-12 justify-content-center p-0 m-0">
        <button type="button" class="btn btn-link" (click)="onDoNotShowAgain()">{{ 'Do not show again' | translate}}.</button>
      </div>
  </div>
</div>
<!-- Main -->
<ng-container *ngIf="!isDesktopDetected && !hideAppBase; else elseTemplate">
  <app-pre-loader *ngIf="(loadingBar.progress$|async) > 0"></app-pre-loader>
  <app-topbar *ngIf="show()" [ngClass]="(showChangeCountryNotice || showReminder) ? 'p-t-80' : 'p-t-0'"></app-topbar>
  <app-base-inner [ngClass]="(showChangeCountryNotice || showReminder) && !show() ? 'p-t-80' : 'p-t-0'">
    <router-outlet (activate)="onActivate()"></router-outlet>
  </app-base-inner>
  <img *ngIf="(mode != 'Members Only' || isUserLoggedIn()) && isMember && luckySpinDomain !== '' && !onMaintenancePage" src="../assets/images/lucky-spin-wheel.gif" class="lucky-spin-img" (click)="onLuckySpin()">
  <img *ngIf="( (page == 'home' && !pageTitle) || pageTitle == 'Home' ) && countryCode == 'ID' && (siteName == 'BP77'|| siteName == 'BP9') && !onMaintenancePage" src="../assets/images/rtp-floating-icon-animated.gif" [class]="checkLivechat() == 'liveChat' ? 'rtp-img-with-livechat' : checkLivechat() == 'sbChat' ? 'rtp-img-with-sbchat' : 'rtp-img'"  (click)="onRTP()">
</ng-container>
<ng-template #elseTemplate>
  <router-outlet (activate)="onActivate()"></router-outlet>
</ng-template>
<app-request-receipt *ngIf="isUserLoggedIn()"></app-request-receipt>
<app-floating-reward *ngIf="isUserLoggedIn()"></app-floating-reward>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GameSubCategory } from '@core/models/games/game-sub-category.model';
import { Pagination } from '@core/models/pagination.model';
import { Observable } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';
import { ApiResponse } from '../models/api-response.model';
import { GameList } from '../models/games/game-list.model';


@Injectable({
  providedIn: 'root'
})
export class GameListHttpService {

  pagination: Pagination;

  constructor(private http: HttpClient) { }

  getGamesBy(providerCode: string, categoryCode: string, page?: number, subCategory?: string, gameName?: string, gameSortBy?: string): Observable<GameList[]> {
    if (providerCode && categoryCode) {
      let pageParam = `?status=1&game_provider_code=${providerCode}&category_code=${categoryCode}`;
      if (subCategory) {
        pageParam += `&sub_category_name=${subCategory}`;
      }
      if (gameName) {
        pageParam += `&game_name=${gameName}`;
      }
      if (gameSortBy) {
        pageParam += `&sort=${gameSortBy}`;
      }
      if (page) {
        pageParam += `&page=${page}`;
      } else {
        pageParam += '&paginate=0';
      }
      return this.http.get<ApiResponse>(`/gameprovider/getallgamelist${pageParam}`).pipe(
        tap(res => this.paginationInit(res)),
        map(res => {
          if (res.data && res.data.rows !== null) {
            return res.data.rows;
          }
          if(res.data == null){
            return [];
          }
        })
      );
    }
  }

  getGameImages(providerCode: string, categoryCode: string): Observable<any[]> {
    if (providerCode && categoryCode) {
      let pageParam = `?paginate=0&game_provider_code=${providerCode}&game_category_code=${categoryCode}`;
      
      return this.http.get<ApiResponse>(`/gameimages${pageParam}`).pipe(
        tap(res => this.paginationInit(res)),
        map(res => {
          if (res.data && res.data.rows !== null) {
            return res.data.rows;
          }
        })
      );
    }
  }
  
  getSubCategories(providerCode: string, categoryCode: string): Observable<GameSubCategory[]> {
    if (providerCode && categoryCode) {
      const pageParam = `?status=1&game_provider_code=${providerCode}&category_code=${categoryCode}`;
      return this.http.get<ApiResponse>(`/gameprovider/subcategory${pageParam}`).pipe(
        share(),
        map(res => res.data.rows)
      );
    }
  }

  getSelfSubCategories(providerCode: string, categoryCode: string): Observable<GameSubCategory[]> {
    if (providerCode && categoryCode) {
      const pageParam = `?status=1&game_provider_code=${providerCode}&category_code=${categoryCode}`;
      return this.http.get<ApiResponse>(`/gameprovider/selfsubcategory${pageParam}`).pipe(
        share(),
        map(res => res.data.rows)
      );
    }
  }

  private paginationInit(res: any) {
    if (res) {
      if (res.data !== null && res.data.paginations) {
        this.pagination = res.data.paginations;
      }
    }
  }

}

// Angular
import { Injectable } from '@angular/core';
// RxJS
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  // Use on wallet component 
  providerBalancesLoadingIndicator: number = 0;
  providerBalancesLoadingBehaviourSubject: BehaviorSubject<number> = new BehaviorSubject<number>(this.providerBalancesLoadingIndicator);

  // Member Wallet Balance
  memberBalanceLoadingIndicator: number = 0;
  memberBalanceLoadingBehaviourSubject: BehaviorSubject<number> = new BehaviorSubject<number>(this.memberBalanceLoadingIndicator);

  constructor() { }

  // Use on wallet component
  updateBalancesLoadingIndicator(type: 'increase' | 'decrease') {
    if (type == 'decrease' && this.providerBalancesLoadingIndicator > 0) {
      this.providerBalancesLoadingIndicator--;
    } else {
      this.providerBalancesLoadingIndicator++;
    }
    this.providerBalancesLoadingBehaviourSubject.next(this.providerBalancesLoadingIndicator);
  }

  updateMemberBalanceLoadingIndicator(type: 'increase' | 'decrease') {
    if (type == 'decrease' && this.memberBalanceLoadingIndicator > 0) {
      this.memberBalanceLoadingIndicator--;
    } else {
      this.memberBalanceLoadingIndicator++;
    }
    this.memberBalanceLoadingBehaviourSubject.next(this.memberBalanceLoadingIndicator);
  }

}

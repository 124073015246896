import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import svgIconList from 'assets/icons.json';

@Component({
  selector: 'app-ewallet-verification-approval',
  templateUrl: './ewallet-verification-approval.component.html',
  styleUrls: ['./ewallet-verification-approval.component.scss']
})
export class EwalletVerificationApprovalComponent implements OnInit {
  svg: any = svgIconList;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      bank_name: string
      account_number: string,
      reason: string,
    },
    public dialogRef: MatDialogRef<EwalletVerificationApprovalComponent>,
  ) { }

  ngOnInit() {
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventEmitterService } from '@core/services/event-emitter.service';

@Component({
  selector: 'app-more-search',
  templateUrl: './more-search.component.html',
  styleUrls: ['./more-search.component.scss']
})
export class MoreSearchComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { page: string },
    private eventEmitterService: EventEmitterService
  ) { }

  ngOnInit(): void {}

  onDismiss(){
    this.eventEmitterService.onDismissMoreSort(this.data.page);
  }

}

<section id="content">
  <app-wallet-bar *ngIf="(isLoggedIn$ | async) && !excludeWalletBarList.includes(route.url)"></app-wallet-bar>

  <div class="container clearfix">
    <div class="row">
      <div class="col-12" [ngClass]="{'p-0': onHistoryPage()}">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</section>
<app-footer-wrapper></app-footer-wrapper>

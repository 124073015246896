import { Location } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PortalMessage } from '@core/models/portal-message.model';
import { DashboardService } from '@core/services/dashboard.service';
import { PortalMessageHttpService } from '@core/services/portal-message-http.service';
import { Observable, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'app-message-details',
  templateUrl: './message-details.component.html',
  styleUrls: ['./message-details.component.scss']
})
export class MessageDetailsComponent implements OnInit, AfterViewInit {

  messageId: number[];
  message$: Observable<PortalMessage>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private messageHttpService: PortalMessageHttpService,
    private location: Location,
    private dashboardService: DashboardService
  ) { }

  ngOnInit() {
    this.messageId = [+this.activatedRoute.snapshot.paramMap.get('id')];
  }

  ngAfterViewInit() {
    this.message$ = this.messageHttpService.updateRead({
      type: 1,
      read: 1,
      message_id: this.messageId
    }).pipe(
      concatMap(res => {
        if(res.success) {
          return this.messageHttpService.getContents(this.messageId[0])
        }
        of(null);
      }),
    )
  }

  onBack() {
    this.location.back()
  }

}

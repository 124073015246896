<!-- Modal -->
<div class="modal show" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-body">
			<div class="modal-content modal-bg-wallet">
				<div class="modal-header pb-0">
					<h4 class="modal-title" id="myModalLabel"><span class="mr-2" [innerHTML]="svg.transferIcon | safeHtml"></span> {{ 'Transfer' | translate}} </h4>
					<button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseDialog()">×</button>
				</div>
				<div class="modal-body">
          			<app-transfer-edit-modal [isPerProvider]="true"></app-transfer-edit-modal>
        		</div>
			</div>
		</div>
	</div>
</div>


import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RewardsStatus } from '@core/enums/rewards-status.enum';
import { Pagination } from '@core/models/pagination.model';
import { DashboardService } from '@core/services/dashboard.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';
import { RewardDataService } from '../../services/reward-data.service';
import { ClaimRewardModalComponent } from './../../dialogs/reward-modal/claim-reward-modal.component';
import { EventEmitterService } from '@core/services/event-emitter.service';

@Component({
  selector: 'app-portal-reward',
  templateUrl: './portal-reward.component.html',
  styleUrls: ['./portal-reward.component.scss']
})
export class PortalRewardComponent implements OnInit {

  rewards$ = [];
  date = new Date();
  messages$ = this.rewardDataService.messages$;
  isSuccess = this.rewardDataService.isSuccess;
  pagination: Pagination;
  pageSize = 15;
  page = 1;
  maxSize = 5;
  params = 'active=1';
  currentPage = 1;
  activeTab = 1;
  rewardStatus = RewardsStatus;
  isSearching = false;
  constructor(
    private rewardDataService: RewardDataService,
    private loadingBar: LoadingBarService,
    public dialog: MatDialog,
    private dashboardService: DashboardService,
    private eventEmitterService: EventEmitterService,
  ) { }

  ngOnInit() {
    this.reload();
  }

  @HostListener('window:scroll', [''])
  onScroll(e: Event) {
    if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
      if (this.pagination && this.page <= this.pagination.last_page) {
        this.isSearching = true;
        this.page++;
        this.onViewPageBy(true, this.page, this.pageSize);
      }
    }
  }

  // onSelectTab(tab: number){
  //   this.page = 1;
  //   this.currentPage = 1;
  //   this.activeTab = tab;
  //   this.params = `active=${tab}`;
  //   this.onViewPageBy(false, this.currentPage);
  // }

  onViewPageBy(preLoad?: boolean, page = 1, pageSize?: number) {
    pageSize = this.pageSize;
    if (!preLoad){
      this.loadingBar.start();
      this.rewards$ = [];
    }
    const params = this.params ? `&${this.params}` : '';
    this.rewardDataService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.currentPage = page;
        this.pagination = this.rewardDataService.pagination;
        this.dashboardService.activeRewards$.next(res.length);
        if (res.length === 0){
          localStorage.removeItem('reward_notice');
        }
        res.map((row: any) => {
          this.rewards$.push({
            ...row,
            expiry_datetime: moment(row.expiry_datetime).local(true).format('YYYY-MM-DD'),
            claimed_at: moment(row.claimed_at).local(true).format('YYYY-MM-DD')
          });
          this.isSearching = false;
        });
        this.loadingBar.complete();
      })
    ).subscribe();
  }

  onPerPage(size: Event){
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(false, this.page, this.pageSize);
  }

  onClaim(row: any){
    const dialogRef = this.dialog.open(ClaimRewardModalComponent, {
      width: '90vw',
      data: {
        reward: row
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true){
        this.onViewPageBy(false, this.page, this.pageSize);
        //update reward bubble
        this.eventEmitterService.onUpdateRewardBubble();
      }
    });
  }

  private reload() {
    this.onViewPageBy(false, this.currentPage);
  }

}


import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class LiveChatIdService {

  siteName = environment.sitePrefix.toUpperCase();

  constructor(
  ) { }

  idStack = {
    ID: {
      EN: 9,
      ID: 8
    },
    TH: {
      EN: 7,
      TH: 6
    },
    SG: {
      EN: 4,
      ZH: 5
    },
    MY: {
      EN: this.siteName == 'BP9' ? 30 : 1,
      ZH: this.siteName == 'BP9' ? 31 : 2,
      MS: this.siteName == 'BP9' ? 30 : 3
    }
  }

  id: number;
  url = 'https://direct.lc.chat/10515072/';

  getUrl(country: string, language: string) {
    this.id = this.idStack[country][language];
    return this.url + this.id;
  }

}

<div class="modal show sign-in" aria-labelledby="mySmallModalLabel">
  <div class="modal-dialog-centered">
      <div class="login-modal">
          <div class="modal-bg modal-signin">
              <div class="modal-header pb-0">
                  <h4 class="modal-title" id="myModalLabel"><span [innerHTML]="svg.loginIcon | safeHtml"></span> {{ 'Login' | translate | uppercase }}</h4>
                  <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseDialog()">×</button>
              </div>
              <form [formGroup]="form" class="mb-0" (ngSubmit)="submit()" id="login-modal-form" name="login-modal-form">
                <div class="modal-body pt-0">
                    <hr>
                    <div class="form-input-label">
                        <p>{{ ('Username' | translate) | titlecase }}</p>
                    </div>
                    <div class="input_container">
                        <input type="text" [ngClass]="{'form-invalid': checkValidity && form.controls.username.errors }" formControlName="username" class="form-control form-control-sm form-input-style" (change)="toLowerCaseInput('username', $event)" #focusInput>
                    </div>
                    <div class="form-input-label">
                        <p>{{ ('Password' | translate) | titlecase }}</p>
                    </div>
                    <div class="input-icons-password ">
                        <i class="icon-eye-password" (click)="onPasswordMask()" [innerHTML]="passwordInput.icon | safeHtml"></i>
                        <input [type]="passwordInput.type" class="form-control form-control-sm form-input-style"
                            formControlName="password" [ngClass]="{'form-invalid password-invalid': checkValidity && form.controls.password.errors }" #passwordField id="password" name="password">
                        <p class="error-text text-danger" *ngIf="!form.get('password').pristine && !form.get('password').valid && form.get('password').value.length < 6">
                           {{ 'Password must be at least 6 characters' | translate }}
                        </p>
                    </div>
                    <button type="submit" class="btn-blue-gra">{{'Login' | translate | uppercase }}
                    </button>
                    <div class="row justify-content-between pl-3 pr-3">
                        <p class="confirm-text text-center m-t-15 m-b-20">
                            <a  class="btn-signup" (click)="onOpenDialog()" (click)="onCloseDialog()"> {{ 'Forgot Password' | translate }}?</a>

                        </p>
                        <p class="confirm-text text-center m-t-15 m-b-20">
                            <ng-container *ngIf="countryCode === 'TH' && languageCode === 'TH'; else elseOther">
                                <a routerLink="/signup" class="btn-signup" (click)="onCloseDialog()"> {{ 'Register Now' | translate | uppercase }}</a>
                            </ng-container>
                            <ng-template #elseOther>
                                {{ 'New User?' | translate }}
                                <a routerLink="/signup" class="btn-signup" (click)="onCloseDialog()"> {{ 'Sign Up' | translate | uppercase }}</a>
                            </ng-template>
                        </p>
                    </div>
                </div>
              </form>
          </div>
      </div>
  </div>
</div>
<app-swal-alert [message]="messages$ | async" [success]="true"></app-swal-alert>
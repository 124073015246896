import { Injectable } from '@angular/core';

type StepTwoLocalStorageKeys =
  | 'selectedMethodName'
  | 'selectedPaymentGateway'
  | 'stepTwoDeposit';
type StepTwoLocalStorageData = { [key in StepTwoLocalStorageKeys]: any };

@Injectable({
  providedIn: 'root',
})
export class TwoStepDepositDataService {
  public GATEWAY_PDXQR = 'PDXQR';
  public GATEWAY_PDXVA = 'PDXVA';

  // keys for two-step deposit related local storage
  private STEP_ONE_SELECTED_METHOD_NAME = 'stepOneSelectedMethodName';
  private STEP_ONE_SELECTED_PAYMENT_GATEWAY = 'stepOneSelectedPaymentGateway';
  private STEP_TWO_DEPOSIT = 'stepTwoDeposit';

  // messages
  private LEAVING_PAGE_MSG_1 =
    'Leaving this page will discard your payment progress.';
  private LEAVING_PAGE_MSG_2 =
    'Would you like to continue your payment?';
  private SESSION_EXPIRED_MSG =
    'Your deposit session has expired. Please submit a new deposit request again.';

  twoStepDepositPGs: string[] = [this.GATEWAY_PDXQR, this.GATEWAY_PDXVA];

  // if possible don't inject any services here to prevent circular dependency.
  // This service just serves as a "data store" for the two-step deposit process.
  constructor() {}

  public getTwoStepDepositPGs(): string[] {
    return this.twoStepDepositPGs;
  }

  public getLSStepOneSelectedMethodName(): string {
    return localStorage.getItem(this.STEP_ONE_SELECTED_METHOD_NAME);
  }

  public getLSStepOneSelectedPaymentGateway(): string {
    return localStorage.getItem(this.STEP_ONE_SELECTED_PAYMENT_GATEWAY);
  }

  public getLSStepTwoDeposit(): string {
    return localStorage.getItem(this.STEP_TWO_DEPOSIT);
  }

  public getLeavingPageMsg(): string {
    return this.LEAVING_PAGE_MSG_1 + ' ' + this.LEAVING_PAGE_MSG_2;
  }

  public getLeavingPageMsg1(): string {
    return this.LEAVING_PAGE_MSG_1;
  }

  public getLeavingPageMsg2(): string {
    return this.LEAVING_PAGE_MSG_2;
  }

  public getSessionExpiredMsg(): string {
    return this.SESSION_EXPIRED_MSG;
  }

  /**
   * Stores data related to the two-step deposit process in the local storage.
   *
   * @param data - An object containing the data to be stored in the local storage.
   * @param data.selectedMethodName - The selected deposit method name.
   * @param data.selectedPaymentGateway - The selected payment gateway.
   * @param data.stepTwoDeposit - The pending callback deposit data.
   */
  storeLocalStorageForStepTwo(data: StepTwoLocalStorageData) {
    localStorage.setItem(
      this.STEP_ONE_SELECTED_METHOD_NAME,
      data.selectedMethodName
    );
    localStorage.setItem(
      this.STEP_ONE_SELECTED_PAYMENT_GATEWAY,
      JSON.stringify(data.selectedPaymentGateway)
    );
    localStorage.setItem(
      this.STEP_TWO_DEPOSIT,
      JSON.stringify(data.stepTwoDeposit)
    );
  }

  /**
   * Clears the step two local storage data and reloads the page.
   * This function is used to clean up the local storage and refresh the page
   * when the user's deposit session has expired.
   */
  clearStepTwoLocalStorageAndReload() {
    this.clearStepTwoLocalStorage();
    window.location.reload();
  }

  /**
   * Clears the step two local storage data by removing relevant deposit information.
   * This function is used to clean up the local storage when the user's deposit session has expired.
   */
  clearStepTwoLocalStorage() {
    localStorage.removeItem(this.STEP_ONE_SELECTED_METHOD_NAME);
    localStorage.removeItem(this.STEP_ONE_SELECTED_PAYMENT_GATEWAY);
    localStorage.removeItem(this.STEP_TWO_DEPOSIT);
  }
}
import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { AuthActions } from '@core/store/auth/auth-action.types';
import { Store } from '@ngrx/store';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AppState } from 'app/store/reducers';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal, { SweetAlertCustomClass } from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import svgIconList from 'assets/icons.json';

@Injectable()
export class ApiErrorInterceptor implements HttpInterceptor {
  svg: any = svgIconList;

  excludeRoute = ['/member/wallet', '/member/transfer', '/maintenance'];
  excludeAPI = ['/api/member/gameprovider/balance'];

  customClass: SweetAlertCustomClass = {
    popup: 'customPopup2'
  };

  constructor(
    private store: Store<AppState>,
    private loadingBar: LoadingBarService,
    private eventEmitterService: EventEmitterService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private translateService: TranslateService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let message: string, currentRoute = window.location.pathname, currentAPIUrl = request.url;
    currentAPIUrl = currentAPIUrl.substring(currentAPIUrl.indexOf('/api/member/'));
    currentAPIUrl = currentAPIUrl.substring(0, currentAPIUrl.indexOf('?'));

    const buildMessage = (stack: any[] | string) => {
      let result = '';
      if (stack?.length > 0 && typeof stack !== 'string') {
        stack.map((m: any) => {
          if (m !== 'message') {
            result += `<li>${m}</li>`;
          }
        });
      }
      return result;
    };
    return next.handle(request).pipe(
      catchError((e: HttpErrorResponse) => {
        const userBrowser = {
          isDesktop: ((this.deviceService.device).includes('iPad')) ? true : this.deviceService.isDesktop(),
          userAgent: this.deviceService.getDeviceInfo().userAgent
        };
        localStorage.setItem('user_browser', JSON.stringify(userBrowser));
        const excludeAPI = ['/api/member/gameprovider/balance', '/api/member/verify']; // remove for now https://aiodintech.slack.com/archives/C0179MGKHEC/p1663241015235579
        // if (e.status === 0) {// remove for now https://aiodintech.slack.com/archives/C0179MGKHEC/p1663241015235579
        //   if (!this.excludeRoute.includes(currentRoute) || !excludeAPI.includes(currentAPIUrl)) {
        //     if (localStorage.getItem('maintenance') === null) {
        //       this.loadingBar.start();
        //       localStorage.setItem('maintenance', 'tbc');
        //       this.router.navigateByUrl('/maintenance');
        //     }
        //   }
        // }

        this.loadingBar.complete();
        this.eventEmitterService.onselectInput();
        const system = e.error;
        message = '<ul>';
        if ((!!system?.errors)) {
          const errors = system.errors;
          Object.keys(errors).forEach(key => message += buildMessage(errors[key]));
        }
        message += buildMessage(system.message);
        message += '</ul>';

        if (e.status === 444) {
          window.location.href = 'https://www.google.com';
          return;
        }

        if (e.status === 503) {
          if (localStorage.getItem('maintenance') === null) {
            this.loadingBar.start();
            localStorage.setItem('maintenance', e.error.data ? e.error.data.rows.end_maintenance_time : 'tbc');
            this.router.navigateByUrl('/maintenance');
          }
        } else {
          if (!(this.excludeRoute.includes(currentRoute) && this.excludeAPI.includes(currentAPIUrl)) && !environment.forceMaintenance) {
            if (!currentAPIUrl.includes('/api/member/seo/getCMSSettings')) {
              Swal.fire({
                customClass: this.customClass,
                html: '<div class="msg-icon">' + this.svg.dangerIcon + '</div>' +
                  '<div class="text-center m-t-20">' +
                  message +
                  '</div>',
                confirmButtonText: system.message[0] === 'Please update your profile' ? this.translateService.instant('Go Update') : 'OK',
              }).then(() => {
                this.eventEmitterService.onButtonEmitter();
                if (e.status === 403) {
                  localStorage.setItem('redirectToLogin', '1');
                  sessionStorage.setItem('openLogin', this.router.url);
                  this.store.dispatch(AuthActions.logout());
                  window.location.href = '/';
                }

                // if launch game from another site failed , redirect back to the orignal site
                if (window.location.pathname == '/gamelaunch' && !document.referrer.includes(window.location.origin)) {
                  window.location.href = document.referrer;
                }

                // TODO: Use http code instead!
                if (system.message[0] === 'Please verify your mobile phone number') {
                  this.eventEmitterService.onOpenVerificationEmitter();
                }

                if (system.message[0] === 'Please update your password') {
                  this.router.navigateByUrl('/member/change-password');
                }

                if (system.message[0] === 'Please update your profile') {
                  this.eventEmitterService.onUpdateProfileErrorCloseAllModalEmitter();
                  
                  if (localStorage.getItem('main_domain_url') != null && localStorage.getItem('main_domain_url') !== window.location.origin) {
                    var url = localStorage.getItem('main_domain_url') + '/member/profile';
                    window.location.replace(url);
                  }
                  else {
                    this.router.navigateByUrl('/member/profile');
                  }
                }
              });
            }
          }
          return throwError(message);
        }
      })
    );
  }
}

import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Swal, { SweetAlertCustomClass } from 'sweetalert2';
import svgIconList from 'assets/icons.json';

@Component({
  selector: 'app-swal-alert',
  templateUrl: './swal-alert.component.html',
  styleUrls: ['./swal-alert.component.scss']
})
export class SwalAlertComponent implements OnInit, OnChanges {
  svg: any = svgIconList;

  @Input()
  success: any;

  @Input()
  message: any[];

  @Input()
  contentHash: string;

  @Input()
  color: string = '#000';

  @Input()
  tick: boolean = true;

  /**
   * BY DEFAULT IS 3 SECONDS
   * ACTION THAT DO NOT REALTED TO MONEY SET 3 SECONDS
   * ACTION THAT RELATED TO MONEY SET 5 SECONDS
   */
  @Input()
  timer = 3000;

  @Input()
  register: boolean;

  @Input()
  ewalletVerification: boolean

  @Output()
  confirmed = new EventEmitter<boolean>();

  customClass: SweetAlertCustomClass = {
    popup: 'customPopup',
    content: 'customContent'
  };

  constructor(
    private translateService: TranslateService
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.alert();
  }

  private alert() {
    if ((!!this.message)) {
      let messages = '';
      if (this.register) {
        messages += this.translateService.instant('Congratulations! You have successfully registered as a member');
      } else {
        this.message.map((row: any) => messages += `${row}<br/>`);
      }

      const messageStyles = `
        // color:${this.color};
        width: ${this.tick ? '60vw' : '70vw'};
        overflow: hidden;
      `;
      if (this.ewalletVerification) {
        Swal.fire({
          html: '<div class="msg-icon-underreview">' + this.svg.underReviewIcon + '</div>' +
                '<div class="text-center m-t-20" style="font-size:18px; font-weight: 700;">'+ this.translateService.instant('Under Review') + '</div>' +
                '<div class="text-center m-t-20">' +
                  messages +
                '</div>' 
        }).then(result => {
          if (result) {
            this.confirmed.emit(true);
          }
        });
      } else {
        Swal.fire({
          html: `<div class="text-center" style="${messageStyles}">` + messages + '</div>',
          toast: true,
          showConfirmButton: false,
          showCloseButton: true,
          customClass: this.customClass,
          position: 'top',
          timer: this.timer,
          hideClass: {
            popup: `animate__animated animate__bounceOutUp`
          },
          ...(this.tick && { icon: 'success' })
        }).then(result => {
          if (result.isConfirmed) {
            this.confirmed.emit(true);
          }
        })
      }
    }
  }

}

import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { Pagination } from '@core/models/pagination.model';
import { isLoggedIn } from '@core/store/auth/auth.selectors';
import { select, Store } from '@ngrx/store';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AppState } from '@store/reducers';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ReferralDataService } from '../../../../../../core/services/referral-data.service';

declare const $: any;

@Component({
  selector: 'app-referral-history',
  templateUrl: './referral-history.component.html',
  styleUrls: ['./referral-history.component.scss']
})
export class ReferralHistoryComponent implements OnInit {

  details: any = {};
  currency = JSON.parse(localStorage.getItem('user_data')).currency;

  isLoggedIn$: Observable<boolean>;
  pagination: Pagination;
  pageSize = 15;
  page = 1;
  maxSize = 5;
  params = `?pagination=true&page=${this.page}&perPage=${this.pageSize}`;
  isLoading = false;
  history$ = [];
  userKYCstatus = localStorage.getItem('user_data') !== null ? JSON.parse(localStorage.getItem('user_data')).KYC : null;

  constructor(
    private referralDataService: ReferralDataService,
    private loadingBar: LoadingBarService,
    private datePipe: DatePipe,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.isLoggedIn$ = this.store.pipe(select(isLoggedIn));
    this.isLoggedIn$.subscribe(res => {
      if (res) {
        setTimeout(() => {
          this.userKYCstatus = JSON.parse(localStorage.getItem('user_data')).KYC;
          if (this.userKYCstatus === 'pro'){
            this.referralDataService.getDetails().subscribe(
              (res) => this.details = res,
              (err) => {
                Swal.fire({
                  html: '<i class="icon-exclamation-triangle alert-icon-fail"></i>' +
                    '<div class="text-center m-t-20">' +
                    err +
                    '</div>'
                });
              },
              () => { });
            this.loadingBar.start();
            this.onViewPageBy();
          }
        }, 500);
      }
    });
  }

  @HostListener('window:scroll', [''])
  onScroll(e: Event) {
    if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
      if (this.pagination && this.page <= this.pagination.last_page) {
        this.isLoading = true;
        this.page++;
        this.onViewPageBy(this.page);
      }
    }
  }

  onViewPageBy(page = 1) {
    this.params = `?pagination=true&page=${page}&perPage=${this.pageSize}`;
    this.referralDataService.getHistory(this.params).pipe(
      tap(res => {
        this.pagination = this.referralDataService.pagination;
        res.map(item => {
          item.register_date = this.datePipe.transform(item['register_date'], 'yyyy-MM-dd HH:mm');
          this.history$.push({
            ...item
          });
        });
        this.loadingBar.complete();
        this.isLoading = false;
      })
    ).subscribe();
  }

  showDetails(id: number) {
    $(`#refferal-details-${id}`).prev().toggleClass('opened');
  }

}

<div class="container app-sub-lobby-row" id="mega888" *ngIf="appGameProvider">
    <div class="app-sub-lobby col-sm-12 col-md-12">
        <div class="modal-content modal-bg-download">
            <div class="download-modal-body pb-0">
                <div class="download-input-with-icon">
                    <h4 class="mb-0 title">{{ appGameProvider['game_provider_name'] | uppercase }}</h4>
                    <hr class="mb-3 mt-2">
                </div>
                <div class="download-input-with-icon mb-3">
                    <p>{{ 'Game Wallet Balance' | translate }}</p>
                    <div class="input-group">
                        <span class="input-group-append d-flex align-items-end appended-container">
                        <span class="input-group-append">
                            <span class="input-rounded-left currency-box d-flex justify-content-center align-items-center"> {{ currency.code }} </span>
                        </span>
                        </span>
                        <input type="text" [value]="appBalance | number: '1.2-2'" class="form-control form-control-sm form-input-style mt-1 amount-text" disabled="" />
                    </div>
                </div>
                <form [formGroup]="form">
                    <div class="row download-input-with-icon">
                        <p class="col-12">{{ 'Transfer Amount' | translate | titlecase }}</p>
                        <div class="col-12 row ml-0 d-flex align-items-center justify-content-between">
                            <div class="input-group transfer-amount-text-container">
                                <span class="input-group-append d-flex align-items-end appended-container">
                                    <span class="input-group-append">
                                        <span class="input-rounded-left currency-box d-flex justify-content-center align-items-center"> {{ currency.code }} </span>
                                    </span>
                                </span>
                                <input type="text" formControlName="amount" class="form-control form-control-sm form-input-style mt-1 transfer-text mb-0 amount-text" digitOnly decimal="true" decimalSeparator="."/>
                            </div>
                            <button (click)="onTransfer()" class="btn-blue-gra col-4 d-flex align-items-center justify-content-center transfer-button">{{ 'Transfer' | translate | uppercase }}</button>
                        </div>
                    </div>
                </form>
                <div class="download-input-with-icon mt-4">
                    <p>{{ 'Username' | translate | titlecase }}</p>
                    <input type="text" [value]="username" class="form-control form-control-sm form-input-style mt-1" disabled="" />
                    <a class="btn-copy" [cdkCopyToClipboard]="username"
                        (cdkCopyToClipboardCopied)="onCopyText('username')">
                        <!-- <img src="/assets/images/app-sub-lobby/copy-icon.png" alt="Copy Icon"
                            (mouseenter)="$event.stopImmediatePropagation()"
                            (mouseleave)="$event.stopImmediatePropagation()" mat-raised-button
                            #tooltipUsername="matTooltip" [matTooltip]="'Copied' | translate" matTooltipPosition="above"> -->
                        <span [innerHTML]="svg.copyIcon | safeHtml"
                        (mouseenter)="$event.stopImmediatePropagation()"
                        (mouseleave)="$event.stopImmediatePropagation()" mat-raised-button
                        #tooltipUsername="matTooltip" [matTooltip]="'Copied' | translate" matTooltipPosition="above"></span>
                    </a>
                </div>

                <div class="download-input-with-icon">
                    <p>{{ 'Password' | translate | titlecase }}</p>
                    <input type="text" [value]="password" class="form-control form-control-sm form-input-style mt-1 mb-0" disabled="" />
                    <a class="btn-copy" [cdkCopyToClipboard]="password"
                        (cdkCopyToClipboardCopied)="onCopyText('password')">
                        <!-- <img src="/assets/images/app-sub-lobby/copy-icon.png" alt="Copy Icon"
                            (mouseenter)="$event.stopImmediatePropagation()"
                            (mouseleave)="$event.stopImmediatePropagation()" mat-raised-button
                            #tooltipPassword="matTooltip" [matTooltip]="'Copied' | translate" matTooltipPosition="above"> -->
                        <span [innerHTML]="svg.copyIcon | safeHtml"
                        (mouseenter)="$event.stopImmediatePropagation()"
                        (mouseleave)="$event.stopImmediatePropagation()" mat-raised-button
                        #tooltipPassword="matTooltip" [matTooltip]="'Copied' | translate" matTooltipPosition="above"></span>
                    </a>
                </div>
                <div class="row d-flex justify-content-center m-3">
                    <a class="game-changepassword-btn" (click)="changeGamePassword()">{{ 'Change Game Password' | translate | uppercase }} </a>
                </div>
            </div>

            <div class="text-center download-button-container">
                <button class="btn-download" *ngIf="gameCode !== 'LIVE'; else elseLIVE">
                    <div class="download-icon">
                        <a [href]="miniURL">
                            <span [innerHTML]="svg.androidIcon | safeHtml"></span>
                            <span [innerHTML]="svg.appleIcon | safeHtml"></span>
                            <div class="download-title">
                                <h4> {{ 'Download' | translate | uppercase }} <br>
                                    <span class="download-title-small">{{ 'iOS' | translate }} / {{ 'Android' | translate }}</span>
                                </h4>
                            </div>
                        </a>
                    </div>
                </button>
                <ng-template #elseLIVE>
                    <button class="btn-download">
                        <div class="download-icon">
                            <a href="http://download.aries22.com/mobileservice/download/live22/android">
                                <span [innerHTML]="svg.androidIcon | safeHtml"></span>
                                <div class="download-title">
                                    <h4> <span class="download-text">{{ 'Download' | translate | uppercase }}</span> <br>
                                        <span>{{ 'Android' | translate }}</span>
                                    </h4>
                                </div>
                            </a>
                        </div>
                    </button>
                    &nbsp;
                    <button class="btn-download">
                        <div class="download-icon">
                            <a href="https://22.caveman88.com/web/lobbyv2/login.html?m7=N9ic" target="_blank">
                                <span [innerHTML]="svg.appleIcon | safeHtml"></span>
                                <div class="download-title">
                                    <h4> <span class="download-text">{{ 'Download' | translate | uppercase }}</span>  <br>
                                        <span>{{ 'iOS' | translate }}</span>
                                    </h4>
                                </div>
                            </a>
                        </div>
                    </button>
                </ng-template>
            </div>
            <div class="playnow-button-container" *ngIf="deepLink !== null">
                <button class="btn-playnow"  (click)="onOpenDeepLink()">
                 {{ 'Play Now' | translate | uppercase }}
                </button>
            </div>
            <div class=" download-modal-body">
                <h6 class="pg-title m-b-10">{{ '3 EASY STEPS TO DOWNLOAD' | translate }} {{ gameCode | translate }}</h6>
                <div class="download-info">
                    <ol>
                        <li>{{ 'Click the download button to download [gameProvider] if you have not downloaded.' | translate : { 'gameProvider' : gameCode } }}</li>
                        <li>{{ 'Proceed to install the App and follow the on screen instructions.' | translate }}</li>
                        <li>{{ 'Launch the application and enter the following details to login!' | translate }}</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</div>
<app-swal-alert [success]="isSuccess" [message]="messages$ | async" [timer]="5000"></app-swal-alert>
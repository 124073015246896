import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ChangeDetectorRef, OnChanges , ChangeDetectionStrategy } from '@angular/core';
import Swal, { SweetAlertCustomClass } from 'sweetalert2';
import { Router } from '@angular/router';
import { SwiperOptions } from 'swiper';
import { DomSanitizer, SafeHtml} from '@angular/platform-browser';
import { isLoggedIn } from '@core/store/auth/auth.selectors';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from '@store/reducers';
import { AnnouncementHttpService } from '@core/services/announcement-http.service';
@Component({
  selector: 'app-popup-announcement',
  templateUrl: './popup-announcement.component.html',
  styleUrls: ['./popup-announcement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupAnnouncementComponent implements OnInit {

  content$ = [];
  displayStyle = "none";

  isLoggedIn$: Observable<boolean>;

  config: SwiperOptions = {
    pagination: { el: '.swiper-pagination-popup', clickable: true },
    autoHeight: true,
    allowTouchMove: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    loop: false // Do not turn on the loop, else the CTA button is not clickable
  };
  
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private domSanitizer:DomSanitizer,
    private cdr: ChangeDetectorRef,
    private announcementHttpService: AnnouncementHttpService,
  ) { }

  @Input()
  message: any[];

  @Output()
  confirmed = new EventEmitter<boolean>();

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.alert();
  }

  openPopup() {
    this.displayStyle = "block";
  }
  
  closePopup() {
    let popup_ids = localStorage.getItem('popup_ids');
    if (popup_ids != undefined && popup_ids != null && JSON.parse(popup_ids).length > 0) {
      this.isLoggedIn$.subscribe(res => {
        if (res) {
            const data = {
              popup_ids: JSON.parse(localStorage.getItem('popup_ids'))
            }
            this.announcementHttpService.updatePromotionPopupDialogLog(data).subscribe(() => {
              localStorage.removeItem('popup_ids');
            });
          }
      });

      let announcementPopList = JSON.parse(localStorage.getItem('announcements_pop')).filter(x => JSON.parse(popup_ids).includes(x.id));
      let announcementPopCodes = announcementPopList.map(function(i) {
        return i['code'];
      });

      if (announcementPopCodes.length > 0 && localStorage.getItem('dialog') != null) {
        if (announcementPopCodes.includes(localStorage.getItem('dialog'))) {
          localStorage.removeItem('dialog');
        }
      }
    }
    
    this.displayStyle = "none";
    this.content$ = [];
    this.cdr.detectChanges();
    this.confirmed.emit(true);
  }

  private alert() {

    //checking login
    this.isLoggedIn$ = this.store.pipe(select(isLoggedIn));

    this.content$ = [];
    this.cdr.detectChanges();
    const currentLanguageCode = localStorage.getItem('language_code');
    if (this.message.length > 0) {
      this.isLoggedIn$.subscribe(res => {
        if (res) {
          this.message.map(content => {
            let item = content[0];
            let language_code = (item['locale_name'] as string ).split("_", 2);
            if(currentLanguageCode == language_code[1] && (item['session'] == 1 || item['session'] == 3)){
              if(item['title'] || item['content'] || ( item['media_type'] && item['desktop_link'] ) )  this.content$.push(item);
            }
          });
        } else {
          this.message.map(content => {
            let item = content[0];
            let language_code = (item['locale_name'] as string ).split("_", 2);
            if(currentLanguageCode == language_code[1] && (item['session'] == 1 || item['session'] == 2)){
              if(item['title'] || item['content'] || ( item['media_type'] && item['desktop_link'] ))  this.content$.push(item);
            }
          });
        }
        this.cdr.markForCheck();
        if(this.content$.length > 0) this.openPopup();
      });      
    }
  }

  onRedirect(item: any, link: string){
    this.isLoggedIn$.subscribe(res => {
      if (res) {
        const data = {
          popup_ids: [item.id]
        }
        this.announcementHttpService.updatePromotionPopupDialogLog(data).subscribe(() => {});
      }
    });

    if (link !== (null && '')){
      if (link.includes('/external?link=')) {
        var redirectTO = link.replace('/external?link=','');
        window.open(redirectTO, '_blank');
      } else {
        this.router.navigateByUrl(link);
      }
      this.closePopup();
    }
  }

  getUrl(url) {
    if(url.includes("youtube") && url.includes("watch?v=")){
      url = url.replace('watch?v=', 'embed/') + '?controls=1&mute=1&showinfo=0&rel=0&autoplay=1&loop=1';
    }
    else if(url.includes("youtube")) {
      url = url + '?controls=1&mute=1&showinfo=0&rel=0&autoplay=1&loop=1';
    }
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  displayVideoType(url) {
    if(url.includes("youtube")){
      return 'youtube'
    }else{
      return 'video'
    }
  }

  returnImageRes(){
    
  }  

  getImageDimenstion(i){
    const img = document.getElementById('image'+i) as HTMLImageElement;
    if(img.naturalHeight > img.naturalWidth) img.style.paddingTop = '25px';
  }

  checkPopupList() {
    if (localStorage.getItem('announcements_pop') !== null) {
      return true;
    }
    else {
      return false;
    }
  }
}

// Angular
import { Component, OnInit, AfterViewInit, Input } from "@angular/core";
import { Router } from "@angular/router";
// Service
import { SwiperOptions } from "swiper";
// Model
import {
  RegistrationPage,
  RegistrationPageBanner,
  RegistrationPageContainer,
} from "@core/models/registration-page.model";

@Component({
  selector: "app-signup-layout1",
  templateUrl: "./layout1.component.html",
  styleUrls: ["./layout1.component.scss"],
})
export class Layout1Component implements OnInit, AfterViewInit {
  @Input()
  dynamicData: RegistrationPage = null;

  config: SwiperOptions = {
    pagination: { el: ".swiper-pagination", clickable: true },
    allowTouchMove: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    loop: true,
  };
  containers: RegistrationPageContainer[] = [];
  topBanner: RegistrationPageBanner = null;
  bottomBanner: RegistrationPageBanner = null;

  constructor(private router: Router) {}

  ngOnInit() {
    this.setContainers();
    this.setBanners();
  }

  ngAfterViewInit() {}

  private setContainers() {
    if (!this.dynamicData) return;
    this.containers = this.dynamicData.containers;
  }

  private setBanners() {
    if (!this.dynamicData) return;

    const banners = this.dynamicData.banners;

    this.topBanner = banners.filter(
      (banner) => banner.banner_section_type_id === 1 // top
    )[0];

    this.bottomBanner = banners.filter(
      (banner) => banner.banner_section_type_id === 2 // bottom
    )[0];
  }

  onNavigate(link: string) {
    if (link !== "") {
      window.location.href = link;
    } else {
      this.router.navigateByUrl("/");
    }
  }
}

<div class="modal show sign-in" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog-centered">
        <div class="signup-modal">
            <div class="signup-container modal-content">
                <div class="signup-header modal-header pb-0">
                    <h4 class="modal-title" id="myModalLabel"><span [innerHTML]="svg.registerIcon | safeHtml"></span>
                        {{ 'Registration' | translate | uppercase }}
                    </h4>
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseDialog()">×</button>
                </div>

                <form [formGroup]="form" (ngSubmit)="submit(this.form.value)" id="signup-modal-form" name="signup-modal-form" class="m-b-0">
                    <div class="modal-body pt-0">
                        <hr>
                        <!--Username-->
                        <div class="form-input-label">
                            <p>{{ 'Username' | translate | titlecase }} </p>
                        </div>
                        <div class="input_container">
                            <!-- Added id and username for browser to collect credentials correctly to save. Tested on : Chrome, Edge and Firefox -->
                            <input type="text" class="form-control form-control-sm form-input-style" formControlName="username" [ngClass]="{'form-invalid': checkValidity && form.controls.username.errors, 'custom-field-valid': (form.value.username.length > 5 && form.value.username.length < 17) && (!usernameFormatValid.special && form.value.username.length > 0) && usernameFormatValid.consist && serverSideValidations.username, 'invalid-input': (serverSideValidated && !serverSideValidations.username) }" (keyup)="toLowerCaseInput('username', $event); onCheckUsernameFormat(form.value.username); resetServerValidation();" #focusInput
                                id="username" name="username" (focusout)="onServerSideUsernameValidation(form.value.username)" />
                            <ng-container>
                                <p
                                    *ngIf="(serverSideValidated && !serverSideValidations.username) else elseTemplate"
                                    class="error-text small mar-top--15 d-flex justify-content-between err-msg text-danger"
                                >
                                    <span>{{ "Username is already taken" | translate }}</span>
                                </p>
                            </ng-container>
                            <ng-template #elseTemplate>
                                <ng-container *ngIf="(checkValidity && form.controls.username.errors) || (!form.get('username').pristine && !form.get('username').valid)">
                                    <p class="error-text small mar-top--15 d-flex justify-content-between err-msg" [ngClass]="form.value.username.length > 5 && form.value.username.length < 17 ? 'text-success' : 'text-danger'">
                                        <span>{{ 'Between 6 to 16 characters' | translate }}</span>
                                        <i *ngIf="form.value.username.length > 5 && form.value.username.length < 17" class="fa fa-check position-relative text-success"></i>
                                    </p>
                                    <p class="error-text small mar-top--15 d-flex justify-content-between err-msg" [ngClass]="!usernameFormatValid.special && form.value.username.length > 0 ? 'text-success' : 'text-danger'">
                                        <span>{{ 'No special characters allowed' | translate }}</span>
                                        <i *ngIf="!usernameFormatValid.special && form.value.username.length > 0" class="fa fa-check position-relative text-success"></i>
                                    </p>
                                    <p class="error-text small mar-top--15 d-flex justify-content-between err-msg" [ngClass]="usernameFormatValid.consist ? 'text-success' : 'text-danger'">
                                        <span>{{ 'Consists of letters and numbers OR letters only' | translate }}</span>
                                        <i *ngIf="usernameFormatValid.consist" class="fa fa-check position-relative text-success"></i>
                                    </p>
                                </ng-container>
                            </ng-template>
                        </div>

                        <!--Phone Number-->
                        <div class="form-input-label">
                            <p>{{ 'Phone Number' | translate |titlecase }} </p>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                <div class="dropdown">
                                    <button class="w-100 btn form-input-style p-0 text-white d-flex justify-content-around" type="button" data-toggle="dropdown">
                                        <img [src]="'/assets/images/language-icon/' + selected + '.png'" class="m-auto">
                                        <span class="icon-arrow"><img src="/assets/images/down-arrow.png"></span>
                                    </button>
                                    <ul class="dropdown-menu">
                                    <li class="text-center" *ngFor="let dropDownCountry of dropDownCountries" [ngClass]="{'selected': selected === dropDownCountry.value}" (click)="onChangeCountry(dropDownCountry.value)">
                                        <img [src]="'/assets/images/language-icon/' + dropDownCountry.value + '.png'"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-8 p-l-0">
                                <div class="input-container">
                                    <!-- Removed the type value to prevent Firefox get phone numbers as username to save -->
                                    <input type="" class="form-control form-control-sm form-input-style" formControlName="phone_number" (keypress)="numberOnly($event)" [ngClass]="{'form-invalid': checkValidity && form.controls.phone_number.errors,'custom-field-valid': form.get('phone_number').valid }" digitOnly inputmode="numeric"/>
                                    <ng-container *ngIf="(checkValidity && form.controls.phone_number.errors) || (!form.get('phone_number').pristine && !form.get('phone_number').valid)">
                                        <p class="error-text text-danger small mar-top--15 d-flex justify-content-between">
                                            <span>{{ 'Invalid phone number format' | translate }}</span>
                                        </p>
                                    </ng-container>
                                </div>
                            </div>
                        </div>

                        <!--Password-->
                        <div class="form-input-label">
                            <p>{{ 'Password' | translate | titlecase }} </p>
                        </div>
                        <div class="input-icons-password ">
                            <i class="icon-eye-password" (click)="onPasswordMask('password')" [innerHTML]="passwordInput.icon | safeHtml"></i>
                            <!-- Added id and username for browser to collect credentials correctly to save. Tested on : Chrome, Edge and Firefox -->
                            <input [type]="passwordInput.type" class="form-control form-control-sm form-input-style" formControlName="password" (keyup)="onCheckPassword()" [ngClass]="{'form-invalid password-invalid': checkValidity && form.controls.password.errors, 'custom-field-valid': form.get('password').valid }" #passwordField id="password" name="password" />
                            <ng-container *ngIf="(checkValidity && form.controls.password.errors) || (!form.get('password').pristine && !form.get('password').valid)">
                                <p class="error-text text-danger small mar-top--15 d-flex justify-content-between">
                                    <span>{{ 'At least 6 characters' | translate }}</span>
                                    <i *ngIf="form.value.password.length > 5" class="fa fa-check position-relative text-success"></i>
                                </p>
                            </ng-container>
                        </div>

                        <!--Confirm Password-->
                        <div class="form-input-label">
                            <p>{{ 'Confirm Password' | translate | titlecase }} </p>
                        </div>
                        <div class="input-icons-password ">
                            <i class="icon-eye-password" (click)="onPasswordMask('confirmPassword')" [innerHTML]="confirmPasswordInput.icon | safeHtml"></i>
                            <input [type]="confirmPasswordInput.type" class="form-control form-control-sm form-input-style" formControlName="confirm_password" [ngClass]="{'form-invalid password-invalid': (checkValidity && form.controls.confirm_password.errors), 'input-invalid': !passwordMatched, 'custom-field-valid': passwordMatched && form.controls.confirm_password.valid }" (keyup)="onCheckPassword()" #passwordField />
                            <ng-container *ngIf="!passwordMatched || (checkValidity && form.controls.confirm_password.errors)">
                                <p class="error-text text-danger small mar-top--15 d-flex justify-content-between">
                                    <span>{{ 'Passwords do not match' | translate }}</span>
                                </p>
                            </ng-container>
                        </div>

                        <!--Referral-->
                        <div class="form-input-label" *ngIf="showRef">
                            <p>{{ 'Referral' | translate | titlecase }} ({{ 'Optional' | translate | titlecase }})</p>
                        </div>
                        <div class="input_container" *ngIf="showRef">
                            <input type="" formControlName="ref" class="form-control form-control-sm form-input-style" [readonly]="isRefSet"/>
                        </div>

                        <!-- Agent -->
                        <div class="form-input-label" *ngIf="showAgent">
                            <p>{{ 'Agent' | translate | titlecase }} ({{ 'Optional' | translate | titlecase }})</p>
                        </div>
                        <div class="input_container" *ngIf="showAgent">
                            <input type="" formControlName="agent" class="form-control form-control-sm form-input-style" [readonly]="true"/>
                        </div>

                        <!-- Affiliate -->
                        <div class="form-input-label" *ngIf="showAff">
                            <p>{{ 'Affiliate' | translate | titlecase }} ({{ 'Optional' | translate | titlecase }})</p>
                        </div>
                        <div class="input_container" *ngIf="showAff">
                            <input type="" formControlName="aff" class="form-control form-control-sm form-input-style" [readonly]="true"/>
                        </div>

                        <!--Confirm T&C text-->
                        <p class="m-b-0" [innerHTML]="'By clicking Join, I confirm that I am at least 18 years of age and that I accept the <a class=\'tnc-link\'>Terms & Conditions</a>.' | translate"></p>
                        <!--Action Button-->
                        <button type="submit" class="btn-blue-gra m-t-15">
                            {{ 'Join' | translate | uppercase}}
                        </button>
                        <p class="m-t-20 m-b-20 text-center">{{ 'Existing Member?' | translate }} <a (click)="onLogin()"class="btn-signup"> {{ 'Login Now' | translate }}</a></p>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<app-swal-alert [message]="messages$ | async" [success]="true" [register]="true"></app-swal-alert>
<div class="modal1" id="modal-signup">
  <div class="block mx-auto ma-box boxshadow">
    <div class="row">
      <div class="col-12">
        <div class="content bank-modal-content">
          <div class="modal show sign-in" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-body">
                    <div class="modal-content modal-bg modal-blue-bg">
                        <div class="modal-header">
                            <h4 class="modal-title" id="myModalLabel">{{ data.request_type == 'phone_validation' ? ('PHONE NUMBER VERIFICATION' | translate) : ('OTP VERIFICATION' | translate ) }}</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseDialog(true)">×</button>
                        </div>
                        <div class="bd-bold"></div>
                        <form [formGroup]="form" class="p-1rem OTP-input m-b-0" id="verification-modal-form" name="verification-modal-form">
                          <div class="user-input">
                            <label *ngIf="data.request_type == 'phone_validation'">{{ 'Click on REQUEST OTP button and enter the 6-digit code you received via SMS into the field below to verify your mobile number.' | translate }} <br></label>

                            <label>{{ 'OTP' | translate }}</label>
                            <div class="input_container">
                              <input type="text" class="form-control m-t-5" placeholder="{{ 'Enter 6-digit OTP' | translate }}" formControlName="otp" />
                              <div class="inside-button-cont">
                                <button type="button" (click)="onRequestOTP()" class="btn-blue-gra inside-button-button d-flex align-items-center justify-content-center" [disabled]="counter > 0">
                                  {{ counter > 0 ? ('OTP SENT' | translate) : ('REQUEST OTP' | translate) }} <span *ngIf="counter > 0" class="ml-1"> ({{ this.counter}})</span>
                                </button>
                              </div>
                            </div>

                          </div>
                          <button type="button" [disabled]="!form.valid" class="btn-blue-gra pl-3 pr-3" (click)="onVerify()">{{ data.request_type == 'phone_validation' ? ('OK' | translate) : ('Verify' | translate | uppercase) }}</button>
                        </form>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-swal-alert [success]="isSuccess" [message]="messages$ | async" (confirmed)="onCloseDialog(toCloseDialog)"></app-swal-alert>

<div class="modal show">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-body">
            <div class="modal-content modal-bg demomode-notice">
                <div class="notice-body">
                    <p class="icon-wrapper"><span class="icon">i</span></p>
                    <h6>{{ 'VPN REQUIRED' | translate }}</h6>
                    <p>{{ 'Game(s) under this provider are not accessible from your country by default. Please connect to a VPN should you wish to play them.' | translate }}</p>

                    <!-- No need for translation -->
                    <button class="btn-blue-gra m-b-10" (click)="onConfirm()">OK</button>
                </div>
                <p class="do-not-show-again d-flex justify-content-center my-2" (click)="onNotShowAgain()">{{ 'Do not show again' | translate }}</p>
            </div>
        </div>
    </div>
</div>
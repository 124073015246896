import { ApiResponse } from '../models/api-response.model';
import { Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MenuBar } from '../models/menu-bar.model';

@Injectable({
  providedIn: 'root'
})
export class MenuBarHttpService {

  constructor(private http: HttpClient) {}

  getMenuBar(): Observable<MenuBar[]>{
    return this.http.get<ApiResponse>(`/menubar`).pipe(
      map(res => res.data.rows)
    );
  }
}

// Service
import { environment } from '@env/environment';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

export class CustomMissingTranslationHandler implements MissingTranslationHandler {

  brandName = (environment.sitePrefix as string).toUpperCase();

  constructor() { }

  handle(params: MissingTranslationHandlerParams) {
    params.key = params.key?.split('$brand_name').join(this.brandName);
    if (params.interpolateParams) {
      var result = params.key;
      Object.keys(params.interpolateParams).forEach(item => {
        var re = new RegExp('\\[' + item + ']', 'g');
        result = result.replace(re, params.interpolateParams[item]);
      })
      return result;
    } else {
      return params.key;
    }
  }
}
import { Subscription } from 'rxjs/internal/Subscription';
import { tap, map } from 'rxjs/operators';
import { GameProviderHttpService } from '@core/services/game-provider-http.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { GameCategoryHttpService } from '@core/services/game-category-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { GamePasswordHttpService } from '../../services/change-game-password-http.service';
import { forkJoin } from 'rxjs';
import svgIconList from 'assets/icons.json';

@Component({
  selector: 'app-portal-game-password',
  templateUrl: './portal-game-password.component.html',
  styleUrls: ['./portal-game-password.component.scss']
})
export class PortalGamePasswordComponent implements OnInit {
  svg: any = svgIconList;
  messages$ = this.gamePasswordHttpService.messages$;
  isSuccess = false;

  slotProviders$: any;
  form: FormGroup;

  passwordMatched = true;
  passwordInput = {
    type: 'password',
    icon: this.svg.hidePasswordIcon
  };
  confirmPasswordInput = {
    type: 'password',
    icon: this.svg.hidePasswordIcon
  };
  gameCategoryList: any;
  usernameList = [];
  windowInnerHeight: number;

  gameProviderId = window.history.state.appId;
  filename = window.history.state.appFileName;
  username = window.history.state.appUsername;
  countryCode = localStorage.getItem('country_code');
  checkValidity = false;
  usernameFormatValid = {
    special: false
  };
  private subscription = new Subscription();

  constructor(
    public dialog: MatDialog,
    private gamePasswordHttpService: GamePasswordHttpService,
    public loadingBar: LoadingBarService,
    public gameCategoryHttpService: GameCategoryHttpService,
    private gameProviderHttpService: GameProviderHttpService
  ) { }

  ngOnInit() {
    this.windowInnerHeight = window.innerHeight;
    this.setUsernameList();
    this.formInit();
    this.slotProviders$ = this.gameCategoryHttpService.getWithQuery('?category_code=SL');
  }

  onCheckPassword() {
    this.passwordMatched = true;
    if (this.form.value.confirm_password !== null && this.form.value.password !== null) {
      if (this.form.value.password !== this.form.value.confirm_password) {
        this.passwordMatched = false;
      }

      if (this.form.value.password.length === 0 || this.form.value.confirm_password.length === 0) {
        this.passwordMatched = true;
      }
    }
  }

  selectProvider(provider: any, type?: string) {
    this.gameProviderId = provider.game_provider_id;
    this.filename = provider.filename?.split(".")[0];

    this.form.patchValue({
      ... this.form,
      game_provider_id: provider.game_provider_id
    });

    this.username = this.usernameList.find(x => x.code === provider.game_provider_code)?.username;
  }

  onPasswordMask(type?: string) {
    if (type === 'password') {
      this.passwordInput.type = this.passwordInput.type === 'password' ? 'text' : 'password';
      this.passwordInput.icon = this.passwordInput.icon === this.svg.hidePasswordIcon ?this.svg.viewPasswordIcon : this.svg.hidePasswordIcon;
    } else {
      this.confirmPasswordInput.type = this.confirmPasswordInput.type === 'password' ? 'text' : 'password';
      this.confirmPasswordInput.icon = this.confirmPasswordInput.icon === this.svg.hidePasswordIcon ?this.svg.viewPasswordIcon : this.svg.hidePasswordIcon;
    }
  }

  onConfirm() {
    this.formInit();
  }

  onSave() {
    this.checkValidity = true;
    if (this.form.valid) {
      const data = {
        ...this.form.value
      };
      Object.keys(data).forEach((key) => (data[key] == null || data[key] === ''));
      this.gamePasswordHttpService.updatePassword(data).subscribe(() => {
        this.checkValidity = false;
        this.isSuccess = this.gamePasswordHttpService.isSuccess;
        this.filename = null;
        this.gameProviderId = null;
        this.username = null;
        this.formInit();
      }
      );
    }
  }

  onCheckPasswordFormat(username: any) {
    return this.usernameFormatValid = {
      special: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(username)
    };
  }

  formInit() {
    this.form = new FormGroup({
      game_provider_id: new FormControl(this.gameProviderId),
      password: new FormControl(null, [Validators.required, Validators.minLength(8), Validators.pattern(`(^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$)|([a-zA-Z])`)]),
      confirm_password: new FormControl(null, [Validators.required, Validators.minLength(1)])
    }, { validators: this.checkPasswords });
  }

  private setUsernameList(){
    this.gameCategoryHttpService.getGameCategoryListWithPromo().subscribe(res => {
      this.usernameList = res['rows'];
      this.gameCategoryList = res;
    });
  }

  private checkPasswords(group: FormGroup) {
    const password = group.get('password').value;
    const confirmPassword = group.get('confirm_password').value;

    return password === confirmPassword ? null : { notSame: true }
  }

  @HostListener('window:resize', [])
  onResize() {
    if (window.innerHeight < this.windowInnerHeight) {
      window.scroll(0, 120);
    }
  }

}

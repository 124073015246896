<!-- Popup -->
<div [ngClass]="{'request-dialog-div': !minimizeDialog}">
  <div class="request-dialog" [ngClass]="{'request-dialog-minimize': minimizeDialog}" id="request-dialog">
    <div class="modal1">
      <div class="row">
        <div class="col-lg-12">
          <div class="p-20">
            <div class="d-flex justify-content-between">

              <div class="fancy-title mb-0">
                <span *ngIf="stepper.selectedIndex > 0" [innerHTML]="svg.backIcon | safeHtml" class="title-action-btn" (click)="stepper.previous()"></span>
                <!-- <img src="/assets/images/receipt-icn.png" class="title-image" /> -->
                <span [innerHTML]="svg.receiptIcon | safeHtml" class="title-image"></span>
                <h4>{{ (stepper.selectedIndex == 0 ? "Payment Receipt Required" : "Upload Receipt") | translate | uppercase }}</h4>
              </div>
              <button type="button" class="close" (click)="onTogglePopup()">×</button>
              <!-- <button title="Close (Esc)" type="button" class="mfp-close" (click)="onTogglePopup()">
                ×
              </button> -->
              
            </div>
            <hr/>

            <mat-horizontal-stepper linear #stepper>
              <!-- Summary of the request -->
              <mat-step>
                <div class="form-group">
                    <label>{{ 'There was a difficulty in verifying your latest deposit.' | translate }} </label>
                    <label>{{ 'Kindly upload your payment receipt for us to continue processing your deposit.' | translate }} </label>
                    <div class="details row">
                      <!-- Bank -->
                      <label class="col-3 m-0 py-1">{{ 'Bank' | translate }}</label>
                      <label class="col-1 m-0 py-1 px-0">:</label>
                      <label class="col-8 m-0 py-1 px-0">{{ depositData?.bank_name }}</label>
                      <!-- Amount -->
                      <label class="col-3 m-0 py-1">{{ 'Amount' | translate }}</label>
                      <label class="col-1 m-0 py-1 px-0">:</label>
                      <label class="col-8 m-0 py-1 px-0">{{ depositData?.currency_symbol }} {{ (depositData?.currency_code != 'IDR' ? (depositData?.amount| number : '1.0') : (depositData?.amount * 1000 | number : '1.0' : 'id-ID') ) }}</label>
                      <!-- Date/Time -->
                      <label class="col-3 m-0 py-1">{{ 'Date/Time' | translate }}</label>
                      <label class="col-1 m-0 py-1 px-0">:</label>
                      <label class="col-8 m-0 py-1 px-0">{{ depositData?.date_time | date:'yyyy-MM-dd HH:mm:ss' }}</label>
                      <!-- Reason -->
                      <label class="col-3 m-0 py-1">{{ 'Reason' | translate }}</label>
                      <label class="col-1 m-0 py-1 px-0">:</label>
                      <label class="col-8 m-0 py-1 px-0">{{ depositData?.reason ? depositData.reason : '-' }}</label>
                    </div>
                </div>
                <div class="d-flex justify-content-around">
                    <button type="button" class="btn-blue-gra w-50 mr-1 bottom-action-btn" (click)="onCancelDeposit()">{{ 'Cancel Deposit' | translate }}</button>
                    <button type="button" class="btn-yellow-gra w-50 ml-1 bottom-action-btn" matStepperNext>{{ 'Upload Now' | translate }}</button>
                </div>
              </mat-step>
              <!-- Upload Receipt -->
              <mat-step>
                <form class="upload-receipt-box mb-0" id="uploadReceiptForm">
                  <div class="d-flex flex-row flex-wrap">
                    <div class="receipt-box d-flex justify-content-center align-items-center" *ngFor="let receipt of uploaded_receipts; let i = index">
                      <img *ngIf="!receipt.isPdf" [src]="receipt.path" alt="{{ 'Image Preview' | translate }}" />
                      <iframe *ngIf="receipt.isPdf" width="100%" height="100%" [src]="receipt.path" frameborder="0" style="border:0" allowfullscreen></iframe>
                      <div class="trash-img-div d-flex justify-content-end align-items-start">
                        <span [innerHTML]="svg.trashIcon | safeHtml" class="upload-action-btn" (click)="onRemoveFileMulti(i)"></span>
                      </div>
                    </div>
                    <div *ngIf="uploaded_receipts.length < max_receipts" class="receipt-box d-flex justify-content-center align-items-center">
                      <input type="file" accept="image/*, application/pdf" (change)="onUploadFileMulti($event)" #myFileInput hidden/>
                      <!-- <img *ngIf="!uploadInProgress" src="/assets/images/upload-btn.png" class="upload-action-btn" (click)="myFileInput.click()" /> -->
                      <span *ngIf="!uploadInProgress" [innerHTML]="svg.uploadIcon | safeHtml" class="upload-action-btn" (click)="myFileInput.click()"></span>
                      <span *ngIf="uploadInProgress" class="spinner-border"></span>
                    </div>
                  </div>
                </form>

                <!-- <form class="upload-receipt-box" id="uploadReceiptForm">
                  <div class="upload-box d-flex justify-content-center align-items-center">
                    <input type="file" accept="image/*, application/pdf" (change)="onUploadFile($event)" #myFileInput hidden/>
                    <ng-container *ngIf="!uploadInProgress && imagePreview == null; else imgPreview">
                      <img src="/assets/images/upload-btn.png" class="upload-action-btn" (click)="myFileInput.click()"/>
                    </ng-container>
                    <ng-template #imgPreview>
                      <div *ngIf="!uploadInProgress" class="d-flex justify-content-center align-items-center img-preview-div">
                        <img [src]="imagePreview" class="img-preview"/>
                        <div class="trash-img-div d-flex justify-content-end align-items-start">
                          <img src="/assets/images/trash-red.png" width="25" (click)="onRemoveFile()">
                        </div>
                      </div>
                    </ng-template>
                    <span *ngIf="uploadInProgress" class="spinner-border"></span>
                  </div>
                </form> -->

                <button type="button" class="w-100 bottom-action-btn" [ngClass]="form.valid ? 'btn-blue-gra' : 'button-white'" (click)="onSubmit()">
                  <span *ngIf="formSubmitting" class="spinner-border" style="color: #000000"></span>
                  <ng-container *ngIf="!formSubmitting">{{ 'Submit Receipt' | translate }}</ng-container>
                </button>
              </mat-step>
            </mat-horizontal-stepper>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Ballon -->
<div class="d-flex justify-content-center align-items-center" [ngClass]="{'hide-ballon': (!showBallon || depositData == null), 'receipt-floating-div': minimizeDialog, 'receipt-floating-div-hide': !minimizeDialog}" id="receipt-floating-bubble">
  <span class="badge-btn" matBadge="×" matBadgeColor="warn" (click)="onCloseBallon()" matBadgeOverlap="true"></span>
  <div class="receipt-floating-content" (click)="onTogglePopup()">
    <span [innerHTML]="svg.receiptIcon | safeHtml"></span>
  </div>
</div>
<app-swal-alert [message]="messages$ | async"></app-swal-alert>

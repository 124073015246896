<div class="modal show sign-in" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog-centered">
        <div class="login-modal">
            <div class="modal-content modal-bg modal-signin">
                <div class="modal-header pb-0">
                    <h4 class="modal-title" id="myModalLabel"><span [innerHTML]="svg.forgotPasswordIcon | safeHtml"></span> {{ 'Forgot Password' | translate | uppercase }}</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseDialog()">×</button>
                </div>
                <form [formGroup]="form" class="mb-0" id="login-modal-form" name="login-modal-form">
                    <div class="modal-body pt-0">
                        <hr class="mb-0">
                        <div class="row vip-tabs mat-container">
                            <mat-tab-group [(selectedIndex)]="selectedIndex" [disablePagination]="true">
                                <mat-tab label="{{ 'Email' | translate | titlecase}}">
                                    <div class="item">
                                        <p class="p-b-0">{{ 'Please enter your username below. You will receive a link to create a new password via email.' | translate }}</p>
                                    </div> 
                                </mat-tab>
                                <mat-tab label="{{ 'SMS' | translate | uppercase}}">
                                    <div class="item">
                                        <p class="p-b-0">{{'Please enter your username below. You will receive a SMS to create a new password on your mobile device.' | translate}}</p>
                                    </div>
                                </mat-tab>
                            </mat-tab-group>

                            <div class="input-btn-container">
                                <div class="form-input-label">
                                    <p>{{ ('Username' | translate) | titlecase }}</p>
                                </div>
                                <div class="input_container">
                                    <input type="text" [ngClass]="{'form-invalid': checkValidity && form.controls.username.errors}" formControlName="username" class="form-control form-control-sm form-input-style" (input)="toLowerCaseInput('username', $event)" #focusInput>
                                </div>
                                <button type="submit" class="btn-blue-gra" (click)="submit()">{{'Submit' | translate | uppercase }}</button>
                            </div>
                        </div>

                        <p class="m-t-20 m-b-20 text-center">{{'Not registered yet?' | translate}} <a routerLink="/signup" class="btn-signup"  (click)="onCloseDialog()"> {{'Register Now' | translate}}</a></p>
                        <p class="text-center"><a class="btn-signup" (click)="onOpenDialog()" (click)="onCloseDialog()">{{ 'Forgot Username' | translate }}?</a></p>
                    </div>
                </form>
            </div>
        </div>
    </div>
  </div>
  <app-swal-alert [message]="messages$ | async" [success]="true"></app-swal-alert>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse } from './../models/api-response.model';
import { Dropdown } from './../models/dropdown.model';

@Injectable({
  providedIn: 'root'
})
export class MerchantBankHttpService {

  constructor(private http: HttpClient) { }

  getMerchantBankDeposits(): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>(`/merchantbank?purpose_id=1`).pipe(
      map(res => res.data)
    );
  }

}

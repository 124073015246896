import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Announcement } from '@core/models/announcement.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse } from '../models/api-response.model';
import { Marquees } from '@core/models/marquees.model';
import { Popup } from '@core/models/popup.model';
import { Subject } from 'rxjs';
import { WebsocketService } from './websocket.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable({
  providedIn: 'root'
})
export class AnnouncementHttpService {

  newPromptIncoming = new Subject();

  static rewardPrompt: Announcement = null;

  private websocketService: WebsocketService | null = null;

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,

  ) { 
    this.websocketService = new WebsocketService(this.snackBar)
  }

  init() {
    this.updatePromptWebsocket();
  }

  getAnnouncements(): Observable<Announcement[]> {
    return this.http.get<ApiResponse>(`/announcement`).pipe(
      map(res => {
        if (Object.keys(res.data.rows)) {
          return res.data.rows;
        }
      })
    );
  }

  // slackMessage(message: string) { //commented, slack message would be replaced by spreadsheet log
  //   return this.http.post<void>('/actreport', { mes: message });
  // }

  visitLog(member_id: number) {
    return this.http.post<void>('/actreport', { member_id: member_id });
  }

  getMarquees(): Observable<Marquees[]>{
    return this.http.get<ApiResponse>(`/marquees`).pipe(
      map(res => res.data.rows)
    );
  }

  getPopUp(dialogCode?: any): Observable<Popup[]>{
    let params = '&code=';
    if (dialogCode !== null) {
      params = params + dialogCode;
    }
    else {
      params = '';
    }

    return this.http.get<ApiResponse>(`/popups?always_pop=1${params}` ).pipe(
      map(res => res.data.rows)
    );
  }

  updatePromotionPopupDialogLog(data: any): Observable<Popup[]>{
    return this.http.put<ApiResponse>(`/promotionpopupdialoglog/updateseen`, data).pipe(
      map(res => res.data.rows)
    );
  }

  private updatePromptWebsocket() {
    this.websocketService
      .getChannel('update-reward-prompt-channel.')
      .listen(`.UpdateRewardPromptEvent`, (data) => {
        AnnouncementHttpService.rewardPrompt = data;
        this.newPromptIncoming.next();
      });
  }
}

import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { SystemSettingHttpService } from '../services/system-setting-http.service';

@Injectable({
    providedIn: 'root'
})
export class MaintenanceResolver implements Resolve<boolean> {

    constructor(
        private systemSettingHttpService: SystemSettingHttpService
    ) { }

    resolve(): Observable<any> | any {
        return this.systemSettingHttpService.getMaintenance(false);
    }
}

export const environment = {
  production: false,
  apiBase: "https://api.jk188.net/api/member",
  socketHost: "https://api.jk188.net",
  translationDomain: "https://e86186d6.quickcdn.org",
  luckySpinDomain: "https://luckyspin.jk188.net",
  sitePrefix: "bp77",
  domainEnv: "staging", // production | demo | staging | local : this value is to decide which domain list to use under dropdown-http.service.ts
  https: true, // true use "https://", false use "//"
  domainName: "jk188.net",
  mobileDomainName: "m2.jk188.net",
  altDomainName: "",
  altMobileDomainName: "",
  altDomainNameTwo: "",
  altMobileDomainNameTwo: "",
  forceMaintenance: false,
  shutdown: false,
  recaptcha: {
    siteKey: "6LfeX5ggAAAAALY8xH4oc7RIzL_J-svVRmtcdQV6",
    secretKey: "6LfeX5ggAAAAAEQIgT9F1Du9RM5tnM8zVjKxPpVg",
  },
  sentry_dsn: "",
  reqSignKey: "kbXbAEotZ64nueRXt0+fWKBndGADLrQiaL6VrhM+mSw=",
};
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UploadHttpService } from '@core/services/upload-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { catchError, tap } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { BankAccountDataService } from '@views/modules/member/services/bank-account-data.service';
import { throwError } from 'rxjs';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { FileCompressionPipe } from 'app/pipes/file-compression.pipe';
import svgIconList from 'assets/icons.json';

@Component({
  selector: 'app-ewallet-verification-modal',
  templateUrl: './ewallet-verification-modal.component.html',
  styleUrls: ['./ewallet-verification-modal.component.scss']
})
export class EwalletVerificationModalComponent implements OnInit {
  svg: any = svgIconList;

  form: FormGroup;

  uploadInProgress: boolean;
  fileName: string;

  max_receipts = 10;
  uploaded_images = [];
  uploaded_images_form = [];
  disableButton = true;
  messages$ = this.bankAccountDataService.messages$;
  isSuccess = this.bankAccountDataService.isSuccess;
  isCompressed = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      reupload: boolean,
      bankAccountId: number,
      reason: string,
      account_name: string,
      account_number: number,
      bank_id: number,
      bank_type: number
    },
    public dialogRef: MatDialogRef<EwalletVerificationModalComponent>,
    private uploadHttpService: UploadHttpService,
    private dropdownHttpService: DropdownHttpService,
    private sanitizer: DomSanitizer,
    private bankAccountDataService: BankAccountDataService,
    public loadingBar: LoadingBarService,
    private eventEmitterService: EventEmitterService,
    private fileCompression: FileCompressionPipe,
  ) { }

  ngOnInit() {
    this.formInit();
  }

  async onUploadFileMulti(event: any) {
    this.uploadInProgress = true;
    const file: File = event.target.files[0];

    if (file == null) {
      this.uploadInProgress = false;
      return;
    }
    var compressedFile: any;
    //Only image & file size greater than 2MB will need to compress
    if (file.type != "application/pdf" && file.size >= 2 * 1048576) {
      // Compress the file
      this.isCompressed = true;
      compressedFile = await this.fileCompression.transform(file);
    }
    
    if (compressedFile || this.isCompressed == false) {
      const formData = new FormData();
      this.fileName = compressedFile ? compressedFile.name : file.name;
      formData.append('files', compressedFile ? compressedFile : file);
      formData.append('type', this.dropdownHttpService.uploadTypes[2]);

      this.uploadHttpService.upload(formData).pipe(
        tap((res: any) => {
          this.uploadInProgress = false;
          this.disableButton = false;
          this.uploaded_images.push({
            path: res[0].indexOf('.pdf') >= 0 ? this.sanitizer.bypassSecurityTrustResourceUrl(res[0]) : res,
            isPdf: res[0].indexOf('.pdf') >= 0 ? true : false,
          });
          this.uploaded_images_form.push(res[0]);
          this.form.patchValue({
            document_path: this.uploaded_images_form
          });
          this.form.get('document_path').markAsDirty();
          this.form.markAllAsTouched();
        }),
        catchError((error) => {
          this.uploadInProgress = false;
          this.disableButton = true;
          throw error;
        })
      ).subscribe();
    } else {
      this.isCompressed = false;
      this.uploadInProgress = false;
      if (this.uploaded_images_form.length == 0) {
        this.disableButton = true;
      } else {
        this.disableButton = false;
      }
    }
    
    event.target.value = null;
  }

  onRemoveFileMulti(idx) {
    this.uploaded_images.forEach((value,index)=>{
      if(index==idx) this.uploaded_images.splice(index,1);
    });
    this.uploaded_images_form.forEach((value,index)=>{
      if(index==idx) this.uploaded_images_form.splice(index,1);
    });
    this.form.patchValue({
      document_path: this.uploaded_images_form
    });
    if (this.uploaded_images_form.length == 0) {
      this.disableButton = true;
    } else {
      this.disableButton = false;
    }
    this.form.get('document_path').markAsDirty();
    this.form.markAllAsTouched();
  }

  onCloseDialog(close? : boolean) {
    this.dialogRef.close(close);
  }

  onSave() {
    if (this.data.reupload == true) {
      this.loadingBar.start()
      const data = {
        ...this.form.value
      };
      this.bankAccountDataService.reupload(this.data.bankAccountId, data).pipe(
        tap(res => {
          this.loadingBar.complete();
          this.eventEmitterService.onUpdateVerificationEmitter();
          this.eventEmitterService.onUpdateBankList();
          this.onCloseDialog();
        }),
        catchError(err => {
          this.loadingBar.complete();
          throw err;
        })
      ).subscribe();
    } else {
      const data = {
        ...this.form.value,
        account_name: this.data.account_name,
        account_number: this.data.account_number,
        bank_id: this.data.bank_id,
        bank_type: this.data.bank_type,
        bank_name: null,
        icon_filename: null,
        id: null,
        name: null
      };

      this.loadingBar.start();
      Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
      this.bankAccountDataService.addBank(data).pipe(
        tap(res => {
          this.loadingBar.complete();
          this.eventEmitterService.onUpdateBankList();
          this.onCloseDialog(true);
        }),
        catchError(err => {
          this.loadingBar.complete();
          return throwError(err);
        })
      ).subscribe();
    }
  }

  private formInit() {
    this.form = new FormGroup({
      document_path: new FormControl(null, Validators.required),
    });
  }

}

import { ApiResponse } from '../models/api-response.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Dropdown } from '../models/dropdown.model';

@Injectable({
  providedIn: 'root'
})
export class BankMaintenanceHourService {

  constructor(private http: HttpClient) { }

  getBankMaintenanceHours(bank_id): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>(`/bankmaintenancehours?bank_id=${bank_id}`).pipe(
      map(res => res.data)
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { BankAccountDataService } from '../../services/bank-account-data.service';
import { ActivatedRoute } from '@angular/router';
import { CryptoHttpService } from '@core/services/crypto-http.service';

@Component({
  selector: 'app-portal-bank',
  templateUrl: './portal-bank.component.html',
  styleUrls: ['./portal-bank.component.scss']
})
export class PortalBankComponent implements OnInit {


  activeTab = this.activatedRoute.snapshot.paramMap.get('tab') ? this.activatedRoute.snapshot.paramMap.get('tab') : 'bank';
  messages$ = this.bankAccountDataService.messages$;
  availableTokens:any;
  constructor(
    private bankAccountDataService: BankAccountDataService,
    private activatedRoute: ActivatedRoute,
    private cryptoHttpService: CryptoHttpService,
  ) { }

  ngOnInit() {
    this.cryptoHttpService.getCryptoTokens().subscribe(res => {
      this.availableTokens = JSON.parse(JSON.stringify(res));
    })
  }

  onSelectTab(tab: string) {
    this.activeTab = tab;
    window.history.replaceState({}, '', `/member/bank;tab=${tab}`);
  }
}

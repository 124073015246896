import { Component, OnDestroy, OnInit } from '@angular/core';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { LiveChatIdService } from '@core/services/live-chat-id.service';
import { isLoggedIn } from '@core/store/auth/auth.selectors';
import { environment } from '@env/environment';
import { select, Store } from '@ngrx/store';
import { AppState } from '@store/reducers';
import { Observable, Subscription } from 'rxjs';
import { LivechatHttpService } from '@core/services/livechat-http.service';
import { Crisp } from "crisp-sdk-web";

declare const window: any;
declare var $: any;
@Component({
  selector: 'app-bottombar',
  templateUrl: './bottombar.component.html',
  styleUrls: ['./bottombar.component.scss']
})
export class BottombarComponent implements OnInit, OnDestroy {

  isLoggedIn$: Observable<boolean>;
  mode = window.mode;

  siteName = environment.sitePrefix.toUpperCase();
  subscription: Subscription;
  constructor(
    private store: Store<AppState>,
    private liveChatIdService: LiveChatIdService,
    private eventEmitterService: EventEmitterService,
    private livechatHttpService: LivechatHttpService
  ) { }

  ngOnInit() {
    this.isLoggedIn$ = this.store.pipe(select(isLoggedIn));

    this.subscription = this.store.pipe(select(isLoggedIn)).subscribe(res => {
      if (window.location.pathname !== '/gamelaunch' && this.mode !== 'Members Only') this.getLiveChatScript();
      if (!res) {
        this.getCleanModeLiveChatScript();
      } else {
        document.body.classList.add('logged-in');
      }
    });

    this.eventEmitterService.liveChatWidgetVar = this.eventEmitterService.liveChatWidgetEmitter.subscribe(() => {
      this.openLiveChat();
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private openLiveChat(){
    window.LiveChatWidget.call('maximize');
  }

  private getLiveChatScript() {
    var language = localStorage.getItem('language_code');
    var country = localStorage.getItem('country_code');

    this.livechatHttpService.getLiveChatScript(`?language=${language}&country=${country}`).subscribe(res => {
      if (this.livechatHttpService.website !== null && this.livechatHttpService.website !== '') {
        this.isLoggedIn$.subscribe(login => {
          var website = this.livechatHttpService.website;

          // Check live chat <script> is it exists.
          var liveChatScriptID = "live-chat-script";
          var scriptIndex = website.indexOf('<script') + '<script'.length;
          website = website.slice(0, scriptIndex) + ' id="' + liveChatScriptID + '" ' + website.slice(scriptIndex, website.length);
          var scriptExists = (document.getElementById(liveChatScriptID) !== null);

          if (this.livechatHttpService.live_chat_platform_name == 'Live Chat') {
            // Remove old live chat <script> if already exists.
            if (scriptExists) {
              var scriptElement = document.getElementById(liveChatScriptID);
              if (scriptElement != null) {
                scriptElement.parentNode.removeChild(scriptElement);
              }
            }

            website = website.replace(/(['])/g, "\\$1");
            var index = website.indexOf('window.__lc.license');
            var username = "  ";
            var email = "  ";

            if (login) {
              if (localStorage.getItem('user_data') != null) {
                if (JSON.parse(localStorage.getItem('user_data')).username != null && JSON.parse(localStorage.getItem('user_data')).username !== '') {
                  username = JSON.parse(localStorage.getItem('user_data')).username;
                }
                if (JSON.parse(localStorage.getItem('user_data')).email != null && JSON.parse(localStorage.getItem('user_data')).email !== '') {
                  email = JSON.parse(localStorage.getItem('user_data')).email;
                }
              }
              if(index > 0) {
                website = website.slice(0, index) + "window.__lc.visitor = {name: '"+ username +"',email: '"+ email +"'};" + website.slice(index, website.length);
              }
            }
            else{
              if(index > 0) {
                website = website.slice(0, index) + website.slice(index, website.length);
              }
            }
            $(website).appendTo(document.body);

          } else if (this.livechatHttpService.live_chat_platform_name == 'Crisp') {

            // Crisp no need to remove and assign again due to after assign it require refresh only can work.
            if (!scriptExists || typeof window.$crisp === "undefined") {
              $(website).appendTo(document.body);
              var indexStart = website.indexOf('window.CRISP_WEBSITE_ID=')  + 'window.CRISP_WEBSITE_ID='.length;
              var indexEnd = website.indexOf(';(function(){d=document');
              var websiteID = website.slice(indexStart, indexEnd).replace(/\\/g, "").replace(/\"/g, "");

              Crisp.configure(websiteID);
            }

            if (login) {
              if (localStorage.getItem('user_data') != null) {
                if (JSON.parse(localStorage.getItem('user_data')).username != null && JSON.parse(localStorage.getItem('user_data')).username !== '') {
                  Crisp.user.setNickname(JSON.parse(localStorage.getItem('user_data')).username);
                }
                if (JSON.parse(localStorage.getItem('user_data')).email != null && JSON.parse(localStorage.getItem('user_data')).email !== '') {
                  Crisp.user.setEmail(JSON.parse(localStorage.getItem('user_data')).email);
                }
              }
            }

          }
        });
      }
    });
  }

  private getCleanModeLiveChatScript() {
    if (this.mode !== 'Members Only') {
      return;
    }

    const language = localStorage.getItem('language_code');
    const country = localStorage.getItem('country_code');
    const countryLang = `${country}_${language}`;
    const groupIds = {
      MY_EN: 54,
      MY_ZH: 55,
      SG_EN: 56,
      SG_ZH: 57
    };

    const chatParentElem = document.createElement('div');
    chatParentElem.id = "clean-mode-live-chat-widget";
    const script = document.createElement('script');
    const noscript = document.createElement('noscript');
    script.innerHTML = `
      window.__lc = window.__lc || {};
      window.__lc.license = 10515072;
      window.__lc.group = ${groupIds[countryLang] || 54};
      ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))
    `;
    noscript.innerHTML = '<a href="https://www.livechat.com/chat-with/10515072/" rel="nofollow">Chat with us</a>, powered by <a href="https://www.livechat.com/?welcome" rel="noopener nofollow" target="_blank">LiveChat</a>';
    chatParentElem.appendChild(script);
    chatParentElem.appendChild(noscript);
    document.body.append(chatParentElem);
  }

}

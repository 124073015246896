import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiResponse } from './../models/api-response.model';

@Injectable({
  providedIn: 'root'
})
export class FavoriteGameHttpService {

  messages$ = new Subject<any[]>();

  constructor(private http: HttpClient) { }

  toggleFavorite(param: any) {
    return this.http.post<ApiResponse>(`/gameprovider/favorite`, param).pipe(
      tap(res => this.messages$.next(res.message)),
    );
  }

  toggleFavoriteMiniGames(data: any){
    return this.http.post<ApiResponse>(`/gameprovider/minigame/favorite`, data).pipe(
      tap( res => this.messages$.next(res.message)),
    );
  }

}

<div class="modal show sign-in" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog-centered">
      <div class="signup-modal promotion-application">
          <div class="signup-container modal-content p-3">
              <div class="signup-header modal-header">
                    <h4 class="modal-title" id="myModalLabel">{{ data.applyData.title }}<br></h4>
                  <button type="button" class="close " data-dismiss="modal" aria-hidden="true" (click)="onCloseDialog()">×</button>
              </div>
              <span class="fill-text">{{ 'Please fill up the details' | translate }}</span>

              <form [formGroup]="form" (ngSubmit)="onSave()" id="signup-modal-form" name="promotion-application-modal-form" class="m-b-0 pt-3">
                  <ng-container *ngFor="let item of data.applyData.required_fields">
                    <div class="form-input-label">
                        <p>{{ '' + item | translate }} <span class="text-danger">*</span></p>
                    </div>
                    <div class="input_container">
                        <!-- Added id and username for browser to collect credentials correctly to save. Tested on : Chrome, Edge and Firefox -->
                        <input type="text" class="form-control form-control-sm form-input-style" [ngClass]="{'form-invalid': checkValidity && form.controls[item | lowercase].errors}" [formControlName]="item | lowercase" [id]="item | lowercase" [name]="item | lowercase" #focusInput/>
                    </div>
                  </ng-container>
                  <!--Action Button-->
                  <button type="submit" class="btn-blue-gra">
                      {{ 'Save' | translate | uppercase}}
                  </button>
              </form>
          </div>
      </div>
  </div>
</div>

<app-swal-alert [message]="messages$ | async" [success]="isSuccess" (confirmed)="onCloseDialog()" [timer]="5000"></app-swal-alert>
<button title="Close (Esc)" type="button" class="mfp-close" style="font-weight: bold;" (click)="onCloseNotification()">×</button>
<div>
    <div *ngIf="data.title" class="text-blue text-uppercase font-weight-bold header" style="width: 90%;">
        {{ data.title | translate }}
    </div>
    <div [style]="data?.title ? 'margin-top: 10px !important;' : 'width: 90%; text-align:center;'">
        <h6 *ngIf="data.subtitle" class="msg-title">{{ data.subtitle }}</h6>
        <div class="msg-content" [innerHTML]="sanitize(data.message || data.content)"></div>
    </div>

    <div *ngIf="data.CTA" class="m-t-10 d-flex flex-column align-items-center">
        <button class="btn-blue-gra" style="width: 80%;" (click)="onNavigate()">{{ data.buttonConfirm | titlecase | translate }}</button>
        <p class="msg-cancel" (click)="onCloseNotification()">{{ data.buttonCancel | translate }}</p>
    </div>
</div>
<div class="modal1" id="modal-signup" (click)="onDismiss()">
  <div class="block mx-auto ma-box boxshadow">
    <div class="row">
      <div class="col-12">
        <div class="content bank-modal-content">
          <div class="modal show sign-in" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-body">
                  <div class="modal-content">
                    <!-- this modal used to darken the background of more sort  -->
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
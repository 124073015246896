import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { PortalMemberProfileHttpService } from '@core/services/portal-member-profile-http.service';
import { UploadHttpService } from '@core/services/upload-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { FileCompressionPipe } from 'app/pipes/file-compression.pipe';
@Component({
  selector: 'app-portal-verification',
  templateUrl: './portal-verification.component.html',
  styleUrls: ['./portal-verification.component.scss']
})
export class PortalVerificationComponent implements OnInit {

  form: FormGroup;
  imageFrontName: null;
  imageHoldName: null;
  uploadInProgress: boolean;

  // For use of image preview
  imageFrontPreview: string;
  imageHoldPreview: string;

  messages$ = this.portalMemberProfileHttpService.messages$;
  isSuccess = this.portalMemberProfileHttpService.isSuccess;
  isCompressed = false;

  constructor(
    private portalMemberProfileHttpService: PortalMemberProfileHttpService,
    private dropdownService: DropdownHttpService,
    private uploadHttpService: UploadHttpService,
    public loadingBar: LoadingBarService,
    private fileCompression: FileCompressionPipe,
  ) { }

  ngOnInit() {
    this.FormInit();
  }

  async onUploadFile(event: any, type: string) {
    this.loadingBar.start();
    const file: File = event.target.files[0];
    var compressedFile: any;
    //Only image & file size greater than 2MB will need to compress
    if (file.type != "application/pdf" && file.size >= 2 * 1048576) {
      // Compress the file
      this.isCompressed = true;
      compressedFile = await this.fileCompression.transform(file);
    }
    if (compressedFile || this.isCompressed == false) {
      const formData = new FormData();
      formData.append('files', compressedFile ? compressedFile : file);
      formData.append('type', this.dropdownService.uploadTypes[4]);
      switch (type) {
        case 'front':
          this.uploadHttpService.upload(formData).subscribe(res => {
            this.uploadInProgress = false;
            res.forEach((result, index) => {
              if (index === 0) {
                this.imageFrontPreview = result;
              }
              this.form.patchValue({
                identity_front_path: this.imageFrontPreview
              });
            });
          });
          this.imageFrontName = event.target.files[0].name;
          this.loadingBar.stop();
          break;
        case 'hold':
          this.uploadHttpService.upload(formData).subscribe(res => {
            this.uploadInProgress = false;
            res.forEach((result, index) => {
              if (index === 0) {
                this.imageHoldPreview = result;
              }
            });
            this.form.patchValue({
              identity_hold_path: this.imageHoldPreview
            });
          });
          this.imageHoldName = event.target.files[0].name;
          this.loadingBar.stop();
          break;
      }
    } else {
      this.isCompressed = false;
      this.uploadInProgress = false;
    }
  }

  onVerify() {
    const data = {
      ...this.form.value
    };
    this.portalMemberProfileHttpService
      .updateICVerification(data)
      .subscribe(res => {
        this.isSuccess = this.portalMemberProfileHttpService.isSuccess;
      });
  }

  onConfirm() {
    this.FormInit();
    this.imageFrontName = null;
    this.imageHoldName = null;
  }

  private FormInit() {
    this.form = new FormGroup({
      identity_front_path: new FormControl(null, [Validators.required]),
      identity_hold_path: new FormControl(null, [Validators.required])
    });
  }

}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import svgIconList from 'assets/icons.json';

@Component({
  selector: 'app-logged-validator',
  templateUrl: './logged-validator.component.html',
  styleUrls: ['./logged-validator.component.scss']
})
export class LoggedValidatorComponent implements OnInit, OnChanges {
  svg: any = svgIconList;
  @Input()
  isLoggedIn: any;

  @Output()
  confirmed = new EventEmitter<boolean>();

  constructor(
    private router: Router
  ) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    this.alert();
  }

  private alert() {
    if ((!!this.isLoggedIn)){
      Swal.fire({
        html: '<div class="msg-icon">' + this.svg.dangerIcon + '</div>' +
              '<div class="text-center m-t-20">' +
                '<ul><li>' + this.isLoggedIn + '</li></ul>' +
              '</div>',
          confirmButtonText: 'OK',
          reverseButtons: true,
        }).then(result => {
          this.router.navigate(['/']);
        });
    }
  }
}

<div class="modal modal-bottom fade show d-block" id="notification-pop" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-modal="true">
    <div class="h-100" (click)="onCloseDialog()"></div>
    <div class="notification-bottom modal-dialog modal-content">
        <div class="gra-box">
            <h3>{{ 'Insufficient Funds' | translate }}</h3>
            <p>{{ 'Your balance is insufficient for real money play.' | translate }} <br>{{ 'Would you like to make a deposit?' | translate }}</p>
        </div>
        <div class="row m-l-0 m-r-0">
            <div class="col-6 p-l-0 p-r-0">
                <button class="btn-nothanks" (click)="onCloseDialog()">{{ 'No, Thanks' | translate }}</button>
            </div>
            <div class="col-6 p-l-0 p-r-0">
                <button (click)="onDeposit()">{{ 'Deposit' | translate }}</button>
            </div>
        </div>

    </div>
</div>
// Model
import { ApiResponse } from '../models/api-response.model';

// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// RxJS
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SiteDomainService {

  constructor(private http: HttpClient) { }

  getSiteDomain(): Observable<any> {
    return this.http.get<ApiResponse>(`/sitedomain/getsitedomain`).pipe(
      map(res => res.data)
    );
  }
}

import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { ApiResponse } from '@core/models/api-response.model';
import { Reward } from '@core/models/reward.model';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  totalGameBalance$ = new Subject<number>();
  memberBalance$ = new Subject<number>();
  memberUsername$ = new Subject<string>();
  activeRewards$ = new BehaviorSubject<number>(null);
  unreadMessagesCount$ = new Subject<number>();

  reloadProviderEmmiter = new EventEmitter();
  subsVar: Subscription;

  constructor(
    private http: HttpClient
  ) { }

  refreshMemberBalance(amount: number) {
    this.memberBalance$.next(amount);
  }

  reloadProvider() {
    this.reloadProviderEmmiter.emit();
  }

  refreshMemberName(username: string) {
    this.memberUsername$.next(username);
  }

  getActiveRewards(): Observable<Reward[]> {
    return this.http.get<ApiResponse>(`/reward?active=1`).pipe(
      map(res => {
        this.activeRewards$.next((res.data.rows).length);
        return res.data.rows;
      }),
    );
  }

}

import { Injectable } from '@angular/core';
import { CryptoWallet } from '@core/models/crypto-wallet.model';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { ApiResponse } from '@core/models/api-response.model';

@Injectable({
  providedIn: 'root'
})

export class CryptoWalletHttpService {
  messages$ = new Subject<any[]>();
  isSuccess: boolean;

  constructor(private http: HttpClient) {
  }


  getAll(): Observable<CryptoWallet[]> {
    return this.http.get<ApiResponse>(`/cryptowallets`).pipe(
      map(res => res.data.rows)
    );
  }

  addWallet(bankAccount: CryptoWallet) {
    return this.http.post<ApiResponse>(`/cryptowallets`, bankAccount).pipe(
      map(res => {
        this.messages$.next(res.message);
        this.isSuccess = res.success;
      })
    );
  }

  update(id: number, data) {
    return this.http.put<ApiResponse>(`/cryptowallets/${id}`, data).pipe(
      tap(res => this.messages$.next(res.message)),
    )
  }

  validateWalletAddress(wallet_information) {
    return this.http.get<ApiResponse>(`/cryptowallets/validate/`+ wallet_information).pipe(
      map(res => res.data)
    );
  }

}

import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-reward-swal',
  templateUrl: './reward-swal.component.html',
  styleUrls: ['./reward-swal.component.scss']
})
export class RewardSwalComponent implements OnInit {
  currency = localStorage.getItem('country_code');
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { type?: string, message?: any, provider?: any },
    private router: Router,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<RewardSwalComponent>,
  ) { }

  ngOnInit(): void {
    if(this.data.message !== undefined){
      this.data.message = this.data.message.replace(/\&lt;/g, '<').replace(/\&gt;/g, '>');
    }
  }

  onCloseDialog(){
    this.dialogRef.close();
  }

  onClaim(type?: any){
    localStorage.removeItem('reward_notice');
    this.dialog.closeAll();
    if (type === 'navigate'){
      this.router.navigateByUrl('/member/reward');
    }
  }

}

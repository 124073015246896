import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-push-notification',
  templateUrl: './push-notification.component.html',
  styleUrls: ['./push-notification.component.scss']
})
export class PushNotificationComponent implements OnInit {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackBarRef: MatSnackBarRef<PushNotificationComponent>,
    private router: Router,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
  }

  onCloseNotification() {
    this.snackBarRef.dismiss();
  }

  onNavigate() {
    this.onCloseNotification();
    this.router.navigate(['/member/message/' + this.data.id]);
  }


  sanitize(content: any) {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

}

<div class="modal show">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-body">
            <div class="modal-content modal-bg demomode-notice">
                <div class="notice-body">
                    <p class="icon-wrapper"><span class="icon">i</span></p>
                    <p id="note"></p>

                    <!-- No need for translation -->
                    <button class="btn-blue-gra m-b-10" (click)="onCloseDialog()">OK</button>
                </div>
                <p class="m-b-0 d-flex justify-content-center">
                    <label>
                        <input type="checkbox" (change)="onDoNotShowAgain($event)" /> {{ 'Do not show again' | translate }}
                    </label>
                </p>
            </div>
        </div>
    </div>
</div>
<div class="modal show">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-body">
            <div class="modal-content modal-bg approval-notice">
                <div class="notice-body">
                    <span class="msg-icon" [innerHTML]="svg.greenTickIcon | safeHtml"></span>
                    <h4 class="m-b-10 m-t-10">{{ 'Verification Successful' | translate }}</h4>
                    <p>{{ 'Your Ewallet has been verified and can be used for withdrawal.' | translate }}</p>
                    <div class="ewallet-detail row">
                        <!-- Ewallet-->
                        <label class="col-5 m-0 py-1">{{ 'E-Wallet' | translate }}</label>
                        <label class="col-1 m-0 py-1 px-0">:</label>
                        <label class="col-6 m-0 py-1 px-0">{{ data.bank_name }}</label>
                        <!-- Account Number -->
                        <label class="col-5 m-0 py-1">{{ 'Account Number' | translate }}</label>
                        <label class="col-1 m-0 py-1 px-0">:</label>
                        <label class="col-6 m-0 py-1 px-0">{{ data.account_number }}</label>
                    </div>
                    <!-- No need for translation -->
                    <button class="btn-blue-gra m-b-10" (click)="onCloseDialog()">OK</button>
                </div>
            </div>
        </div>
    </div>
</div>